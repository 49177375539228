<template>
  <div>
    <header-table-component />
    <article class="container-fluid container-box p-3 mx-1">
      <div class="row ">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="FormControlSelect1" class="">Search</label>
            <input
              type="text"
              class="form-control relative"
              placeholder="Search (fast)"
              v-model.lazy="search"
              @keyup.enter="($store.state.list.paginate.page = 1), fetchData()"
            />
          </div>
        </div>
        <div class="col mt-4">
          <button
            type="button"
            class="btn btn-primary"
            @click="fetchData()"
          >
            <i class="fa fa-refresh"></i>
          </button>
        </div>
      </div>
      <hr />

      <div class="table-custom">
        <table class="table  table-bordered">
          <thead class="thead-light">
            <tr>
              <th scope="col">
                row
              </th>
              <th scope="col">
                name
              </th>
              <th scope="col"></th>
              <th scope="col">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in results" :key="index">
              <th class="text-center" scope="row">{{ index + 1 }}</th>
              <td>{{ item.title }}</td>
              <td>{{ item.value }}</td>

              <td>
                <div class="row">
                  <div class="col-lg-4 " @click="editDataMethode(item)">
                    <button class="btn btn-custom px-2 d-flex flex-column">
                      <i class="fa fa-edit mx-auto"></i>
                      <span class="mx-auto">
                        Edit
                      </span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <h5 v-show="results.length === 0" class="mt-4 text-center text-warning">
          Not found!
        </h5>
      </div>
      <!-- paginate  v-if="paginate.total > 1"-->

      <div class="mt-4">
        <paginateComponent
          :paginateShared="paginate"
          @clickedCustom="onClickChildPaginate"
        />
      </div>

      <!-- paginate -->
    </article>

    <!-- comment user modal -->
    <form @submit.prevent="editData()">
      <modals :modalShow="showEditModal" @closeModalEmited="showEditModal = false">
        <template #header> Edit </template>
        <template #content>
          <div class="row">
            <div class="form-group col-lg-6">
              <label>Value</label>
              <input
                type="text"
                class="form-control"
                placeholder="name"
                v-model="models.value"
                required
              />
            </div>
          </div>
        </template>
        <template #footer>
          <button type="submit" class="btn btn-info">
            Save تغییرات
          </button>
        </template>
      </modals>
    </form>
    <!-- comment user modal -->
    <div>
      <loading :loading="loading" />
    </div>
  </div>
</template>
<script>
import CRUDMix from "/src/mixins/lists/CRUD.js";
import paginateMix from "/src/mixins/lists/paginate.js";
import staticDataTableMix from "/src/mixins/lists/staticDataTable.js";
import defaultErrorMessageMix from "/src/mixins/lists/defaultErrorMessage.js";
import storeStateMix from "/src/mixins/lists/storeState.js";
export default {
  name: "default-setting",
  mixins: [
    CRUDMix,
    paginateMix,
    staticDataTableMix,
    defaultErrorMessageMix,
    storeStateMix,
  ],
  data() {
    return {
      reqName: "settings", //URL name request To server EX:www.test.com/api/v2/reqName
      models: {
        id: 0,
        name: "",
        value: "",
        inputType: "",
        title: "",
      },
    };
  },
  watch: {
    "models.name"() {
      return (this.models.keyName = this.models.name.replace(/\s/g, "_"));
    },
  },
  methods: {
    fetchData() {
      this.$store.dispatch("list/getListsAction", {
        reqName: this.reqName,
        page: this.paginate.page,
        pageSize: this.paginate.pageSize,
        search: `Title=${this.search}¤5ɖ`,
        sorting: `${this.sortingField}¤${this.sortDircetion}`,
        filter: ``,
      });
    },

    editDataMethode(item) {
      
      this.showEditModal = !this.showEditModal;
      this.models.id = item.id;
      this.models.name = item.name;
      this.models.title = item.title;
      this.models.value = item.value;
      this.models.inputType = item.inputType;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style lang="scss"></style>
