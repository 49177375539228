export const upload = {
  namespaced: true,
  state: {
    imageDetail: {},
    imageSecondDetail: {},
    uploadLoading: false,
    uploadSuccess: false,
  },
  mutations: {
    uploadLinkMutate(state, data) {
      state.imageDetail = data;
    },
    uploadSecondLinkMutate(state, data) {
      state.imageSecondDetail = data;
    },
 
  },
  actions: {
    async uploadImage(context, params) {
     

      context.state.uploadLoading = true;
      context.state.uploadSuccess = false;
     await axios
        .post(`/api/image`, params)
        .then(({ data }) => {
          context.state.uploadLoading = false;

          
          if (data.succeeded) {
            if (params.count === 1 || params instanceof FormData) {
              context.commit("uploadLinkMutate", data.data);
              context.state.uploadSuccess = true;
            } else if (params.count === 2) {
              context.commit("uploadSecondLinkMutate", data.data);
              context.state.uploadSuccess = true;
            }
          } else {
            // root:true Set Global Mutate in main store file
            context.commit("errorHandel",
              {
                message: {
                  text: data.error.message,
                  icon: "error",
                  title: `error code -${data.error.code}`,
                  textButton: "close",
                },
              },
              {
                root: true
              });
            context.rootState.success = false;
          }
        })
        .catch((error) => {
          context.state.uploadLoading = false;
          context.commit(
            "errorHandel",
            {
              message: {
                text: "An error occurred. Contact support",
                icon: "error",
                title: "Error",
                textButton: "Ok",
              },
            },
            { root: true }
          );

          if (error.response) {
            console.log(error.response);
          }
        });
    },

    
    removeImage(context, params) { 
      console.log(params);
      context.state.uploadLoading = true;
      axios
        .delete(`/api/image/${params}`)
        .then(({ data }) => {
          context.state.uploadLoading = false;

          if (data.succeeded) {
            context.commit("uploadLinkMutate", {});
            context.state.uploadSuccess = true;

          }
          else {
            // root:true Set Global Mutate in main store file
            context.commit("errorHandel",
              {
                message: {
                  text: data.error.message,
                  icon: "error",
                  title: `error code -${data.error.code}`,
                  textButton: "close",
                },
              },
              {
                root: true
              });
            context.rootState.success = false;
          }
        })
        .catch((error) => {
          context.commit(
            "errorHandel",
            {
              message: {
                text: "An error occurred. Contact support",
                icon: "error",
                title: "Error",
                textButton: "Ok",
              },
            },
            { root: true }
          );

          if (error.response) {
            console.log(error.response);
          }
        });
    },
  },
};
