const base = "content";

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
import storyList from "../../components/stories/storyList.vue";

export default [
  {
    path: "/story-list",
    name: "storyList",
    component: storyList,
    meta: { requiresAuth: true },
  },
  
];
