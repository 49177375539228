const touchMap = new WeakMap();

export const validation = {
  namespaced: true,
  state: { resetCodeTimerMin: 0, resetCodeTimerSec: 0 },
  mutations: {
    delayTouchMutate(state, $v) {
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 1000));
    },
    resetCodeIntervalMutate(state, i) {
      var int = setInterval(() => {
        var divisor_for_minutes = i % (60 * 60);
        state.resetCodeTimerMin = Math.floor(divisor_for_minutes / 60);

        var divisor_for_seconds = divisor_for_minutes % 60;
        state.resetCodeTimerSec = Math.ceil(divisor_for_seconds);

        i-- || clearInterval(int);
      }, 1000);
    },
  },
  actions: {},
};
