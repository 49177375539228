export default {
    data() {
        return {
            window: window.url,
            pageSize: 10,
            dropDown: false,
            search: "",
            showCreateModal: false,
            showEditModal: false,
            showModalFilter: false,
            sortingField: "",
            sortDircetion: 2,
            staticModels: {}
      
        }
    },
}