<template>
  <div>
    <header-table-component />
    <article class="container-fluid container-box p-3 mx-1">
      <div class="row">
        <div class="col-lg-3">
          <label for="FormControlSelect1" class="">Search</label>
          <input type="text" ref="search" class="form-control relative" placeholder="Search (fast)" v-model="search"
            @keyup.enter="($store.state.list.paginate.page = 1), fetchData()" />
        </div>
        <div class="form-group col-lg-2">
          <label>Sort </label>
          <select class="form-control" v-model="sortName">
            <option :value="item.value" v-for="(item, index) in fields" :key="index" v-show="item.value !== null">
              {{ item.text }}
            </option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label>Order by</label>
          <select class="form-control" v-model.number="sortDircetion">
            <option value="1">ascending</option>
            <option value="2">descending</option>
          </select>
        </div>
        <div class="col mt-4">
          <button type="button" class="btn btn-primary mt-2" @click="fetchData()">
            <i class="fa fa-refresh"></i>
          </button>
          <button class="btn btn-success mx-1 mt-2" @click="createDataMethode()">
            <i class="fa fa-plus mx-1"></i>
            <span class=""> New tag </span>
          </button>
          <!--   <button
            class="btn btn-info mx-1"
            @click="showModalFilter = !showModalFilter"
          >
            <i class="fa fa-filter mx-1"></i>
            Filters
          </button> -->
          <div class="btn-group mt-2" :class="{ show: dropDown }">
            <button type="button" class="btn btn-info dropdown-toggle" @click="dropDown = !dropDown">
              Export
            </button>
            <div class="dropdown-menu" :class="{ show: dropDown }" style="
                position: absolute;
                transform: translate3d(0px, 38px, 0px);
                top: 0px;
                left: 0px;
                will-change: transform;
              ">
              <a class="dropdown-item pointer"><i class="fa fa-file-excel-o mx-1"></i>Excel</a>

            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="table-custom">
        <table class="table table-bordered">
          <thead class="thead-light">
            <tr>
              <th v-for="(item, index) in fields" :key="index" v-show="item.value !== ''">
                {{ item.text }}
                <span v-show="sortName === item.value" class="float-left">
                  <i class="fa" :class="[
                    sortDircetion === 1
                      ? 'fa-sort-amount-asc'
                      : 'fa-sort-amount-desc',
                  ]" aria-hidden="true"></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in results" :key="index">
              <th class="text-center" scope="row">{{ index + 1 }}</th>
              <td>{{ item.name }}</td>

              <td>
                <div class="row">
                  <div class="col-lg-4">
                    <button class="btn btn-custom px-2 d-flex flex-column" @click="deleteDataMethode(item.id)">
                      <i class="fa fa-trash mx-auto"></i>
                      <span class="mx-auto"> remove </span>
                    </button>
                  </div>
                  <div class="col-lg-4" @click="editDataMethode(item)">
                    <button class="btn btn-custom px-2 d-flex flex-column">
                      <i class="fa fa-edit mx-auto"></i>
                      <span class="mx-auto"> Edit </span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <h5 v-show="results.length === 0" class="mt-4 text-center text-warning">
          Not found!
        </h5>
      </div>
      <!-- paginate  v-if="paginate.total > 1"-->

      <div class="mt-4">
        <paginateComponent :paginateShared="paginate" @clickedCustom="onClickChildPaginate" />
      </div>

      <!-- paginate -->
    </article>
    <!-- Create modal -->
    <modals :modalShow="showCreateModal || showEditModal"
      @closeModalEmited="(showCreateModal = false), (showEditModal = false)">
      <template #header> {{ activeClassText }}</template>
      <template #content>
        <form id="form-tag" @submit.prevent="showCreateModal ? createData() : editData()">
          <div class="row">
            <div class="form-group col-lg-6">
              <label>name</label>
              <input type="text" class="form-control" placeholder="name" v-model="models.name" required />
            </div>
          </div>
        </form>
      </template>
      <template #footer>
        <button type="submit" form="form-tag" class="btn btn-info">
          Save {{ activeClassText }}
        </button>
      </template>
    </modals>

    <div>
      <loading :loading="loading" />
    </div>
  </div>
</template>
<script>
import CRUDMix from "/src/mixins/lists/CRUD.js";
import paginateMix from "/src/mixins/lists/paginate.js";
import staticDataTableMix from "/src/mixins/lists/staticDataTable.js";
import defaultErrorMessageMix from "/src/mixins/lists/defaultErrorMessage.js";
import storeStateMix from "/src/mixins/lists/storeState.js";
export default {
  name: "product-tags",
  mixins: [
    CRUDMix,
    paginateMix,
    staticDataTableMix,
    defaultErrorMessageMix,
    storeStateMix,
  ],
  data() {
    return {
      reqName: "tags", //URL name request To server EX:www.test.com/api/v2/reqName
      fields: [
        { value: "", text: "default" },
        { value: null, text: "row" },
        { value: "Name", text: "name" },
        { value: null, text: "" },
      ],
      sortName: "",

      models: {
        id: 0,
        name: "",
        keyName: "",
      },
    };
  },
  watch: {
    "models.name"() {
      return (this.models.keyName = this.models.name.replace(/\s/g, "_"));
    },
  },
  methods: {
    fetchData() {
      this.$store.dispatch("list/getListsAction", {
        reqName: this.reqName,
        page: this.paginate.page,
        pageSize: this.paginate.pageSize,
        search: `Name=${this.search}¤5ɖ`,
        sorting: `${this.sortName}¤${this.sortDircetion}`,
        filter: ``,
      });
    },

    createDataMethode() {
      this.showCreateModal = !this.showCreateModal;
      this.models.id = 0;
      this.models.name = "";
    },
    editDataMethode(item) {

      this.showEditModal = !this.showEditModal;
      this.models.id = item.id;
      this.models.name = item.name;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style lang="scss"></style>
