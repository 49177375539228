<template>
  <div class="form-group">
    <label for="FormControlSelect1" class="">Search</label>
    <input
      type="text"
      class="form-control relative"
      placeholder="Search (fast)"
      v-model="value"
      @keyup.enter="submitSearch()"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: "",
    };
  },
  methods: {
    submitSearch() {
      this.$emit("submitSearch");
    },
  },
};
</script>
