export const errorHandelMethode = (context, data) => {
    /* handeling post and put methode errors in catch statment */



    let message = '<b> An error occurred. Contact support</b>';
    if (data && data.error && data.error.message)
        message = data.error.message;

    if (data.error && data.error.code == 900 && data.data) {

        message = `<b>${message}</b>`;
        for (const [key, value] of Object.entries(data.data)) {
            console.log(`${key}: ${value}`);

            message += `<br> <b class="text-danger">${key}</b> : ${value.toString()}`


        }


    }

    context.commit(
        "errorHandel", {
        message: {
            text: message,
            icon: "error",
            title: "Error",
            textButton: "Ok",
        },
    }, {
        root: true
    }
    );

}
const messageType = ['success', 'error', 'warning']
function throwIfInvalid(data) {
    if (!data) {
        throw new Error(`data is empty`);
    }
    // if (data.type && !messageType.includes(data.type)) {
    //     throw new Error(`data.type is not valid. must between ${data.toString()}`);
    // }
    if (!data.type || !messageType.includes(data.type)) {
        data.type = 'error';
    }
    if (!data.title) {
        data.title = data.type;
    }
    if (!data.textButton) {
        data.title = "Ok";
    }

    return data;
}
export const messageHandelMethode = (context, data = throwIfInvalid()) => {
    /* handeling post and put methode errors in catch statment */

    context.commit(
        "errorHandel", {
        message: {
            text: data.message,
            icon: data.type,
            title: data.title,
            textButton: data.textButton,
        },
    }, {
        root: true
    }
    );

}