
import {
    Option,
    NAVBAR_DARK_VARIANTS,
    NAVBAR_LIGHT_VARIANTS,
    SIDEBAR_DARK_SKINS,
    SIDEBAR_LIGHT_SKINS
} from "../../utils/themes";
import { mapGetters } from "vuex";
export default {
    // name:'control-sidebar',
    data() {

        return {
          
            navbarLightVariants:  "",
            navbarDarkVariants:  "",
            darkSidebarSkins:  "",
            lightSidebarSkins:  "",

        };
    },
  created(){
    this.navbarLightVariants=  NAVBAR_LIGHT_VARIANTS;
    this.navbarDarkVariants=  NAVBAR_DARK_VARIANTS;
    this.darkSidebarSkins=  SIDEBAR_DARK_SKINS;
    this.lightSidebarSkins=  SIDEBAR_LIGHT_SKINS;

  },
methods:{
     handleDarkModeChange() {
        this.$store.dispatch('ui/toggleDarkMode');
    },

     onNavbarVariantChange(event) {
        if (event && event.target) {
            this.$store.dispatch('ui/setNavbarVariant', event.target.value);
        }
    },

     onSidebarSkinChange(event) {
        if (event && event.target) {
            this.$store.dispatch('ui/setSidebarSkin', event.target.value);
        }
    }

    
},
computed: {
    ...mapGetters({
      darkModeSelected: "ui/darkModeSelected",
      sidebarSkin: "ui/sidebarSkin",
      navbarVariant: "ui/navbarVariant",
      // 'anotherGetter',
      // ...
    }),
}
}
