import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth";
import { list } from "./lists";
import { validation } from "./validation";
import { upload } from "./upload";
import { topics } from "./products/topics";
import { htmlDescription } from "./products/htmlDescription";
import { medias } from "./products/medias";
import { discount } from "./discount";
import { ui } from "./ui";
// import { slides } from "./slides";
import { ratings } from './ratings'

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    messages: [],
    res: [],
    loading: false,
    activeClassText: "",
    success: false,
  },
  mutations: {
    errorHandel(state, messages) {
      state.messages = messages;
      state.loading = false;
    },
    postResponseHandel(state, res) {
      state.res = res;
      state.loading = false;
    },
    activeClassTextMutate(state) {
      state.activeClassText = document.getElementsByClassName(
        "router-link-active"
      )[0].textContent;
    },
  },
  actions: {},
  modules: {
    ui,
    auth,
    list,
    validation,
    upload,
    topics,
    htmlDescription,
    medias,
    discount,
    // slides,
    ratings
  },
});

export default store;
