  <template>
  <div class="row">
    <div class=" col-12">
      <div class="">
        <button class="btn btn-primary" @click="createDataMethode()">
          افزودن اسلاید
        </button>
        <div
          v-for="(item, index) in slides"
          :key="index"
          class="bg-light p-2 mt-4 p-2 rounded "
        >
          <span>{{ item.name }}</span>
          <span
            v-tooltip.top-center="'ویرایش اسلاید'"
            @click="editDataMethode(item)"
            class="pointer text-warning mx-2 h5"
            ><i class="fa fa-edit"></i
          ></span>
          <span
            @click="deleteDataMethode(item.id)"
            class="pointer text-danger  h5"
            v-tooltip.top-center="'remove'"
            ><i class="fa fa-trash"></i
          ></span>
          <span class="mx-4" v-if="item.type === 0">
            <a :href="item.mediaUrl" target="_blank">
              <img
                :src="item.mediaUrl"
                alt="slide-image"
                width="50px"
                height="50px"
            /></a>
          </span>
          <span class="mx-4" v-if="item.type === 1">
            <a :href="item.mediaUrl" target="_blank" class="text-primary">
              مشاهده ویدیو</a
            >
          </span>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit('clickUrl'),showCreateModal ? createData() : editData()">
      <modals
        :modalShow="showCreateModal || showEditModal"
        @closeModalEmited="(showCreateModal = false), (showEditModal = false)"
        class="slide-modal-child"
      >
        <template #header
          >{{ showCreateModal ? "افزودن" : "ویرایش" }} اسلاید
        </template>
        <template #content>
          <div class="row">
            <div class="form-group col-lg-12">
              <label>name</label>
              <input
                type="text"
                class="form-control"
                placeholder="name"
                v-model="models.name"
                
              />
            </div>
            <div class="form-group col-lg-12">
              <label>Display Title</label>
              <input
                type="text"
                class="form-control"
                placeholder="name"
                min="0"
                v-model="models.displayTitle"
              />
            </div>
            <div class="form-group col-lg-6">
              <label>نوع</label>
              <select class="form-control" v-model.number="models.type">
                <option value="">select...</option>

                <option :value="0">image</option>
                <option :value="1">ویدئو</option>
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>See story link</label>

              <select class="form-control" v-model.number="linkType">
                <option :value="0">select...</option>

                <option :value="1">Free</option>
                <option :value="2">Tel</option>
                <option :value="3">App</option>
              </select>
            </div>
            <div class="col-lg-12" v-show="linkType">
              <globalLink
                :type="linkType"
                :key="linkType"
                :value="models.clickUrl"
                :app-slot="appSlot"
                @input="models.clickUrl = $event"
                @selectAppSlot="appSlot = $event"
              />
            </div>
            <div class="form-group col-lg-6">
              <label>Display duration in milliseconds</label>
              <input
                type="number"
                class="form-control"
                placeholder="مدت"
                v-model.number="models.duration"
              />
            </div>
            <div class="form-group col-lg-6">
              <label> انیمیشن</label>
              <input
                type="text"
                class="form-control"
                placeholder="انیمیشن"
                v-model="models.transition"
              />
            </div>
            <div class="form-group col-lg-4">
              <label>Display</label>
              <select class="form-control" v-model="models.isActive" >
                <option value="">select...</option>

                <option :value="true">Active</option>
                <option :value="false">Inactive</option>
              </select>
            </div>
            <div class="form-group col-lg-6" v-if="models.type === 0">
              <label>image</label>
              <!-- custom form upload -->
              <file-upload
                :key="showCreateModal || showEditModal"
                :imageUrl="models.mediaUrl"
                
              />
              <!-- custom form upload -->
            </div>
            <div class="form-group col-lg-12" v-if="models.type === 1">
              <label>ویدیو</label>
              <!-- custom form upload -->
              <video-upload />

              <!-- custom form upload -->
            </div>
            <div class="form-group col-4 mt-4">
              <label>Display Home Splash</label>
              <br />
              <toggle-button v-model="models.isAppSplash" sync class="mr-2" />
            </div>
          </div>
        </template>
        <template #footer>
          <button type="submit" class="btn btn-info">
            Save
          </button>
        </template>
      </modals>
    </form>
  </div>
</template>
<script>
import { mapState } from "vuex";
import CRUDMix from "/src/mixins/lists/CRUD.js";
import staticDataTableMix from "/src/mixins/lists/staticDataTable.js";
import checkLinkMix from "/src/mixins/lists/checkLink.js"
 

export default {
  name: "story-slides",
  props: { storyId: { required: true, type: Number | String } },
  mixins: [CRUDMix, staticDataTableMix,checkLinkMix],
  data() {
    return {
      reqName: "slides", //URL name request To server EX:www.test.com/api/v2/reqName
      models: {
        name: "",
        displayTitle: "",
        type: 0,
        mediaUrl: "",
        clickUrl: "",
        duration: 0,
        transition: "",
        storyId: this.storyId,
        isAppSplash: false,
        priority: 0,
        isActive: true,
      },
    };
  },
  watch: {
    imageDetail() {
      this.models.mediaUrl = window.url + this.imageDetail.url;
    },
    slides() {
      let findMax = Math.max.apply(
        Math,
        this.slides.map(function(o) {
          return o.priority;
        })
      );
      this.models.priority = findMax + 10;
    },
  },
  computed: {
    ...mapState({
      /* just Getter */
      slides: (state) => state.slides.slides,
      imageDetail: (state) => state.upload.imageDetail,
    }),
  },
  methods: {
    fetchData() {
      this.$store.dispatch("slides/getSlidesAction", {
        reqName: this.reqName,
        sorting: `Priority¤2`,
        filter: `StoryId=${this.storyId}¤1µ`,
      });
    },
    resetModels() {
      return {
        name: "",
        displayTitle: "",
        type: 0,
        mediaUrl: "",
        clickUrl: "",
        duration: 0,
        transition: "",
        storyId: this.storyId,
        isAppSplash: false,
        priority: 0,
        isActive: true,
        
      };
    },
    createDataMethode() {
      this.showCreateModal = !this.showCreateModal;
      this.models = this.resetModels();
      this.linkType = 0;
      this.appSlot = "";
    },

    editDataMethode(item) {
      this.showEditModal = !this.showEditModal;
      this.models = { ...item };
      this.checkLink(item, "clickUrl");
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
