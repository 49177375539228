const base = "discount";
import discountList from "@/components/discount/discountsList";


export default [
  {
    path: `/${base}/list`,
    name: "discountList",
    component: discountList,
    meta: { requiresAuth: true },
  }
];
