import { errorHandelMethode } from "../errorsHandel";
export const htmlDescription = {
    namespaced: true,
    state: {
        htmValue: {
            content: null
        },
    },
    mutations: {

        updateDescription(state, data) {
            state.htmValue.content = data;
        },


    },
    actions: {
        async getHtmlByIdAction(context, params) {
            console.log(context);
            context.rootState.loading = true;
            axios
                .get(
                    `/api/${params.reqName}/FullDescription/${params.id}`
                )
                .then(({
                    data
                }) => {
                    context.rootState.loading = false;
                    

                    if (data.succeeded) {
                        context.commit("updateDescription", data.data);
                    } else {
                        errorHandelMethode(context, data)
                    }
                })
                .catch((error) => {
                    console.log(error.response)
                    context.rootState.success = false;
                    errorHandelMethode(context, error.response.data)

                });
        },
        async updateHtmlDescription(context, params) {

            
            context.rootState.loading = true;

            await axios
                .put(`/api/${params.reqName}/UpdateFullDescription/${params.id}`
                    , { id: params.id, fullDescription: params.fullDescription })
                .then(({ data }) => {

                  
                    context.rootState.loading = false;
                    if (data.succeeded) {
                        console.log("sucesss");
                        context.rootState.success = true;
                        // context.commit("updateDescription", data.data);

                    } else {
                        errorHandelMethode(context, data)
                        context.rootState.success = false;

                    }
                })
                .catch((error) => {
                    console.log(error.response)
                    context.rootState.loading = false;
                    context.rootState.success = false;
                    errorHandelMethode(context, error.response.data)
                });
        },

    }


}