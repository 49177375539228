import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";

/* shared Component */
require("./shared.js");
/* shared Component */

/* middaleWare config */
require("./apiConfig");
/* middaleWare config */


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: function(h) {
    return h(App);
  },
}).$mount("#app");
