<template>
  <div>
    <div
      class="row bg-light px-2 py-1 mx-1"
      ref="filterChips"
      v-show="filterChipsShow"
    >
      <span
        class="chip-item table-color px-2 py-1 rounded m-2"
        v-for="(item, index) in filters"
        :key="item.keyName"
        v-show="item.value"
      >
        <span>{{ item.text }} : </span>
        <span v-if="item.valueFarsi" class="text-primary">{{
          item.valueFarsi
        }}</span>
        <span v-else class="text-primary">{{ item.value }}</span>
        <i
          class="fa fa-times mx-1 text-danger pointer"
          @click="removeChip(index)"
        ></i
      ></span>
    </div>
    <hr v-show="filterChipsShow" />
  </div>
</template>
<script>
import setPriceMix from "/src/mixins/lists/setPrice.js";
export default {
  name: "filter-chips",
  mixins: [setPriceMix],
  props: {
    filters: {
      type: Array,
    },
  },
  data() {
    return {
      isMounted: false,
    };
  },
  computed: {
    filterChipsShow() {
      if (this.isMounted) {
        return this.$refs.filterChips.textContent;
      } else return "";
    },
  },
  methods: {
    removeChip(payload) {
      this.$emit("removeChipEmit", payload);
    },
  },
  updated() {
    /* updated when dom full loaded can access $REFS */
    if (this.$refs.filterChips.textContent != "") {
      this.isMounted = true;
    } else this.isMounted = false;
  },
};
</script>
