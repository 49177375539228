const base = "payments";
import bankPayments from "../../components/pays/bankPayments";
import transactions from "../../components/pays/transactions";


export default [

  {
    path: `/${base}/bank-payments`,
    name: "bankPayments",
    component: bankPayments,
    meta: { requiresAuth: true },
  },
  {
    path: `/${base}/transactions`,
    name: "transactions",
    component: transactions,
    meta: { requiresAuth: true },
  },
 
];
