const base = "rating";
import ratingList from "@/components/rating/ratingList";


export default [
  {
    path: `/${base}/list`,
    name: "ratingList",
    component: ratingList,
    meta: { requiresAuth: true },
  }
];
