<template>
  <div class="example-simple">
    <div class="upload">
      <ul>
        <li v-for="file in files" :key="file.id">
          <span>{{ file.name }}</span> - <span>{{ file.size }}</span> -
          <span v-if="file.error">{{ file.error }}</span>
          <span v-else-if="file.success">success</span>
          <span v-else-if="file.active">active</span>
          <span v-else-if="!!file.error">{{ file.error }}</span>
          <span v-else></span>
        </li>
      </ul>
      <div class="example-btn">
        <file-upload-plugin
          class="btn btn-primary"
          extensions=""
          :accept="acceptsFiles"
          v-model="files"
          @input-filter="inputFilter"
          @input-file="inputFile"
          ref="upload"
        >
          <i class="fa fa-plus"></i>
          select file
        </file-upload-plugin>
        <button
          type="button"
          class="btn btn-success"
          v-if="files[0]"
          @click.prevent="uploadFile()"
        >
          <i class="fa fa-arrow-up" aria-hidden="true"></i>
          Upload
        </button>
        <button
          type="button"
          class="btn btn-danger"
          v-if="uploadLoading"
          @click.prevent="upload.active = false"
        >
          <i class="fa fa-stop" aria-hidden="true"></i>
          Stop uploading
        </button>
      </div>
    </div>
  </div>
</template>
<style>
.example-simple label.btn {
  margin-bottom: 0;
  margin-right: 1rem;
}
</style>

<script>
import { mapState } from "vuex";
import FileUploadPlugin from "vue-upload-component";
import formatBytesMix from "/src/mixins/formats/formatBytes.js";
export default {
  components: {
    FileUploadPlugin,
  },
  mixins: [formatBytesMix],
  props: {
    maxSize: {
      type: Number,
      default: 1024 * 1024 * 10,
      required: false,
    },
    acceptsFiles: {
      type: String,
      default:'video/mp4,video/x-m4v,video/*',
      required: false,
    }
  },
  data() {
    return {
      upload: null,
      files: [],
    };
  },
  computed: {
    ...mapState({
      /* just Getter */
      uploadLoading: (state) => state.upload.uploadLoading,
      uploadSuccess: (state) => state.upload.uploadSuccess,
    }),
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file

        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }

        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },
    inputFile(newFile, oldFile) {
      /* 
      if (newFile && !oldFile) {
        // add
        console.log("add", newFile);
      }
      if (newFile && oldFile) {
        // update
        console.log("update", newFile);
      }
      if (!newFile && oldFile) {
        // remove
        console.log("remove", oldFile);
      } */
    },

    uploadFile() {
      const fileSize = this.files[0].size;
      console.log(fileSize);
      if (fileSize > this.maxSize) {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: `The size of the selected file is more than ${this.formatBytes(
            this.maxSize
          )} `,
          allowOutsideClick: true,
          confirmButtonText: "Confirm",
        });
        this.files = [];
      } else {
        let formData = new FormData();
        formData.append("ImageFile", this.files[0].file);

        this.$store.dispatch("upload/uploadImage", formData);
      }
    },
  },
};
</script>
