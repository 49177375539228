import { mapState } from "vuex";
export default{
    computed: {
        ...mapState({
          /* just Getter */
          loading: (state) => state.loading,
          messages: (state) => state.messages,
          results: (state) => state.list.results,
          paginate: (state) => state.list.paginate,
          activeClassText: (state) => state.activeClassText,
        }),
      },
    
}