<template>
  <div>
    <header-table-component />
    <article class="container-fluid container-box p-3 mx-1">
      <div class="row ">
        <div class="col-lg-3">
          <label for="FormControlSelect1" class="">Search</label>
          <input
            type="text"
            ref="search"
            class="form-control relative"
            placeholder="Search (fast)"
            v-model="search"
            @keyup.enter="($store.state.list.paginate.page = 1), fetchData()"
          />
        </div>
        <div class="form-group col-lg-2">
              <label>IsActive</label>
              <select class="form-control" v-model="isCommentPublished">
                <option value="">all</option>
                <option :value="true">Display</option>
                <option :value="false">No display</option>
              </select>
            </div>
        <div class="form-group col-lg-2">
          <label>Sort </label>
          <select class="form-control" v-model="sortName">
            <option
              :value="item.value"
              v-for="(item, index) in fields"
              :key="index"
              v-show="item.value !== null"
              >{{ item.text }}</option
            >
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label>Order by</label>
          <select class="form-control" v-model.number="sortDircetion">
            <option value="1">ascending</option>
            <option value="2">descending</option>
          </select>
        </div>
        <div class="col mt-4">
          <button
            type="button"
            class="btn btn-primary"
            @click="fetchData()"
          >
            <i class="fa fa-check"></i>
            <span class="">
              Filter
            </span>
          </button>
        </div>
      </div>
      <hr />
      <div class="table-custom">
        <table class="table  table-bordered">
          <thead class="thead-light">
            <tr>
              <th
                v-for="(item, index) in fields"
                :key="index"
                v-show="item.value !== ''"
              >
                {{ item.text }}
                <span v-show="sortName === item.value" class="float-left">
                  <i
                    class="fa"
                    :class="[
                      sortDircetion === 1
                        ? 'fa-sort-amount-asc'
                        : 'fa-sort-amount-desc',
                    ]"
                    aria-hidden="true"
                  ></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in results" :key="index">
              <th class="text-center" scope="row">{{ index + 1 }}</th>
              <td>{{ item.comment }}</td>
              <td>{{ item.rate }}</td>

              <td>
                <div class="row">
                  <div class="col-lg-12 ">
                    <select
                      class="form-control"
                      v-model="item.commentPublished"
                      @change="isCommentPublishedMethode(item)"
                    >
                      <option :value="true">Display</option>
                      <option :value="false">No display</option>
                    </select>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <h5 v-show="results.length === 0" class="mt-4 text-center text-warning">
          Not found!
        </h5>
      </div>
      <!-- paginate  v-if="paginate.total > 1"-->

      <div class="mt-4">
        <paginateComponent
          :paginateShared="paginate"
          @clickedCustom="onClickChildPaginate"
        />
      </div>

      <!-- paginate -->
    </article>
    <!-- Create modal -->
    <form @submit.prevent="editData()">
      <modals
        :modalShow="showCreateModal || showEditModal"
        @closeModalEmited="showEditModal = false"
      >
        <template #header> {{ activeClassText }}</template>
        <template #content>
          <div class="row">
            <div class="form-group col-lg-6">
              <label>name</label>
              <input
                type="text"
                class="form-control"
                placeholder="name"
                v-model="models.name"
                required
              />
            </div>
          </div>
        </template>
        <template #footer>
          <button type="submit" class="btn btn-info">
            Save {{ activeClassText }}
          </button>
        </template>
      </modals>
    </form>
    <div>
      <loading :loading="loading" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

import CRUDMix from "/src/mixins/lists/CRUD.js";
import paginateMix from "/src/mixins/lists/paginate.js";
import staticDataTableMix from "/src/mixins/lists/staticDataTable.js";
import defaultErrorMessageMix from "/src/mixins/lists/defaultErrorMessage.js";
import storeStateMix from "/src/mixins/lists/storeState.js";
export default {
  name: "ratings",
  mixins: [
    CRUDMix,
    paginateMix,
    staticDataTableMix,
    defaultErrorMessageMix,
    storeStateMix,
  ],
  data() {
    return {
      reqName: "ratings", //URL name request To server EX:www.test.com/api/v2/reqName
      fields: [
        { value: "", text: "default" },
        { value: null, text: "row" },
        { value: "Comment", text: "comment" },
        { value: "Rate", text: "Score" },
        { value: null, text: "" },
      ],
      sortName: "",
isCommentPublished:"",
      models: {
        items: [],
        published: true,
      },
    };
  },
  watch: {},
  computed: {
    ...mapState({}),
  },

  methods: {
    fetchData() {
      this.$store.dispatch("list/getListsAction", {
        reqName: this.reqName,
        page: this.paginate.page,
        pageSize: this.paginate.pageSize,
        search: `Comment=${this.search}¤5ɖ`,
        sorting: `${this.sortName}¤${this.sortDircetion}`,
        filter: `CommentPublished=${this.isCommentPublished}¤1µ`,
      });
    },
    isCommentPublishedMethode({id,commentPublished}) {
       this.models.items[0] = id
       this.models.published =commentPublished
       this.$store
          .dispatch("ratings/updateComments", {
            data: this.models,
            reqName: 'Ratings/ManageCommentPublished',
          })
          .then(() => {
            this.showEditModal = !this.$store.state.success;
            this.fetchData();
          });

    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style lang="scss"></style>
