const base = "users";

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
import users from "../../components/users/usersList.vue";

export default [
  {
    path: "/users",
    name: "users",
    component: users,
    meta: { requiresAuth: true },
  },
  
];
