export default {
  methods: {
    filterHandeling() {
      this.filters.forEach((filter) => {
        if (filter.value !== "" && filter.value !== null) {
          this.fields.find((field) => {
            let getValue = filter.value === field.value;
            if (getValue) {
              filter.valueFarsi = field.text;
            }
          });
        }
      });
      this.$store.state.list.paginate.page = 1;
      this.showModalFilter = false;
      this.fetchData();
    },
    removeChipEmit(payload) {
      this.filters[payload].value = "";
      this.fetchData();
    },
  },
};
