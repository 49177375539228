
import {
  errorHandelMethode
} from "../errorsHandel";
export const ratings = {
  namespaced: true,
  state: {

  },
  mutations: {


  },
  actions: {
    async updateComments(context, params) {
     
      context.rootState.loading = true;

      await axios
        .post(`/api/${params.reqName}`, params.data)
        .then(({
          data
        }) => {
        

          context.rootState.loading = false;
          if (data.succeeded) {
            console.log("sucesss");
            context.rootState.success = true;
          } else {
            // root:true Set Global Mutate in main store file
            context.commit("errorHandel", data, {
              root: true
            });
            context.rootState.success = false;
          }
        })
        .catch((error) => {
          console.log(error.response)
          context.rootState.success = false;
          /* handeling 901 OR All errors in errorsHandel.js */
          errorHandelMethode(context, error.response.data)
        });
    },


  }
}