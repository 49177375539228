<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">{{ 'Dashboard' }}</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link class="route-content" to="/">{{ 'Home' }}</router-link>
              </li>
              <li class="breadcrumb-item active">Dashboard</li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-info">
              <div class="inner">
                <h3>150</h3>

                <p>New Orders</p>
              </div>
              <div class="icon">
                <i class="fa fa-shopping-bag"></i>
              </div>
              <a href="#" class="small-box-footer"
                >more <i class="fa fa-arrow-circle-right"></i
              ></a>
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-success">
              <div class="inner">
                <h3>67<sup style="font-size: 20px">%</sup></h3>

                <p>Bounce Rate</p>
              </div>
              <div class="icon">
                <i class="fa fa-bar-chart"></i>
              </div>
              <a href="#" class="small-box-footer"
                >more <i class="fa fa-arrow-circle-right"></i
              ></a>
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-warning">
              <div class="inner">
                <h3>33</h3>

                <p>User Registrations</p>
              </div>
              <div class="icon">
                <i class="fa fa-user-plus"></i>
              </div>
              <a href="#" class="small-box-footer"
                >more <i class="fa fa-arrow-circle-right"></i
              ></a>
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-danger">
              <div class="inner">
                <h3>65</h3>

                <p>Unique Visitors</p>
              </div>
              <div class="icon">
                <i class="fa fa-pie-chart"></i>
              </div>
              <a href="#" class="small-box-footer"
                >more <i class="fa fa-arrow-circle-right"></i
              ></a>
            </div>
          </div>
          <!-- ./col -->
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
      <div class="row my-5">
        <div class="col-lg-6 charts">
          <div class="p-2 mt-2">
            <h5 class="">The chart of registered users in the last 15 days</h5>
            <hr />
          </div>
          <line-chart
            :chart-data="datacollection"
            :options="options"
            :height="250"
          ></line-chart>
        </div>
        <div class="col-lg-6 charts">
          <div class="p-2 mt-2">
            <h5 class="">Chart of transactions ordered in the last 15 days</h5>
            <hr />
          </div>
          <line-chart
            :chart-data="datacollection"
            :options="options"
            :height="250"
          ></line-chart>
        </div>
      </div>
      <!-- /.row (main row) -->

      <!-- /.container-fluid -->
    </section>
  </div>
</template>
<script>
import lineChart from "./lineChart.js";

export default {
  components: {
    lineChart,
  },
  data() {
    return {
      options: {
        legend: {
          display: false,
        },
      },
      datacollection: {
        labels: [
          "week 1",
          "week 2",
          "week 3",
          "week 4",
          "week 5",
          "week 6",
          "week 7",
          "week 8",
          "week 9",
          "week 10",
        ],
        datasets: [
          {
            data: [282, 350, 411, 502, 635, 809, 947, 1402, 3700, 5267],
            label: "Asia",
            borderColor: "#343a40",
            fill: true,
          },
        ],
      },
    };
  },

  methods: {
    fetchData() {},
  },
  created() {},
};
</script>
<style lang="scss"></style>
