<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 d-flex">
            <slot name="title"></slot>
            
            {{ activeClassText }}
          </h1>
        </div>
        <!-- /.col -->
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item">
              <router-link class="route-content" to="/">{{
                "Dashboard"
              }}</router-link>
            </li>
            <li class="breadcrumb-item active">{{ activeClassText }}</li>
          </ol>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "header-table",
  computed: {
    ...mapState({
      /* just Getter */
      activeClassText: (state) => state.activeClassText,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit("activeClassTextMutate");
    }, 10);
  },
};
</script>
