<template>
  <div>
    <header-table-component />
    <article class="container-fluid container-box p-3 mx-1">
      <div class="row ">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="FormControlSelect1" class="">Search</label>
            <input
              type="text"
              class="form-control relative"
              placeholder="Search (fast)"
              v-model.lazy="search"
              @keyup.enter="($store.state.list.paginate.page = 1), fetchData()"
            />
          </div>
        </div>

        <div class="col mt-4">
          <button
            type="button"
            class="btn btn-primary"
            @click="fetchData()"
          >
            <i class="fa fa-refresh"></i>
          </button>

          <button
            class="btn btn-info mx-1"
            @click="showModalFilter = !showModalFilter"
          >
            <i class="fa fa-filter mx-1"></i>
            Filters
          </button>
          <div class="btn-group " :class="{ show: dropDown }">
            <button
              type="button"
              class="btn btn-info dropdown-toggle"
              @click="dropDown = !dropDown"
            >
              Export
            </button>
            <div
              class="dropdown-menu "
              :class="{ show: dropDown }"
              style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;"
            >
              <a class="dropdown-item pointer"
                ><i class="fa fa-file-excel-o mx-1 "></i>Excel</a
              >
            
            </div>
          </div>
        </div>
      </div>
      <hr />

      <ListFilters
        :search="search"
        :fields="fields"
        :filterList="filterList"
        :isActiveCheck="isActiveCheck"
        @fetchDataEmit="fetchDataEmit"
      />

      <div class="table-custom">
        <table class="table  table-bordered">
          <thead class="thead-light">
            <tr>
              <th
                v-for="(item, index) in fields"
                :key="index"
                v-show="item.value !== ''"
              >
                {{ item.text }}
                <span
                  v-show="filterList.sortingField === item.value"
                  class="float-left"
                >
                  <i
                    class="fa"
                    :class="[
                      sortDircetion === 1
                        ? 'fa-sort-amount-asc'
                        : 'fa-sort-amount-desc',
                    ]"
                    aria-hidden="true"
                  ></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in results" :key="index">
            <tr>
              <th class="text-center" scope="row">{{ index + 1 }}</th>
              <td>{{ item.firstName }} {{ item.lastName }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.phoneNumber }}</td>
              <td>
                <span v-if="item.gender === 0">خانم</span
                ><span v-else>
                  آقا
                </span>
              </td>
              <td>
                {{
                  formatDate(item.dateOfBirth)
                 
                }}
              </td>
              <td :class="item.isActive ? 'text-success' : 'text-danger'">
                <span v-if="item.isActive">Active</span>
                <span v-else> Inactive</span>
              </td>

              <td>
                <div class="row">
                  <div class="col-lg-4 ">
                    <button
                      class="btn btn-custom px-2 d-flex flex-column"
                      @click="deleteDataMethode(item.id)"
                    >
                      <i class="fa fa-trash mx-auto"></i>
                      <span class="mx-auto">
                        remove
                      </span>
                    </button>
                  </div>
                  <div class="col-lg-4 " @click="editDataMethode(item)">
                    <button class="btn btn-custom px-2 d-flex flex-column">
                      <i class="fa fa-edit mx-auto"></i>
                      <span class="mx-auto">
                        Edit
                      </span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <h5 v-show="results.length === 0" class="mt-4 text-center text-warning">
          Not found!
        </h5>
      </div>
      <!-- paginate  v-if="paginate.total > 1"-->

      <div class="mt-4">
        <paginateComponent
          :paginateShared="paginate"
          @clickedCustom="onClickChildPaginate"
        />
      </div>

      <!-- paginate -->
    </article>

    <!-- comment user modal -->
    <form @submit.prevent="editData()">
      <modals :modalShow="showEditModal" @closeModalEmited="showEditModal = false">
        <template #header> Edit </template>
        <template #content>
          <div class="row">
            <div class="form-group col-lg-6">
              <label>نام</label>
              <input
                type="text"
                class="form-control"
                placeholder="name"
                v-model="models.firstName"
                required
              />
            </div>
            <div class="form-group col-lg-6">
              <label>Full name</label>
              <input
                type="text"
                class="form-control"
                placeholder="name"
                v-model="models.lastName"
                required
              />
            </div>
            <div class=" col-lg-6 mb-4">
              <label class="float-right"> Date of birth</label>
              <date-picker
                v-model="models.dateOfBirth"
                color="#41b887"
                class="date-time-picker date-time-picker-products"
                format="YYYY-MM-DD"
                display-format="dddd jDD jMMMM jYYYY"
                clearable
                required
              />
            </div>
            <div class="form-group col-lg-6">
              <label>Display</label>
              <select class="form-control" v-model="models.isActive">
                <option value="">select...</option>

                <option :value="true">Active</option>
                <option :value="false">Inactive</option>
              </select>
            </div>
          </div>
        </template>
        <template #footer>
          <button type="submit" class="btn btn-info">
            Save تغییرات
          </button>
        </template>
      </modals>
    </form>
    <!-- comment user modal -->
    <!-- filters Modal -->
    <modals
    :modalShow="showModalFilter"
      @closeModalEmited="showModalFilter = false"
    >
      <template #header> Filters </template>
      <template #content>
        <form>
          <div class="row">
            <div class="form-group col-lg-6">
              <label>Sort </label>
              <select class="form-control" v-model="sortingField">
                <option
                  :value="item.value"
                  v-for="(item, index) in fields"
                  :key="index"
                  v-show="item.value !== null"
                  >{{ item.text }}</option
                >
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>Order by</label>
              <select class="form-control" v-model.number="sortDircetion">
                <option value="1">ascending</option>
                <option value="2">descending</option>
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>IsActive</label>
              <select class="form-control" v-model="isActiveCheck">
                <option value="">all</option>
                <option :value="true">Active</option>
                <option :value="false">Inactive</option>
              </select>
            </div>
          </div>
        </form>
      </template>
      <template #footer>
        <button
          type="button"
          class="btn btn-info"
          @click="
            ($store.state.list.paginate.page = 1),
              fetchData(),
              (showModalFilter = false)
          "
        >
          Filter
        </button>
      </template>
    </modals>
    <!-- filters Modal -->

    <div>
      <loading :loading="loading" />
    </div>
  </div>
</template>
<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import CRUDMix from "/src/mixins/lists/CRUD.js";
import paginateMix from "/src/mixins/lists/paginate.js";
import staticDataTableMix from "/src/mixins/lists/staticDataTable.js";
import defaultErrorMessageMix from "/src/mixins/lists/defaultErrorMessage.js";
import storeStateMix from "/src/mixins/lists/storeState.js";
import formatDateMix from "/src/mixins/lists/formatTime.js"

export default {
  name: "users",
  mixins: [
    CRUDMix,
    paginateMix,
    staticDataTableMix,
    defaultErrorMessageMix,
    storeStateMix,
    formatDateMix
  ],
  data() {
    return {
      reqName: "users", //URL name request To server EX:www.test.com/api/v2/reqName
      fields: [
        { value: "", text: "default" },
        { value: null, text: "" },
        { value: "LastName", text: "Full name" },
        { value: "Email", text: "Email " },
        { value: null, text: "Phone number" },
        { value: null, text: "Gender" },
        { value: "DateOfBirth", text: "Date of birth " },
        { value: "IsActive", text: "IsActive" },
        { value: null, text: "" },
      ],
      models: {
        id: "",
        firstName: "",
        lastName: "",
        isActive: true,
        dateOfBirth: "",
      },
      isActiveCheck: "",
      filterList: {
        sortingField: "",
        isActive: "",
      },
    };
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("list/getListsAction", {
        reqName: this.reqName,
        page: this.paginate.page,
        pageSize: this.paginate.pageSize,
        search: `FirstName=${this.search}¤5ɖLastName=${this.search}¤5ɖEmail=${this.search}¤5ɖPhoneNumber=${this.search}¤5ɖ`,
        sorting: `${this.sortingField}¤${this.sortDircetion}`,
        filter: `IsActive=${this.isActiveCheck}¤1µ`,
      });

      this.checkListClick();
    },
    checkListClick() {
      this.filterList.sortingField = this.sortingField;
      this.filterList.isActive = this.isActiveCheck;
    },

    editDataMethode(item) {
      
      this.showEditModal = !this.showEditModal;
      this.models.id = item.id;
      this.models.firstName = item.firstName;
      this.models.lastName = item.lastName;
      this.models.isActive = item.isActive;
      this.models.dateOfBirth = item.dateOfBirth;
    },
  },
  created() {
    this.fetchData();
  },
  components: { "date-picker": VuePersianDatetimePicker },
};
</script>
<style lang="scss"></style>
