<template>
  <div>
    <header-table-component />
    <article class="container-fluid container-box p-3 mx-1">
      <div class="row">
        <div class="col-lg-3 col-sm-4">
          <div class="form-group">
            <label for="FormControlSelect1" class="">Search</label>
            <input type="text" for="FormControlSelect1" class="form-control relative" placeholder="Search (fast)"
              v-model.lazy="search" @keyup.enter="($store.state.list.paginate.page = 1), fetchData()" />
          </div>
        </div>

        <div class="col mt-4 col-sm-8">
          <button type="button" class="btn btn-primary mt-2" @click="fetchData()">
            <i class="fa fa-refresh"></i>
          </button>
          <button class="btn btn-success mx-1  mt-2" @click="createDataMethode()">
            <i class="fa fa-plus mx-1"></i>
            <span class=""> New </span>
          </button>
          <button class="btn btn-info mx-1  mt-2" @click="showModalFilter = !showModalFilter">
            <i class="fa fa-filter mx-1"></i>
            Filters
          </button>
          <div class="btn-group  mt-2" :class="{ show: dropDown }">
            <button type="button" class="btn btn-info dropdown-toggle" @click="dropDown = !dropDown">
              Export
            </button>
            <div class="dropdown-menu" :class="{ show: dropDown }" style="
                position: absolute;
                transform: translate3d(0px, 38px, 0px);
                top: 0px;
                left: 0px;
                will-change: transform;
              ">
              <a class="dropdown-item pointer"><i class="fa fa-file-excel-o mx-1"></i>Excel</a>

            </div>
          </div>
        </div>
      </div>
      <hr />

      <ListFilters :search="search" :fields="fields" :filterList="filterList" :isActiveCheck="isActiveCheck"
        :showFilterChips="showFilterChips" @fetchDataEmit="fetchDataEmit" />

      <div class="table-custom">
        <table class="table table-bordered">
          <thead class="thead-light">
            <tr>
              <th v-for="(item, index) in fields" :key="index" v-show="item.value !== ''">
                {{ item.text }}
                <span v-show="filterList.sortingField === item.value" class="float-left">
                  <i class="fa" :class="[
                    sortDircetion === 1
                      ? 'fa-sort-amount-asc'
                      : 'fa-sort-amount-desc',
                  ]" aria-hidden="true"></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in results" :key="item.id">
              <td scope="row" class="min-table-200">
                <span v-tooltip.top-center="'remove'" class="text-danger mx-1 pointer h4">
                  <i class="fa fa-trash mx-auto" @click="deleteDataMethode(item.id)"></i>
                </span>

                <span v-tooltip.top-center="'Edit'" class="text-gray h4">
                  <i class="fa fa-edit mx-auto" @click="editDataMethode(item)"></i>
                </span>

                <span v-tooltip.top-center="'full description'" class="text-primary pointer h4 mx-2" @click="
                  (showDescriptionModal = !showDescriptionModal),
                  (productId = item.id),
                  (fullDescription = item.fullDescription)
                  ">
                  <i class="fa fa-html5 mx-auto"></i>
                </span>
                <!-- <span
                  v-tooltip.top-center="'Topics and medias'"
                  class="text-gray h4"
                  @click="
                    (showTopicModal = !showTopicModal), (productId = item.id)
                  "
                >
                  <i class="fa fa-comments mx-auto"></i>
                </span> -->
                <span v-tooltip.top-center="'(ID)'" class="text-gray h4 mx-2" @click="
                  copyId(item.id),
                  showAlert(),
                  (showAlertText = 'Successfully copied')
                  ">
                  <i class="fa fa-id-card mx-auto"></i>
                </span>
              </td>
              <td class="p-0">
                <span v-if="item.coverImage">
                  <a :href="window + item.coverImage.url" target="_blank">
                    <img :src="window + item.coverImage.thumbnailUrl" alt="content_IMG" class="img-fluid" width="75px"
                      height="35px" />
                  </a>
                </span>
              </td>
              <td class="min-table-200">{{ item.name }}</td>
              <td class="min-table-200">{{ item.subName }}</td>
              <!-- <td class="min-table-400">
                {{
                  item.description
                    ? item.description.substring(0, 18) + ".."
                    : ""
                }}
              </td> -->
              <td>
                {{ setPrice(item.price) }}
              </td>
              <td :class="item.isActive ? 'text-success' : 'text-danger'">
                <span v-if="item.isActive">Active</span>
                <span v-else> Inactive</span>
              </td>
              <td>{{ item.rating }}</td>

              <td class="min-table-300">
                <span v-for="tag in item.tags" :key="tag.id">
                  {{ tag.name }},
                </span>
              </td>

              <td>{{ item.priority }}</td>


              <td>{{ item.duration }}</td>
              <td>
                <span :id="'publish-date' + item.id">
                  {{ formatDate(item.publishDate) }}</span>
                <b-tooltip :target="'publish-date' + item.id" placement="bottom">
                  {{ formatTime(item.publishDate) }}
                </b-tooltip>
              </td>
            </tr>
          </tbody>
        </table>
        <h5 v-show="results.length === 0" class="mt-4 text-center text-warning">
          Not found!
        </h5>
      </div>
      <!-- paginate  v-if="paginate.total >. 1"-->

      <div class="mt-4">
        <paginateComponent :paginateShared="paginate" @clickedCustom="onClickChildPaginate" />
      </div>

      <!-- paginate -->
    </article>
    <!-- Create modal -->

    <modals name="create-edit-m" modalSize="xl" :modalShow="showCreateModal || showEditModal"
      @closeModalEmited="(showCreateModal = false), (showEditModal = false)">
      <template #header> {{ activeClassText }}</template>
      <template #content>
        <form id="myform" @submit.prevent="showCreateModal ? createData() : editData()">
          <div class="row">
            <div class="form-group col-lg-6">
              <label>name</label>
              <input type="text" class="form-control" placeholder="name" v-model="models.name" required />
            </div>
            <div class="form-group col-lg-6">
              <label>Sub name(title)</label>
              <input type="text" class="form-control" placeholder="name" v-model="models.subName" />
            </div>

            <div class="form-group col-lg-6">
              <label>description</label>

              <textarea class="form-control" placeholder="description" v-model="models.description" rows="3"></textarea>
            </div>

            <div class="form-group col-lg-6">
              <label>Insight description</label>

              <textarea class="form-control" v-model="models.insightDescription" rows="3"></textarea>
            </div>
            <div class="form-group col-lg-6">
              <label>Price with discount (CAD)</label>
              <input type="number" class="form-control" v-model.number="models.price" />
            </div>
            <div class="form-group col-lg-6">
              <label> Initial price</label>
              <input type="number" class="form-control" v-model.number="models.priceOrginal" />
            </div>

            <div class="form-group col-lg-6">
              <label>Duration</label>
              <input type="number" class="form-control" placeholder="Duration in minutes"
                v-model.number="models.duration" />
            </div>

            <div class="form-group col-lg-12">
              <label class="typo__label">Tags</label>
              <multiselect v-model="selectedTags" :options="tags" :multiple="true" :hideSelected="true" return="id"
                label="name" track-by="id" placeholder="Select tag or Search" selectLabel="Enterr or click."
                selectedLabel="Selected"></multiselect>
            </div>
            <div class="form-group col-lg-12">
              <label class="typo__label">Description</label>
              <multiselect v-model="selectedCategories" :options="categories" :multiple="true" :hideSelected="true"
                return="id" label="name" track-by="id" placeholder="Select category or search"
                selectLabel="Enterr or click." selectedLabel="Selected"></multiselect>
            </div>
            <div class="form-group col-lg-4">
              <label>Priority</label>
              <select class="form-control" v-model="models.priority">
                <option :value="index" v-for="(item, index) in 10" :key="index">
                  {{ index }}
                </option>
              </select>
            </div>
            <div class="form-group col-lg-4">
              <label>Display</label>
              <select class="form-control" v-model="models.isActive">
                <option value="">select...</option>

                <option :value="true">Active</option>
                <option :value="false">Inactive</option>
              </select>
            </div>
            <div class="form-group col-lg-4">
              <label>is admin choice</label>
              <select class="form-control" v-model="models.isAdminChoice">
                <option value="">select...</option>

                <option :value="true">Active</option>
                <option :value="false">Inactive</option>
              </select>
            </div>
            <div class="form-group col-lg-4">
              <label>Score</label>
              <select class="form-control" v-model.number="models.rating">
                <option :value="index" v-for="(item, index) in 5" :key="index">
                  {{ index + 1 }}
                </option>
              </select>
            </div>
            <div class="form-group col-lg-4">
              <label> Number of voters</label>
              <input type="number" class="form-control" v-model.number="models.voterCount" />
            </div>
            <div class="form-group col-lg-4">
              <label>participant Count</label>
              <input type="number" class="form-control" v-model.number="models.participateCount" />
            </div>

            <div class="form-group col-3 mt-3">
              <label>has audio: </label>

              <toggle-button v-model="models.hasAudio" sync class="mr-2" />
            </div>

            <div class="form-group col-3 mt-3">
              <label>has video: </label>

              <toggle-button v-model="models.hasVideo" sync class="mr-2" />
            </div>
            <div class="form-group col-3 mt-3">
              <label>has pdf: </label>
              <toggle-button v-model="models.hasPdf" sync class="mr-2" />
            </div>
            <div class="form-group col-3 mt-3">
              <label>has license: </label>

              <toggle-button v-model="models.hasLicense" sync class="mr-2" />
            </div>
            <br style="clear: both" />
            <div class="col-lg-6 mb-4">
              <label class=""> Release date </label>
              <!-- <date-picker v-model="models.publishDate" id="publishDate" color="#41b887"
                class="date-time-picker date-time-picker-products" format="YYYY-MM-DD"
                display-format="dddd jDD jMMMM jYYYY" clearable /> -->

              <b-form-datepicker :model="models.publishDate" id="publishDate" placeholder="Type or select date"
                :min="minDate" clearable :typeable="true" />
            </div>
            <div class="col-lg-6 mb-4">
              <label class="float-right"> last Update</label>
              <!-- <date-picker v-model="models.lastUpdate" id="lastUpdate" color="#41b887"
                class="date-time-picker date-time-picker-products" :min="minDate" format="YYYY-MM-DD"
                display-format="dddd jDD jMMMM jYYYY" clearable /> -->
              <b-form-datepicker v-model="models.lastUpdate" id="lastUpdate" color="#41b887"
                placeholder="Type or select date" :min="minDate" format="YYYY-MM-DD"
                display-format="dddd jDD jMMMM jYYYY" clearable :typeable="true" />
            </div>
            <div class="form-group col-lg-12">
              <label>license description</label>
              <input type="text" class="form-control" v-model="models.licenseDescription" />
            </div>

            <div class="form-group col-lg-6">
              <label>image</label>
              <!-- custom form upload -->
              <div>
                <file-upload input-id="product-img" :imageUrl="models.image ? models.image.thumbnailUrl : ''"
                  :fileId="models.imageId ? models.imageId : null" />
              </div>
              <!-- custom form upload -->
            </div>
          </div>
        </form>
      </template>
      <template #footer>
        <button type="submit" form="myform" class="btn btn-info">
          Save {{ activeClassText }}
        </button>
      </template>
    </modals>
    <!-- Create modal -->
    <!-- filters Modal -->
    <modals name="filter-m" :modalShow="showModalFilter" @closeModalEmited="showModalFilter = false">
      <template #header> Filters </template>
      <template #content>
        <form>
          <div class="row">
            <div class="form-group col-lg-6">
              <label>Sort </label>
              <select class="form-control" v-model="sortingField">
                <option :value="item.value" v-for="(item, index) in fields" :key="index" v-show="item.value !== null">
                  {{ item.text }}
                </option>
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>Order by</label>
              <select class="form-control" v-model.number="sortDircetion">
                <option value="1">ascending</option>
                <option value="2">descending</option>
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>IsActive</label>
              <select class="form-control" v-model="isActiveCheck">
                <option value="">all</option>
                <option :value="true">Active</option>
                <option :value="false">Inactive</option>
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>Price from</label>
              <input type="number" class="form-control" placeholder="Price" v-model.number="filterList.minPrice"
                @keydown="showFilterChips.showMinPrice = false" />
            </div>
            <div class="form-group col-lg-6">
              <label>Price to</label>
              <input type="number" class="form-control" placeholder="Price" v-model.number="filterList.maxPrice"
                @keydown="showFilterChips.showMaxPrice = false" />
            </div>
          </div>
        </form>
      </template>
      <template #footer>
        <button type="button" class="btn btn-info" @click="
          ($store.state.list.paginate.page = 1),
          fetchData(),
          (showModalFilter = false)
          ">
          Filter
        </button>
      </template>
    </modals>
    <!-- filters Modal -->
    <!-- descriptionModal  -->
    <modals name="desc-m" modalSize="xl" :modalShow="showDescriptionModal" :hideFooter="true"
      @closeModalEmited="showDescriptionModal = false">
      <template #header> description</template>
      <template #content>
        <descriptionModal @clickedCustomCloseModal="onClickChildModal()" :key="showDescriptionModal"
          :productId="productId" :fullDescription="fullDescription" class="" />
      </template>
      <template #footer> </template>
    </modals>

    <!-- descriptionModal  -->

    <div>
      <loading :loading="loading" />
    </div>
    <b-alert class="" :show="dismissCountDown" dismissible fade variant="success" @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChange">
      {{ showAlertText }}
    </b-alert>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
// import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import descriptionModal from "../products/childs/productListDescriptionModal";
import CRUDMix from "/src/mixins/lists/CRUD.js";
import paginateMix from "/src/mixins/lists/paginate.js";
import staticDataTableMix from "/src/mixins/lists/staticDataTable.js";
import defaultErrorMessageMix from "/src/mixins/lists/defaultErrorMessage.js";
import storeStateMix from "/src/mixins/lists/storeState.js";
import setPriceMix from "/src/mixins/lists/setPrice.js";
import formatDateMix from "/src/mixins/lists/formatTime.js";
import copyMix from "/src/mixins/lists/copy.js";
import alertMix from "/src/mixins/alert/alert.js";

export default {
  name: "product-list",
  mixins: [
    CRUDMix,
    paginateMix,
    staticDataTableMix,
    defaultErrorMessageMix,
    storeStateMix,
    setPriceMix,
    formatDateMix,
    copyMix,
    alertMix,
  ],
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const mindate = new Date(today);
    mindate.setMonth(mindate.getMonth() - 1);
    // mindate.setDate(15)
    return {
      minDate: mindate,
      reqName: "products", //URL name request To server EX:www.test.com/api/v2/reqName
      showTopicModal: false,
      showDescriptionModal: false,
      fields: [
        { value: "", text: "Default" },
        { value: null, text: "Operations" },
        { value: null, text: "Image" },
        { value: "Name", text: "Name" },
        { value: null, text: "Sub name" },
        // { value: "Description", text: "description" },
        { value: "Price", text: "Price" },
        { value: "IsActive", text: "IsActive " },
        { value: "Rating", text: "Rating" },
        { value: "Tags", text: "Tags" },
        { value: "Priority", text: "Priority" },
        { value: null, text: "Duration (minutes) " },
        { value: "PublishDate", text: "Release date" },
      ],
      models: {
        id: null,
        name: null,
        subName: null,
        description: null,
        insightDescription: null,
        price: 0,
        priceOrginal: 0,
        tags: [],
        categories: [],
        duration: null,
        priority: 1,
        isActive: true,
        isAdminChoice: false,
        rating: 1,
        voterCount: 0,
        participateCount: 0,
        hasAudio: false,
        hasVideo: false,
        hasPdf: false,
        publishDate: new Date().toISOString().split("T")[0],
        lastUpdate: new Date().toISOString().split("T")[0],
        hasLicense: false,
        licenseDescription: null,
        image: {},
        imageId: null,
      },
      isActiveCheck: "",
      filterList: {
        sortingField: "",
        isActive: "",
        minPrice: "",
        maxPrice: "",
      },
      showFilterChips: {
        showMinPrice: false,
        showMaxPrice: false,
      },
      selectedTags: [],
      selectedCategories: [],
      productId: "",
      fullDescription: "",
    };
  },
  watch: {
    imageDetail() {
      this.models.image = this.imageDetail;
      this.models.imageId = this.imageDetail.id;
    },
    selectedTags(newValues) {
      this.models.tags = newValues.map((obj) => obj.id);
    },
    selectedCategories(newValues) {
      this.models.categories = newValues.map((obj) => obj.id);
    },
    // "filterList.instructorsSelected"() {
    //   if (this.filterList.instructorsSelected === "") {
    //     this.filterList.instructorsFirstName = "";
    //     this.filterList.instructorsLastName = "";
    //   } else {
    //     const instructorSelected = this.instructors.find(
    //       (x) => x.id === this.filterList.instructorsSelected
    //     );
    //     this.filterList.instructorsFirstName = instructorSelected.firstName;
    //     this.filterList.instructorsLastName = instructorSelected.lastName;
    //   }
    // },
  },
  computed: {
    ...mapState({
      /* just Getter */
      imageDetail: (state) => state.upload.imageDetail,
      tags: (state) => state.list.tags,
      categories: (state) => state.list.categories,
    }),
  },

  methods: {
    async fetchData() {
      await this.$store.dispatch("list/getListsAction", {
        reqName: this.reqName,
        page: this.paginate.page,
        pageSize: this.paginate.pageSize,
        search: `Name=${this.search}¤5ɖDescription=${this.search}¤5ɖ`,
        sorting: `${this.sortingField}¤${this.sortDircetion}`,
        filter: `IsActive=${this.isActiveCheck}¤1µPrice=${this.filterList.minPrice}¤12µPrice=${this.filterList.maxPrice}¤14µ`,
      });

      this.checkListClick();
    },
    checkListClick() {
      this.filterList.sortingField = this.sortingField;
      this.filterList.isActive = this.isActiveCheck;

      this.showFilterChips.showMinPrice = !!this.filterList.minPrice;
      this.showFilterChips.showMaxPrice = !!this.filterList.maxPrice;
    },
    async createDataMethode() {
      this.showCreateModal = !this.showCreateModal;
      await this.resetDataModel();
    },
    resetDataModel() {
      this.models.id = null;
      this.models.name = null;
      this.models.subName = null;
      this.models.description = null;
      this.models.insightDescription = null;
      this.models.price = 0;
      this.models.priceOrginal = 0;
      this.models.tags = [];
      this.models.categories = [];
      this.models.duration = 0;
      this.models.priority = 0;
      this.models.isActive = false;
      this.models.isAdminChoice = false;
      this.models.rating = 1;
      this.models.voterCount = 0;
      this.models.participateCount = 0;
      this.models.hasAudio = false;
      this.models.hasVideo = false;
      this.models.hasPdf = false;
      this.models.publishDate = new Date().toISOString().split("T")[0];
      this.models.lastUpdate = new Date().toISOString().split("T")[0];
      this.models.imageId = null;
      this.models.image = {};
      this.models.hasLicense = false;
      this.models.licenseDescription = null;
      this.selectedTags = [];
      this.selectedCategories = [];
    },
    editDataMethode(item) {
      this.showEditModal = !this.showEditModal;
      this.updateDataModel(item);
    },
    updateDataModel(item) {

      for (const property in this.models) {
        this.models[property] = item[property];
      }
      if (item.coverImage) {
        this.models.imageId = item.coverImage.id;
        this.models.image = item.coverImage;
      }

      this.selectedTags = item.tags;
      this.selectedCategories = item.categories;
    },
    onClickChildModal() {
      this.showDescriptionModal = false;
      this.fetchData();
    },

    fetchTags() {
      this.$store.dispatch("list/fetchTagListForProductAction", {
        reqName: "tags",
        page: 1,
        pageSize: -1,
        search: ``,
        sorting: `1`,
        filter: ``,
      });
    },
    fetchCategories() {
      this.$store.dispatch("list/fetchCategoryListForProductAction", {
        reqName: "categories",
        page: 1,
        pageSize: -1,
        search: ``,
        sorting: `1`,
        filter: ``,
      });
    },
    // fetchInstructors() {
    //   this.$store.dispatch("list/fetchInstructorListForProductAction", {
    //     reqName: "instructors",
    //     page: 1,
    //     pageSize: -1,
    //     search: ``,
    //     sorting: `1`,
    //     filter: `IsActive=true¤1µ`,
    //   });
    // },
    // customLabelInstructor({ firstName, lastName }) {
    //   return `${firstName} ${lastName}`;
    // },

    /*  currectFormat() {
      this.models.price = parseInt(this.models.price.replace(/,/g, ""));
      this.models.priceOrginal = parseInt(
        this.models.priceOrginal.replace(/,/g, "")
      );
    }, */
  },
  created() {
    this.fetchData();
    this.fetchTags();
    this.fetchCategories();
    // this.fetchInstructors();
  },
  components: {
    Multiselect,
    descriptionModal,
  },
};
</script>
<style lang="scss">
.topic-modal {
  #customModal {
    .modal-dialog {
      max-width: 85%;
    }
  }
}

@media (max-width: 576px) {
  .topic-modal {
    #customModal .modal-dialog {
      max-width: unset;
    }
  }
}
</style>
