<template>
  <div id="app">
    <div class="wrapper" v-if="auth">
      <nav
        id="main-header"
        class="main-header navbar navbar-expand"
        :class="navbarVariant"
      >
        <ul class="navbar-nav">
          <li class="nav-item pointer">
            <a class="nav-link" @click="onToggleMenuSidebar">
              <i class="fa fa-bars"></i>
            </a>
            <!-- <a class="nav-link">
            <i :class="['fa', [menuShow ? 'fa-bars' : 'fa-bars']]"></i>
          </a> -->
          </li>
        </ul>

        <ul class="navbar-nav ml-auto">
          <li class="nav-item dropdown pointer" @click="notificationToggle()">
            <a class="nav-link">
              <i class="fa fa-comments-o"></i>
              <span class="badge badge-danger navbar-badge">1</span>
            </a>
          </li>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-right show"
            v-show="showNotification"
          >
            <a href="#" class="dropdown-item">
              <div class="media">
                <button
                  type="button"
                  class="close text-danger p-2"
                  @click="showNotification = !showNotification"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <img
                  src="./assets/image/default-profile.png" 
                  alt=""
                  class="img-size-50 ml-3 img-circle"
                />
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    Arman Abi
                    <span class="float-left text-sm text-danger"
                      ><i class="fa fa-star"></i
                    ></span>
                  </h3>
                  <p class="text-sm">please contact me...</p>
                  <p class="text-sm text-muted">
                    <i class="fa fa-clock-o mr-1"></i> before 4 hours
                  </p>
                </div>
              </div>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item dropdown-footer">View all messages</a>
          </div>
          <li class="nav-item pointer">
            <a class="nav-link" @click="logOut()">
              <i class="nav-icon fa fa-sign-out"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="onToggleControlSidebar">
              <i class="fa fa-th-large"></i>
            </a>
          </li>
        </ul>
      </nav>
      <control-sidebar></control-sidebar>


      <aside class="main-sidebar elevation-4" :class="sidebarSkin">
        <a class="brand-link">
          <img
            src="./assets/logo.png"
            class="img-circle elevation-2 brand-image"
            alt="panemor"
          />
          <span class="brand-text font-weight-light"> Panemor</span>
        </a>
        <div class="sidebar">
          <div>
            <div class="user-panel mt-3 pb-3 mb-3 d-flex">
              <div class="image">
                <!-- <img
                  src="./assets/logo.png"
                  class="img-circle elevation-2"
                  alt="User Image"
                /> -->
                <img
                  src="./assets/image/default-profile.png"
                  fallbackSrc="./assets/image/default-profile.png"
                  class="img-circle"
                  alt="User Image"
                  width="34"
                  height="34"
                  rounded
                />
              </div>
              <div class="info">
                <a class="d-block">{{ user.firstName }} {{ user.lastName }}</a>
              </div>
            </div>
            <nav class="mt-2">
             
                  <!-- views -->
                  <items-list-layout />

                  <!-- views -->
               
            
              <!-- views -->
              <!--      <pays-layout />
              <messages-layout />
              <setting-layout /> -->
              <!-- views -->
              <ul class="nav nav-pills nav-sidebar flex-column">
                <li class="nav-item has-treeview pointer">
                  <a class="nav-link" @click="logOut()">
                    <i class="nav-icon fa fa-sign-out"></i>
                    <p>Log out</p>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </aside>
      <!-- </transition> -->

      <div class="content-wrapper" @click="closeMenuResponsive()">
        <section class="container-fluid">
          <router-view :key="$route.fullPath" />
        </section>
      </div>
      <div
        id="sidebar-overlay"
        v-if="screenSize === 'xs' && menuSidebarCollapsed"
        @click="onToggleMenuSidebar"
      ></div>
    </div>
    <!-- check login -->
    <div v-else>
      <router-view :key="$route.fullPath" />
    </div>
    <!-- check login -->
  </div>
</template>
<script>
import itemsListLayout from "./views/itemsList.vue";
import controlSidebar from "./components/control-sidebar/controlSidebar.vue";
// import paysLayout from "./views/pays.vue";
// import messagesLayout from "./views/messages.vue";
// import loginLayout from "./views/login.vue";
// import forgetPasswordLayout from "./views/forgetPassword.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    "control-sidebar": controlSidebar,
  },

  data() {
    return {
      menuShow: true,
      showNotification: false,
      user: {},
      appElement: null,
    };
  },
  watch: {
    darkModeSelected(value) {
      
      this.checkUiValue();
    },
    menuSidebarCollapsed(value) {
      
      this.checkUiValue();
    },
    controlSidebarCollapsed() {
      
      this.checkUiValue();
    },
    screenSize(value) {
      
      this.checkUiValue();
    },
  },
  computed: {
    // ...mapState({
    //   darkModeSelected: (state) => state.ui.darkModeSelected,
    //   menuSidebarCollapsed: (state) => state.ui.menuSidebarCollapsed,
    //   controlSidebarCollapsed: (state) => state.ui.controlSidebarCollapsed,
    //   screenSize: (state) => state.ui.screenSize,
    // }),

    ...mapGetters({
      darkModeSelected: "ui/darkModeSelected",
      menuSidebarCollapsed: "ui/menuSidebarCollapsed",
      controlSidebarCollapsed: "ui/controlSidebarCollapsed",
      screenSize: "ui/screenSize",
      sidebarSkin: "ui/sidebarSkin",
      navbarVariant: "ui/navbarVariant",
      // 'anotherGetter',
      // ...
    }),
    auth() {
      return this.$store.state.auth.auth;
    },
  },

  methods: {
    checkUiValue() {
      
      if (!this.appElement) {
        return;
      }
      this.appElement.classList.remove("dark-mode");
      this.appElement.classList.remove("sidebar-closed");
      this.appElement.classList.remove("sidebar-collapse");
      this.appElement.classList.remove("sidebar-open");
      this.appElement.classList.remove("control-sidebar-slide-open");

      if (this.darkModeSelected) {
        this.appElement.classList.add("dark-mode");
      }

      if (!this.controlSidebarCollapsed) {
        this.appElement.classList.add("control-sidebar-slide-open");
      }

      if (this.menuSidebarCollapsed && this.screenSize === "lg") {
        this.appElement.classList.add("sidebar-collapse");
      } else if (this.menuSidebarCollapsed && this.screenSize === "xs") {
        this.appElement.classList.add("sidebar-open");
      } else if (!this.menuSidebarCollapsed && this.screenSize !== "lg") {
        this.appElement.classList.add("sidebar-closed");
        this.appElement.classList.add("sidebar-collapse");
      }
    },

    checkToken() {
      if (localStorage.getItem("user")) {
        this.user = JSON.parse(localStorage.getItem("user"));
        if (this.user.token) {
          this.$store.state.auth.auth = true;
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.user.token}`;
        }
      }
    },

    // toggleMenu() {
    //   this.menuShow = !this.menuShow;
    // },
    onToggleMenuSidebar() {
      this.$store.dispatch("ui/toggleMenuSidebar");
    },
    onToggleControlSidebar() {
      this.$store.dispatch("ui/toggleControlSidebar");
    },
    notificationToggle() {
      this.showNotification = !this.showNotification;
    },
    closeMenuResponsive() {
      if (window.innerWidth < 768) {
        this.menuShow = false;
      }
    },
    logOut() {
      this.$store.dispatch("auth/logOut");
    },
  },
  created() {
    this.checkToken();
  },

  mounted() {
    
    // console.log("baseURL",window.axios.defaults.baseURL)
    // console.log("ENVIRONMENT",process.env)
    this.appElement = document.getElementById("app");
    this.appElement.classList.add("sidebar-mini");
    this.appElement.classList.add("layout-fixed");
  },
  unmounted() {
    
    this.appElement.classList.remove("sidebar-mini");
    this.appElement.classList.remove("layout-fixed");
  },

  components: {
    itemsListLayout,
    // paysLayout,
    // messagesLayout,

    // loginLayout,
    // forgetPasswordLayout,
  },
};
</script>
<style lang="scss">
@import "./assets/bootstrap/bootstrap.min.css";
@import "./assets/bootstrap/custom-style.css";
@import "./assets/bootstrap/adminlte.min.css";
/* template manual style  */
@import "./assets/sass/template.css";
@import "./assets/sass/multiSelect.css";
/* template manual style  */
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
.wrapper{
  height: 100vh;
  overflow-y: hidden !important;
}
.content-wrapper {
  overflow-y: scroll !important;
  padding-bottom: 60px;
}

</style>
