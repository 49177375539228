
export const discount = {
  namespaced: true,
  state: {
    products: [],
  },
  mutations: {
    fetchProductListForDiscountMutate(state, data) {
      state.products = data.items;
    },

  },
  actions: {

    fetchProductListForDiscountAction(context, params) {
      axios
        .get(
          `/api/${params.reqName}?Page=${params.page}&SearchCriteria=${
            params.filter
          }${params.search}&SortingCriteria=${params.sorting}&PageSize=${
            params.pageSize === undefined
              ? (params.pageSize = 10)
              : params.pageSize
          }`
        )
        .then(({
          data
        }) => {
          context.rootState.loading = false;
        
          if (data.succeeded) {
            context.commit("fetchProductListForDiscountMutate", data.data);
          } else {
            // root:true Set Global Mutate in main store file
            context.commit("errorHandel", data, {
              root: true
            });
          }
        })
        .catch((error) => {
          context.commit(
            "errorHandel", {
              message: {
                text: "An error occurred. Contact support",
                icon: "error",
                title: "Error",
                textButton: "Ok",
              },
            }, {
              root: true
            }
          );

          if (error.response) {
            console.log(error.response);
          }
        });
    },

  }
}