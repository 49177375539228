<template>
  <b-modal
    :id="name"
    v-model="show"
    :size="modalSize"
    centered
    :hide-footer="hideFooter"
    @hidden="closeModal"
    footer-class="my-3"
    modal-class="default-modal "
  >
    <!-- Header -->
    <template #modal-header="{ close }">
      <h5 class="mt-2 font-weight-bold">
        <!-- {{ title }} -->
        <slot name="header" />
      </h5>
      <b-icon icon="x" class="h2 cursor-pointer" @click="close()" />
    </template>
    <!-- Content -->
    <slot name="content" />
    <!-- Footer -->
    <template #modal-footer>
      <slot name="footer" />
    </template>
  </b-modal>
</template>
<script>
export default {
  name: "modals",
  props: {
    name: {
      type: String,
      required: false,
      default: "b-modal",
    },
    modalShow: {
      type: Boolean,
      default: false,
    },
    modalSize: {
      type: String,
      default: "md",
    },
    // title: {
    //     type: String,
    //     default: ''
    // },
    hideFooter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    modalShow(value) {
      this.show = value;
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModalEmited");
    },
  },
};
</script>
  