export default {
  data() {
    return {

      showAlertText: "",
      dismissSecs: 5,
      dismissCountDown: 0,
    };
  },
  methods: {
    countDownChange(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
     showAlert() {
        this.dismissCountDown = this.dismissSecs
      }
  },
};
