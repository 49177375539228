const base = "content";

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
import contentManagment from "../../components/content/contentManagment.vue";

export default [
  {
    path: "/content-managment",
    name: "contentManagment",
    component: contentManagment,
    meta: { requiresAuth: true },
  },
  
];
