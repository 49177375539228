<template>
  <div>
    <header-table-component />
    <article class="container-fluid container-box p-3 mx-1">
      <div class="row">
        <div class="col-lg-3 col-sm-4">
          <div class="form-group">
            <label for="FormControlSelect1" class="">Search</label>
            <input type="text" class="form-control relative" placeholder="Search (fast)" v-model.lazy="search"
              @keyup.enter="($store.state.list.paginate.page = 1), fetchData()" />
          </div>
        </div>
        <div class="col-lg-3 col-sm-4">
          <div class="form-group">
            <label class="">How to display (location)</label>
            <select class="form-control" v-model="slotSelected"
              @change="($store.state.list.paginate.page = 1), fetchData()">
              <option v-for="(item, index) in slotItem" :key="index" :value="item.value">
                {{ item.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="col mt-4 col-sm-6">
          <button type="button" class="btn btn-primary mt-2" @click="fetchData()">
            <i class="fa fa-refresh"></i>
          </button>
          <button class="btn btn-success mx-1 mt-2" @click="createDataMethode()">
            <i class="fa fa-plus mx-1"></i>
            <span class=""> new content </span>
          </button>
          <button class="btn btn-info mx-1 mt-2" @click="showModalFilter = !showModalFilter">
            <i class="fa fa-filter mx-1"></i>
            Filters
          </button>
          <div class="btn-group mt-2" :class="{ show: dropDown }">
            <button type="button" class="btn btn-info dropdown-toggle" @click="dropDown = !dropDown">
              Export
            </button>
            <div class="dropdown-menu" :class="{ show: dropDown }" style="
                position: absolute;
                transform: translate3d(0px, 38px, 0px);
                top: 0px;
                left: 0px;
                will-change: transform;
              ">
              <a class="dropdown-item pointer"><i class="fa fa-file-excel-o mx-1"></i>Excel</a>
              <!-- <a class="dropdown-item pointer"
                ><i class="fa fa-file-excel-o mx-1"></i>Excel</a
              > -->
            </div>
          </div>
        </div>
      </div>
      <hr />

      <ListFilters :search="search" :fields="fields" :filterList="filterList" :isActiveCheck="isActiveCheck"
        :slotSelected="slotSelected" :slotItem="slotItem" @fetchDataEmit="fetchDataEmit" />

      <div class="table-custom">
        <table class="table table-bordered">
          <thead class="thead-light">
            <tr>
              <th class="text-center" v-for="(item, index) in fields" :key="index" v-show="item.value !== ''">
                {{ item.text }}
                <span v-show="filterList.sortingField === item.value" class="float-left">
                  <i class="fa" :class="[
              sortDircetion === 1
                ? 'fa-sort-amount-asc'
                : 'fa-sort-amount-desc',
            ]" aria-hidden="true"></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in results" :key="index">
              <th class="text-center" scope="row">{{ index + 1 }}</th>
              <td>{{ item.name
              ? item.name.substring(0, 18) + '...' : '' }}</td>
              <!-- <td class="min-table">
                <span v-html="item.description
              ? item.description.substring(0, 18) + '...' : '' "></span>
              </td> -->
              <td class="text-center">
                <span :class="['AboutPage', 'ContactPage'].includes(item.slot)
              ? 'text-muted'
              : ''
              ">
                  {{ item.slot }}</span>
              </td>
              <td class="text-graytext-center">
                <span v-if="item.type == 0">Free</span>
                <span v-else-if="item.type == 1">Phone</span>
                <span v-else-if="item.type == 2">App</span>
                <span v-else-if="item.type == 3">Equipment</span>
                <span v-else-if="item.type == 4">Services</span>
                <span v-else> </span>
              </td>
              <!-- <td>{{ item.link }}</td> -->
              <td class="text-center">{{ item.priority }}</td>
              <td class="text-center" :class="item.isActive ? 'text-success' : 'text-danger'">
                <span v-if="item.isActive">Active</span>
                <span v-else> Inactive</span>
              </td>

              <td class="text-center p-0" style="width: 75px;">
                <span v-if="item.image">
                  <a :href="window + item.image.url" target="_blank">
                    <img :src="window + item.image.thumbnailUrl" alt="content_IMG" class="img-fluid" />
                  </a>
                </span>
                <span v-else>
                  <div v-if="[3, 4].includes(item.type)" class="bg-warning bg-gradient" style="height: 71px;">
                    <i 
                      :class="[item.type === 3 ? 'fa-quote-right' : (item.type === 4 ? 'fa-gg' : '')]"
                      class="fa fa-quote-right mx-auto h-100 d-flex align-items-center justify-content-center"></i>
                    

                  </div>

                </span>
              </td>
              <td>
                <div class="row">
                  <div class="col-lg-4">
                    <button class="btn btn-custom px-2 d-flex flex-column" @click="deleteDataMethode(item.id)">
                      <i class="fa fa-trash mx-auto"></i>
                      <span class="mx-auto"> remove </span>
                    </button>
                  </div>
                  <div class="col-lg-4" @click="editDataMethode(item)">
                    <button class="btn btn-custom px-2 d-flex flex-column">
                      <i class="fa fa-edit mx-auto"></i>
                      <span class="mx-auto"> Edit </span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <h5 v-show="results.length === 0" class="mt-4 text-center text-warning">
          Not found!
        </h5>
      </div>
      <!-- paginate  v-if="paginate.total > 1"-->

      <div class="mt-4">
        <paginateComponent :paginateShared="paginate" @clickedCustom="onClickChildPaginate" />
      </div>

      <!-- paginate -->
    </article>
    <!-- Create modal -->
    <modals :modalSize="models.type === 0 ? 'xl' : ''" :modalShow="showCreateModal || showEditModal"
      @closeModalEmited="(showCreateModal = false), (showEditModal = false)">
      <template #header> {{ activeClassText }}</template>

      <template #content>
        <form id="content-form" @submit.prevent="showCreateModal ? createData() : editData()">
          <div class="row">
            <div class="form-group col-lg-6">
              <label>name</label>
              <input type="text" class="form-control" placeholder="name" v-model="models.name" required />
            </div>

            <div class="form-group col-lg-6">
              <label>Slot</label>
              <select class="form-control" v-model="models.slot">
                <option v-for="(item, index) in slotItem" :key="index" :value="item.value">
                  {{ item.text }}
                </option>
                <!-- 

                <option value="HomePage">Home page</option>
                <option value="AboutPage">About page</option>
                <option value="ContactPage">Contact page</option>
                <option value="Advertising">Advertising</option> -->
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>Priority</label>
              <select class="form-control" v-model="models.priority">
                <option :value="index" v-for="(item, index) in 10" :key="index">
                  {{ index }}
                </option>
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>Display</label>
              <select class="form-control" v-model="models.isActive">
                <option value="">select...</option>

                <option :value="true">Active</option>
                <option :value="false">Inactive</option>
              </select>
            </div>

            <div class="form-group col-lg-6">
              <label>Url</label>
              <input type="text" class="form-control" placeholder="url" v-model="models.link" />
            </div>

            <div class="form-group col-lg-6">
              <label>Type</label>
              <select class="form-control" v-model.number="models.type">
                <!-- <option :value="0">select...</option> -->
                <option :value="0">Free</option>
                <option :value="1">Tel</option>
                <option :value="2">App</option>
                <option :value="3">Equipment</option>
                <option :value="4">Service</option>
              </select>
            </div>
            <div v-if="![1, 3, 4].includes(models.type)" class="form-group"
              :class="models.type === 0 ? 'col-lg-6' : 'col-lg-12'">
              <label>image</label>

              <file-upload input-id="content-img" :imageUrl="models.image ? models.image.thumbnailUrl : ''"
                :fileId="models.image ? models.image.id : null" :window="window" />
            </div>

            <!-- <div class="col-lg-12" v-show="models.type">
              <globalLink
                :type="models.type"
                :key="models.type"
                :value="models.description"
                :app-slot="appSlot"
                @input="models.description = $event"
                @selectAppSlot="appSlot = $event"
              />
            </div> -->

            <div class="form-group col-lg-12" v-if="[0, 2, 3, 4].includes(models.type)">
              <label>description</label>
              <div v-if="models.type === 0">
                <i @click="showGetHtmlModal = !showGetHtmlModal" class="fa fa-html5 mx-auto text-primary pointer"
                  style="position: absolute; right: 30px; top: 45px; font-size: 24px"></i>
                <vue-editor v-model="models.description" :editorToolbar="customToolbar" class=""></vue-editor>
                <div class="description-html-modal">
                  <modals :modalShow="showGetHtmlModal" @closeModalEmited="showGetHtmlModal = false">
                    <template #header> Add media </template>

                    <template #content>
                      <textarea class="form-control" placeholder="description" v-model="models.description"
                        rows="10"></textarea>
                    </template>

                    <template #footer>
                      <button @click="showGetHtmlModal = false" class="btn btn-primary">
                        confirm
                      </button>
                    </template>
                  </modals>
                </div>
              </div>
              <textarea v-if="[2, 3, 4].includes(models.type)" class="form-control" placeholder="description"
                v-model="models.description" rows="3"></textarea>
            </div>
            <div class="form-group col-lg-12" v-if="models.type === 1">
              <label>Phone</label>
              <input type="tel" class="form-control" placeholder="phone" v-model="models.description" />
            </div>
          </div>
        </form>
      </template>

      <template #footer>
        <button type="submit" form="content-form" class="btn btn-info">
          Save {{ activeClassText }}
        </button>
      </template>
    </modals>
    <!-- Create modal -->

    <!-- filters Modal -->
    <modals :modalShow="showModalFilter" @closeModalEmited="showModalFilter = false">

      <template #header> Filters </template>

      <template #content>
        <form>
          <div class="row">
            <div class="form-group col-lg-6">
              <label>Sort </label>
              <select class="form-control" v-model="sortingField">
                <option :value="item.value" v-for="(  item, index  ) in   fields  " :key="index"
                  v-show="item.value !== null">
                  {{ item.text }}
                </option>
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>Order by</label>
              <select class="form-control" v-model.number="sortDircetion">
                <option value="1">ascending</option>
                <option value="2">descending</option>
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>IsActive</label>
              <select class="form-control" v-model="isActiveCheck">
                <option value="">all</option>
                <option :value="true">Active</option>
                <option :value="false">Inactive</option>
              </select>
            </div>
          </div>
        </form>
      </template>

      <template #footer>
        <button type="button" class="btn btn-info" @click="
              ($store.state.list.paginate.page = 1),
              fetchData(),
              (showModalFilter = false)
              ">
          Filter
        </button>
      </template>
    </modals>
    <!-- filters Modal -->

    <div>
      <loading :loading="loading" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CRUDMix from "/src/mixins/lists/CRUD.js";
import paginateMix from "/src/mixins/lists/paginate.js";
import staticDataTableMix from "/src/mixins/lists/staticDataTable.js";
import defaultErrorMessageMix from "/src/mixins/lists/defaultErrorMessage.js";
import storeStateMix from "/src/mixins/lists/storeState.js";
import checkLinkMix from "/src/mixins/lists/checkLink.js";
import { VueEditor } from "vue2-editor";

export default {
  name: "content-managment",
  components: {
    VueEditor,
  },
  mixins: [
    CRUDMix,
    paginateMix,
    staticDataTableMix,
    defaultErrorMessageMix,
    storeStateMix,
    checkLinkMix,
  ],
  data() {
    return {
      reqName: "contents", //URL name request To server EX:www.test.com/api/v2/reqName
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ align: "" }, { align: "center" }, { align: "left" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"],
        ["clean"], // remove formatting button
      ],
      slotItem: [
        // items in select box for access quickly
        { value: "", text: "all" },
        { value: "HomePage", text: "Home page" },
        { value: "AboutPage", text: "About page" },
        { value: "ContactPage", text: "Contact page" },
        { value: "Advertising", text: "Advertising" },
      ],
      fields: [
        { value: null, text: "row" },
        { value: "", text: "default" },
        { value: "Name", text: "name" },
        { value: "", text: "description" },
        { value: "Slot", text: "slot" },
        { value: "Type", text: "type" },
        { value: "", text: "link" },
        { value: "Priority", text: "priority" },
        { value: "IsActive", text: "isActive" },
        { value: null, text: "image" },
        { value: null, text: "" },
      ],
      slotSelected: "",
      models: {
        id: "",
        name: "",
        description: "",
        slot: "",
        type: 0,
        priority: 1,
        isActive: true,
        isAdminChoice: false,
        link: "",
        image: {},
        imageId: null,
      },
      isActiveCheck: "",
      filterList: {
        sortingField: "",
        isActive: "",
      },
      showGetHtmlModal: false,
    };
  },
  watch: {
    imageDetail() {
      this.models.image = this.imageDetail;
      this.models.imageId = this.imageDetail.id;
    },
  },
  computed: {
    ...mapState({
      imageDetail: (state) => state.upload.imageDetail,
    }),
  },

  methods: {
    async fetchData() {
      await this.$store.dispatch("list/getListsAction", {
        reqName: this.reqName,
        page: this.paginate.page,
        pageSize: this.paginate.pageSize,
        search: `Name=${this.search}¤5ɖDescription=${this.search}¤5ɖ`,
        sorting: `${this.sortingField}¤${this.sortDircetion}`,
        filter: `Slot=${this.slotSelected}¤5µIsActive=${this.isActiveCheck}¤1µ`,
      });

      this.checkListClick();
    },
    checkListClick() {
      this.filterList.sortingField = this.sortingField;
      this.filterList.isActive = this.isActiveCheck;
    },
    createDataMethode() {
      this.showCreateModal = !this.showCreateModal;

      this.models.id = "";
      this.models.name = "";
      this.models.description = "";
      this.models.slot = "";
      this.models.priority = 1;
      this.models.isActive = true;
      this.models.link = "";
      this.models.type = 0;

      this.models.image = {};
      this.models.imageId = null;
      this.linkType = 0;
      this.appSlot = "";
    },
    editDataMethode(item) {
      
      
      this.showEditModal = !this.showEditModal;
      this.models = { ...item };
      if (item.image) this.models.imageId = item.image.id;
      // this.checkLink(item, "link");
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style lang="scss">
.ql-editor {

  //text-align: right !important;
  li:not(.ql-direction-rtl)::before {
    margin-right: 0 !important;
    margin-left: 0.3em;
    text-align: right;
  }
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: unset !important;
}

.description-html-modal {
  #customModal {
    .modal-dialog {
      width: 650px;
    }
  }
}

@media (max-width: 576px) {
  .description-html-modal {
    #customModal .modal-dialog {
      width: unset;
    }
  }
}
</style>
