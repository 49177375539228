export default{
    watch:{
        messages() {
            this.$swal.fire({
              icon: this.messages.message.icon || "error",
              title: this.messages.message.title || "Error",
              html:
                this.messages.message.text ||
                "An error occurred. Contact support",
              allowOutsideClick: true,
              confirmButtonText: this.messages.message.textButton || "Confirm",
            });
          },
      
    }
}