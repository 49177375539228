<template>
  <div>
    <div class="container-fluid">
      <div class="row no-gutter form-page">
        <div class="col">
          <div class="login d-flex align-items-center py-5">
            <div class="container">
              <div class="row">
                <div class="col-md-9 col-lg-6 mx-auto">
                  <h3 class="login-heading mb-4 text-white text-center">
                    Register!
                  </h3>
                  <form @submit.prevent="submit()">
                    <div class="row">
                      <div class="col-6 form-label-group mt-4 ">
                        <label class="input-label">نام </label>

                        <input
                          type="text"
                          v-model="$v.name.$model"
                          class="form-control p-2"
                          :class="{ 'form-control-error': $v.name.$anyError }"
                          @blur="delayTouch($v.name)"
                          @input="delayTouch($v.name)"
                          required
                        />
                        <div v-if="$v.name.$anyDirty">
                          <div class="error mt-1 mr-2" v-if="!$v.name.required">
                            Name is required!
                          </div>
                          <div
                            class="error mt-1 mr-2"
                            v-if="!$v.name.farsiValid"
                          >
                            فقط فارسی نگاشته شود
                          </div>
                        </div>
                      </div>

                      <div class="col-6 form-label-group mt-4  ">
                        <label class="input-label">Full name </label>

                        <input
                          type="text"
                          v-model="$v.family.$model"
                          class="form-control p-2"
                          :class="{
                            'form-control-error': $v.family.$anyError,
                          }"
                          @blur="delayTouch($v.family)"
                          @input="delayTouch($v.family)"
                          required
                        />
                        <div v-if="$v.family.$anyDirty">
                          <div
                            class="error mt-1 mr-2"
                            v-if="!$v.family.required"
                          >
                            فیلد Full name الزامی است !
                          </div>
                          <div
                            class="error mt-1 mr-2"
                            v-if="!$v.family.farsiValid"
                          >
                            فقط فارسی نگاشته شود
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-label-group mt-4 ">
                      <label class="input-label">موبایل </label>

                      <input
                        type="number"
                        v-model="$v.phone.$model"
                        class="form-control p-2"
                        :class="{ 'form-control-error': $v.phone.$anyError }"
                        @blur="delayTouch($v.phone)"
                        @input="delayTouch($v.phone)"
                        required
                      />
                      <div v-if="$v.phone.$anyDirty">
                        <div class="error mt-1 mr-2" v-if="!$v.phone.required">
                          phone is required!
                        </div>
                        <div
                          class="error mt-1 mr-2"
                          v-if="!$v.phone.phoneValid"
                        >
                          formate phone is not valid!
                        </div>
                      </div>
                    </div>
                    <div class="form-label-group mt-4 ">
                      <label class="input-label">Gender </label>
                      <select
                        v-model="$v.gender.$model"
                        class="form-control p-2"
                        :class="{ 'form-control-error': $v.gender.$anyError }"
                        @blur="delayTouch($v.gender)"
                        @input="delayTouch($v.gender)"
                        required
                      >
                        <option value="1">male</option>
                        <option value="0">female</option>
                      </select>

                      <div v-if="$v.gender.$anyDirty">
                        <div class="error mt-1 mr-2" v-if="!$v.gender.required">
                          The gender field is required !
                        </div>
                      </div>
                    </div>
                    <div class="form-label-group mt-4 ">
                      <label class="input-label">Date of birth </label>
                      <date-picker
                        v-model="$v.birth.$model"
                        view="year"
                        format="jYYYY/jMM/jDD"
                        color="#ff9800"
                        class="date-time-picker"
                        :class="{ 'form-control-error': $v.phone.$anyError }"
                        :max="currectYear.toString()"
                        @blur="delayTouch($v.birth)"
                        @input="delayTouch($v.birth)"
                        required
                      />

                      <div v-if="$v.birth.$anyDirty">
                        <div class="error mt-1 mr-2" v-if="!$v.birth.required">
                          فیلد Date of birth الزامی است!
                        </div>
                      </div>
                    </div>
                    <div class="form-label-group mt-4 ">
                      <label class="input-label">Email </label>

                      <input
                        type="email"
                        v-model="$v.email.$model"
                        class="form-control p-2"
                        :class="{ 'form-control-error': $v.email.$anyError }"
                        @blur="delayTouch($v.email)"
                        @input="delayTouch($v.email)"
                        required
                      />
                      <div v-if="$v.email.$anyDirty">
                        <div class="error mt-1 mr-2" v-if="!$v.email.required">
                         Email is required !
                        </div>
                        <div class="error mt-1 mr-2" v-if="!$v.email.email">
                          The entered email is not correct
                        </div>
                      </div>
                    </div>

                    <div class="form-label-group mt-4 ">
                      <label class="input-label">New password</label>
                      <input
                        :type="showPassword ? 'text' : 'password'"
                        v-model="$v.password.$model"
                        class="form-control p-2 relative"
                        :class="{
                          'form-control-error': $v.password.$anyError,
                        }"
                        @blur="delayTouch($v.password)"
                        @input="delayTouch($v.password)"
                        required
                      />
                      <i
                        class="fa  text-white pointer password-type"
                        :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                        @click="showPassword = !showPassword"
                        aria-hidden="true"
                      ></i>
                      <div v-if="$v.password.$anyDirty">
                        <div
                          class="error mt-1 mr-2"
                          v-if="!$v.password.required"
                        >
                          فیلد رمز عبور الزامی است !
                        </div>
                        <div
                          class="error mt-1 mr-2"
                          v-if="!$v.password.minLength"
                        >
                          رمز عبور حداقل باید دارای 6 کاراکتر باشد.
                        </div>
                      </div>
                      <div class="form-label-group mt-4 ">
                        <label class="input-label ">تکرار رمز عبور</label>
                        <input
                          :type="showPassword ? 'text' : 'password'"
                          v-model="$v.repeatPassword.$model"
                          class="form-control p-2 relative"
                          :class="{
                            'form-control-error': $v.repeatPassword.$anyError,
                          }"
                          @blur="delayTouch($v.repeatPassword)"
                          @input="delayTouch($v.repeatPassword)"
                          required
                        />
                        <i
                          class="fa  text-white pointer password-type"
                          :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                          @click="showPassword = !showPassword"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div v-if="$v.repeatPassword.$anyDirty">
                        <div
                          class="error mt-1 mr-2"
                          v-if="!$v.repeatPassword.required"
                        >
                          The password repeat field is requiredThe password repeat field is required !
                        </div>
                        <div
                          class="error mt-1 mr-2"
                          v-if="!$v.repeatPassword.sameAsPassword"
                        >
                          The chosen password is not the same.
                        </div>
                      </div>
                    </div>
                    <div class="form-label-group mt-4 ">
                      <div class="row justify-content-center mt-1">
                        <div class="col-md-10 col-9 ">
                          <label class="input-label">کد تایید </label>
                          <input
                            type="number"
                            v-model="$v.code.$model"
                            class="form-control p-2 relative"
                            :class="{
                              'form-control-error': $v.code.$anyError,
                            }"
                            @blur="delayTouch($v.code)"
                            @input="delayTouch($v.code)"
                            required
                          />
                          <span
                            class="absolute code-icons"
                            v-show="!$v.code.$invalid"
                          >
                            <i
                              class="fa fa-check-square-o"
                              style="color:#1af300"
                            ></i>
                            <i class="fa fa-times" style="color:red"></i
                          ></span>
                        </div>
                        <div class="col-md-2 col-3" style="margin-top:30px">
                          <span
                            v-if="resetCodeTimerSec || resetCodeTimerMin > 0"
                            class="btn btn-app-reset text-uppercase font-weight-bold"
                          >
                            {{
                              resetCodeTimerSec > 9
                                ? resetCodeTimerSec
                                : "0" + resetCodeTimerSec
                            }}
                            :{{ resetCodeTimerMin }}
                          </span>
                          <button
                            v-else
                            class="btn btn-app-reset text-uppercase font-weight-bold"
                            type="button"
                            @click="resetCodeInterval(120)"
                          >
                            دریافت کد
                          </button>
                        </div>
                      </div>
                      <div v-if="$v.code.$anyDirty">
                        <div class="error mt-1 mr-2" v-if="!$v.code.required">
                          فیلد کد تایید الزامی است !
                        </div>
                        <div class="error mt-1 mr-2" v-if="$v.code.$invalid">
                          کد تایید 4 رقمی میباشد.
                        </div>
                      </div>
                    </div>
                    <div class="text-center">
                      <button
                        class="btn btn-app text-uppercase font-weight-bold my-5"
                        type="submit"
                        :disabled="$v.$invalid ? true : false"
                      >
                        ثبت نام
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <loading :loading="loading" />
    </div>
  </div>
</template>
<script>
import {
  required,
  minLength,
  email,
  decimal,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
import { mapState } from "vuex";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
const isPhone = (value) => /^(\+09|0098|98|0)?09\d{9}$/.test(value);
const farsi = (value) => /^[\u0600-\u06FF\s]+$/.test(value);
export default {
  name: "forget-password",
  data() {
    return {
      name: "arman",
      family: "abi",
      phone: "+14164562040",
      gender: "1",
      birth: "0",
      email: "test@test.com",
      password: "test123",
      repeatPassword: "test123",
      code: "1234",

      showPassword: false,
    };
  },
  watch: {
    errors() {
      /*  this.errors.forEach((element) => {
        console.log(element.description);
      }); */
      let html = `<ol>${this.singleError.map(
        (error) => `<li>${error}</li>`
      )}</ol>`;
      this.$swal.fire({
        icon: "error",
        title: this.errors.title,
        html: true,
        html: html.replaceAll(",", " "),
        allowOutsideClick: true,
        confirmButtonText: "Ok!",
      });
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      auth: (state) => state.auth.auth,
      resetCodeTimerMin: (state) => state.validation.resetCodeTimerMin,
      resetCodeTimerSec: (state) => state.validation.resetCodeTimerSec,
      errors: (state) => state.errors,
    }),
    currectYear() {
      let year = new Date()
        .toLocaleDateString("fa-IR")
        .replace(/([۰-۹])/g, (token) =>
          String.fromCharCode(token.charCodeAt(0) - 1728)
        )
        .substring(0, 4);
      return year - 7;
    },
    singleError() {
      let errorsArray = [];
      for (const prop in this.errors.errors) {
        if (this.errors.errors.hasOwnProperty(prop)) {
          errorsArray.push(this.errors.errors[prop]);
        }
      }
      return errorsArray;
    },
  },

  methods: {
    delayTouch($v) {
      this.$store.commit("validation/delayTouchMutate", $v);
    },

    resetCodeInterval(i) {
      if (!this.$v.phone.$invalid && !this.$v.email.$invalid) {
        this.$store.commit("validation/resetCodeIntervalMutate", i);
        this.$store.dispatch("auth/getverifysmscode", {
          email: this.email,
          phoneNumber: this.phone,
        });
      } else
        this.$swal.fire({
          icon: "error",
          title: "Please enter the correct phone number and email address.",
          html: false,
          allowOutsideClick: true,
          confirmButtonText: "Ok!",
        });
    },

    submit() {
      if (!this.$v.invalid) {
        console.log("true");

        this.$store.dispatch("auth/register", {
          firstName: this.name,
          lastName: this.family,
          username: "test",
          email: this.email,
          gender: this.gender,
          birth: this.birth,
          phoneNumber: this.phone,
          password: this.password,
          smsConfirmed: true,
        });
      } else {
        console.log("kamel konid");
      }
    },
  },
  mounted() {
    if (this.auth) {
      this.$router.push({ name: "dashboard" });
    }
  },
  validations: {
    name: {
      required,
      farsiValid: farsi, //custom Regex from validation Global
    },
    family: {
      required,
      farsiValid: farsi, //custom Regex from validation Global
    },
    phone: {
      required,
      decimal,
      phoneValid: isPhone, //custom Regex from validation Global
    },
    gender: {
      required,
    },
    birth: {
      required,
    },
    email: {
      email,
      required,
    },

    password: {
      required,
      minLength: minLength(6),
    },
    repeatPassword: {
      required,
      sameAsPassword: sameAs("password"),
    },
    code: {
      required,
      decimal,
      minLength: minLength(4),
      maxLength: maxLength(4),
    },
  },
  components: { "date-picker": VuePersianDatetimePicker },
};
</script>
<style lang="scss">
.form-page {
  height: auto;
}

.code-icons {
  position: absolute;
  top: 32px;
  left: 20px;
  font-size: 30px;
}
</style>
