<template>
  <div>
    <!-- Styled -->
    <b-form-file :id="inputId" v-model="file1" :state="Boolean(file1)" placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."></b-form-file>
    <div class="mt-3">Selected file: {{ file1 ? file1.name : "" }}</div>
    <button :disabled="uploadLoading" type="button" class="btn btn-success" v-if="!imageUrl" @click.prevent="uploadFile()">
      <div v-if="!uploadLoading">
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
        Upload
      </div>
      <div v-if="uploadLoading" class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </button>

    <button type="button" class="btn btn-danger" v-if="imageUrl" @click.prevent="removeFile()">
    
      <div v-if="!uploadLoading">
        <i class="fa fa-trash" aria-hidden="true"></i>
        Remove
      </div>
      <div v-if="uploadLoading" class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </button>
    <!-- <button type="button" class="btn btn-danger" v-if="uploadLoading" @click.prevent="upload.active = false">
            <i class="fa fa-stop" aria-hidden="true"></i>
            Stop uploading
        </button> -->

    <span class="mx-4" v-if="imageUrl">
      <a :href="imageUrl" target="_blank">
        <img :src="imageUrl" alt="prev-image" width="50px" height="50px" /></a>
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex";
import formatBytesMix from "/src/mixins/formats/formatBytes.js";
export default {
  mixins: [formatBytesMix],
  props: {
    inputId: {
      type: String,
      default: "up-image",
    },
    maxSize: {
      type: Number,
      default: 1024 * 1024 * 100,
      required: false,
    },
    // acceptsFiles: {
    //     type: String,
    //     default: 'video/mp4,video/x-m4v,video/*',
    //     required: false,
    // }
    imageUrl: {
      type: String,
      default: "",
    },
    fileId: {
      type: String,
      default: null,
    },
    // [window]: {
    //     type: Object,
    // }
  },
  data() {
    return {
      file1: null,
    };
  },
  computed: {
    ...mapState({
      /* just Getter */

      imageDetail: (state) => state.upload.imageDetail,
      imageSecondDetail: (state) => state.upload.imageSecondDetail,
      uploadLoading: (state) => state.upload.uploadLoading,
      uploadSuccess: (state) => state.upload.uploadSuccess,
    }),
  },
  methods: {
    async uploadFile() {
      const fileSize = this.file1.size;
      console.log(fileSize);
      if (fileSize > this.maxSize) {
        this.$swal.fire({
          icon: "error",
          title: "error",
          text: `The size of the selected file is more than
          ${this.formatBytes(this.maxSize)}`,
          allowOutsideClick: true,
          confirmButtonText: "ok",
        });
      } else {
        let formData = new FormData();
        formData.append("ImageFile", this.file1);

        await this.$store.dispatch("upload/uploadImage", formData);
        // 
        // this.imageUrl = this.imageDetail.thumbnailUrl;
      }
    },
    async removeFile() {
      if (this.fileId) {
        await this.$store.dispatch("upload/removeImage", this.fileId);
        // this.imageUrl = "";
      }
    },
  },
};
</script>