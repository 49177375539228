<template>
  <div>
    <header-table-component />
    <article class="container-fluid container-box p-3 mx-1">
      <div class="row ">
        <div class="col-lg-3">
          <Search
            ref="search"
            @submitSearch="
              ($store.state.list.paginate.page = 1),
                (filters[0].value = $refs.search.value),
                fetchData()
            "
          />
        </div>
        <div class="col mt-4">
          <button
            type="button"
            class="btn btn-primary"
            @click="fetchData()"
          >
            <i class="fa fa-refresh"></i>
          </button>

          <button
            class="btn btn-info mx-1"
            @click="showModalFilter = !showModalFilter"
          >
            <i class="fa fa-filter mx-1"></i>
            Filters
          </button>
          <div class="btn-group " :class="{ show: dropDown }">
            <button
              type="button"
              class="btn btn-info dropdown-toggle"
              @click="dropDown = !dropDown"
            >
              Export
            </button>
            <div
              class="dropdown-menu "
              :class="{ show: dropDown }"
              style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;"
            >
              <a class="dropdown-item pointer"
                ><i class="fa fa-file-excel-o mx-1 "></i>Excel</a
              >
            
            </div>
          </div>
        </div>
      </div>
      <hr />
      <FilterChips :filters="filters" @removeChipEmit="removeChipEmit" />
      <div class="table-custom">
        <table class="table  table-bordered">
          <thead class="thead-light">
            <tr>
              <th
                v-for="(item, index) in fields"
                :key="index"
                v-show="item.value !== ''"
              >
                {{ item.text }}
                <span
                  v-show="filters[1].value === item.value"
                  class="float-left"
                >
                  <i
                    class="fa"
                    :class="[
                      sortDircetion === 1
                        ? 'fa-sort-amount-asc'
                        : 'fa-sort-amount-desc',
                    ]"
                    aria-hidden="true"
                  ></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in results" :key="index">
              <th class="text-center" scope="row">{{ index + 1 }}</th>
              <td>
                <span
                  class="text-graymx-1 pointer h4"
                  @click="getDetailById(item.id)"
                >
                  <i
                    v-tooltip.top-center="'Details'"
                    class="fa fa-info-circle"
                    aria-hidden="true"
                  ></i>
                </span>
              </td>
              <td>
                <span v-if="item.user">
                  {{ item.user.firstName }} {{ item.user.lastName }}
                </span>
              </td>
              <td class="min-table">{{ item.description }}</td>
              <td>{{ setPrice(item.amount) }}</td>
              <td>
                <span v-show="item.gateway === 1">زرین پال</span>
                <span v-show="item.gateway === 2">سامان</span>
              </td>
              <td>
                <span v-show="item.action === 1">خرید دوره</span>
                <span v-show="item.action === 2">شارژ حساب</span>
              </td>
              <td>
                {{
                  formatDate(item.created)
                }}
              </td>
              <td>
                <span v-show="item.status === 0">New</span>
                <span v-show="item.status === 1" class="text-danger"
                  >Canceled</span
                >
                <span v-show="item.status === 2" class="text-warning"
                  >Pending payment</span
                >
                <span v-show="item.status === 3" class="text-success"
                  >successfully</span
                >
              </td>
            </tr>
          </tbody>
        </table>
        <h5 v-show="results.length === 0" class="mt-4 text-center text-warning">
          Not found!
        </h5>
      </div>
      <!-- paginate  v-if="paginate.total > 1"-->

      <div class="mt-4">
        <paginateComponent
          :paginateShared="paginate"
          @clickedCustom="onClickChildPaginate"
        />
      </div>

      <!-- paginate -->
    </article>
    <!-- deatil modal -->
    <div class="detail-modal">
      <modals
      :modalShow="showDetailModal"
        @closeModalEmited="showDetailModal = false"
      >
        <template #header> Purchase details</template>
        <template #content>
          <div class="row py-3 mx-0 mx-md-4 border-bottom">
            <div class="col-lg-3 font-weight-bold">
              User name :
            </div>
            <div class="col ">
              <span v-if="result.user">{{ result.user.email }}</span>
            </div>
          </div>
          <div class="row py-3 mx-0 mx-md-4 border-bottom">
            <div class="col-lg-3 font-weight-bold">
              Bank portal link :
            </div>
            <div class="col ">
              {{ result.gatewayUrl }}
            </div>
          </div>
          <div class="row py-3 mx-0 mx-md-4 border-bottom">
            <div class="col-lg-3 font-weight-bold">
              Return link from the bank :
            </div>
            <div class="col ">
              {{ result.returnUrl }}
            </div>
          </div>
          <div class="row py-3 mx-0 mx-md-4">
            <div class="col-lg-3 font-weight-bold">
              Bank token :
            </div>
            <div class="col ">
              {{ result.token }}
            </div>
          </div>

          <!-- 
          <td>{{ result.gatewayUrl }}</td>
          <td>{{ result.returnUrl }}</td>
          <td>{{ result.token }}</td> -->
        </template>
        <template #footer> </template>
      </modals>
    </div>
    <!-- deatil modal -->
    <!-- filters Modal -->
    <form @submit.prevent="setFilters()">
      <modals
      :modalShow="showModalFilter"
        @closeModalEmited="showModalFilter = false"
      >
        <template #header> Filters </template>
        <template #content>
          <div class="row">
            <div class="form-group col-lg-6">
              <label>Sort </label>
              <select class="form-control" ref="selectSortName">
                <option
                  :value="item.value"
                  v-for="(item, index) in fields"
                  :key="index"
                  v-show="item.value !== null"
                  >{{ item.text }}</option
                >
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>Order by</label>
              <select class="form-control" v-model.number="sortDircetion">
                <option value="1">ascending</option>
                <option value="2">descending</option>
              </select>
            </div>
          </div>
        </template>
        <template #footer>
          <button type="submit" class="btn btn-info">
            Filter
          </button>
        </template>
      </modals>
    </form>
    <!-- filters Modal -->

    <div>
      <loading :loading="loading" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import CRUDMix from "/src/mixins/lists/CRUD.js";
import paginateMix from "/src/mixins/lists/paginate.js";
import staticDataTableMix from "/src/mixins/lists/staticDataTable.js";
import defaultErrorMessageMix from "/src/mixins/lists/defaultErrorMessage.js";
import storeStateMix from "/src/mixins/lists/storeState.js";
import setPriceMix from "/src/mixins/lists/setPrice.js";
import filtersMethodeMix from "/src/mixins/lists/filtersMethode.js";
import formatDateMix from "/src/mixins/lists/formatTime.js"


export default {
  name: "bankPayments",
  mixins: [
    CRUDMix,
    paginateMix,
    staticDataTableMix,
    defaultErrorMessageMix,
    storeStateMix,
    setPriceMix,
    filtersMethodeMix,
    formatDateMix
  ],
  data() {
    return {
      reqName: "bankPayments", //URL name request To server EX:www.test.com/api/v2/reqName
      showDetailModal: false,
      fields: [
        { value: "", text: "default" },
        { value: null, text: "row" },
        { value: null, text: "" },
        { value: "User.LastName", text: "Full name" },
        { value: "Description", text: "Description" },
        { value: null, text: "Value" },
        { value: null, text: "Portal" },
        { value: null, text: "Action" },
        { value: "Created", text: "Created" },
        { value: null, text: "Status" },
      ],
      filters: [
        { text: "Search", value: "", valueFarsi: "", keyName: "search" },
        {
          text: "Sort by",
          value: "",
          valueFarsi: "",
          keyName: "sortingField",
        },
      ],
      detailFields: [
        { text: "User name" },
        { text: "Bank portal link" },
        { text: "Return link from the bank " },
        { text: "Bank token " },
      ],
    };
  },

  computed: {
    ...mapState({
      /* just Getter */
      result: (state) => state.list.result,
    }),
  },

  methods: {
    async fetchData() {
      await this.$store.dispatch("list/getListsAction", {
        reqName: this.reqName,
        page: this.paginate.page,
        pageSize: this.paginate.pageSize,
        search: `User.FirstName=${this.filters[0].value}¤5ɖUser.LastName=${this.filters[0].value}¤5ɖUser.Email${this.filters[0].value}¤5ɖDescription=${this.filters[0].value}¤5ɖ`,
        sorting: `${this.filters[1].value}¤${this.sortDircetion}`,
        filter: ``,
      });
    },
    setFilters() {
      this.filters[1].value = this.$refs.selectSortName.value;
      this.filterHandeling();
    },

    async getDetailById(id) {
      this.showDetailModal = true;
      await this.$store
        .dispatch("list/getListByIdAction", {
          reqName: this.reqName,
          id: id,
        })
        .then(() => {});
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style lang="scss"></style>
