<template>
  <div>
    <header-table-component />
    <article class="container-fluid container-box p-3 mx-1">
      <div class="row">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="FormControlSelect1" class="">Search</label>
            <input
              type="text"
              class="form-control relative"
              placeholder="Search (fast)"
              v-model.lazy="search"
              @keyup.enter="($store.state.list.paginate.page = 1), fetchData()"
            />
          </div>
        </div>

        <div class="col mt-4">
          <button
            type="button"
            class="btn btn-primary"
            @click="fetchData()"
          >
            <i class="fa fa-refresh"></i>
          </button>
          <button class="btn btn-success mx-1" @click="createDataMethode()">
            <i class="fa fa-plus mx-1"></i>
            <span class=""> New story </span>
          </button>
          <button
            class="btn btn-info mx-1"
            @click="showModalFilter = !showModalFilter"
          >
            <i class="fa fa-filter mx-1"></i>
            Filters
          </button>
          <div class="btn-group" :class="{ show: dropDown }">
            <button
              type="button"
              class="btn btn-info dropdown-toggle"
              @click="dropDown = !dropDown"
            >
              Export
            </button>
            <div
              class="dropdown-menu"
              :class="{ show: dropDown }"
              style="
                position: absolute;
                transform: translate3d(0px, 38px, 0px);
                top: 0px;
                left: 0px;
                will-change: transform;
              "
            >
              <a class="dropdown-item pointer"
                ><i class="fa fa-file-excel-o mx-1"></i>Excel</a
              >
             
            </div>
          </div>
        </div>
      </div>
      <hr />

      <ListFilters
        :search="search"
        :fields="fields"
        :filterList="filterList"
        @fetchDataEmit="fetchDataEmit"
      />

      <div class="table-custom">
        <table class="table table-bordered">
          <thead class="thead-light">
            <tr>
              <th
                v-for="(item, index) in fields"
                :key="index"
                v-show="item.value !== ''"
              >
                {{ item.text }}
                <span
                  v-show="filterList.sortingField === item.value"
                  class="float-left"
                >
                  <i
                    class="fa"
                    :class="[
                      sortDircetion === 1
                        ? 'fa-sort-amount-asc'
                        : 'fa-sort-amount-desc',
                    ]"
                    aria-hidden="true"
                  ></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in results" :key="index">
              <th class="text-center" scope="row">{{ index + 1 }}</th>
              <td scope="row" class="min-table-200">
                <span
                  v-tooltip.top-center="'remove'"
                  class="text-danger mx-1 pointer h4"
                >
                  <i
                    class="fa fa-trash mx-auto"
                    @click="deleteDataMethode(item.id)"
                  ></i>
                </span>

                <span
                  v-tooltip.top-center="'ویرایش'"
                  class="text-gray h4"
                >
                  <i
                    class="fa fa-edit mx-auto"
                    @click="editDataMethode(item)"
                  ></i>
                </span>
                <span
                  v-tooltip.top-center="'اسلاید ها'"
                  class="text-gray h4 mx-2"
                  @click="
                    (showSlideModal = !showSlideModal), (storyId = item.id)
                  "
                >
                  <i class="fa fa-sliders mx-auto"></i>
                </span>
              </td>

              <td>{{ item.name }}</td>

              <td>
                {{ formatDate(item.expireDate) }}
              </td>
              <td>{{ item.url }}</td>

              <td class="p-0">
                <span v-if="item.image">
                  <a :href="window + item.image.url" target="_blank">
                    <img
                      :src="window + item.image.thumbnailUrl"
                      alt="content_IMG"
                      class="img-fluid"
                      width="75px"
                      height="35px"
                    />
                  </a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <h5 v-show="results.length === 0" class="mt-4 text-center text-warning">
          Not found!
        </h5>
      </div>
      <!-- paginate  v-if="paginate.total > 1"-->

      <div class="mt-4">
        <paginateComponent
          :paginateShared="paginate"
          @clickedCustom="onClickChildPaginate"
        />
      </div>

      <!-- paginate -->
    </article>
    <!-- Create modal -->
    <form
      @submit.prevent="
        submit('url'), showCreateModal ? createData() : editData()
      "
    >
      <modals
        :modalShow="showCreateModal || showEditModal"
        @closeModalEmited="(showCreateModal = false), (showEditModal = false)"
      >
        <template #header> Add {{ activeClassText }}</template>
        <template #content>
          <div class="row">
            <div class="form-group col-lg-6">
              <label>name</label>
              <input
                type="text"
                class="form-control"
                placeholder="name"
                v-model="models.name"
                required
              />
            </div>
            <div class="form-group col-lg-6">
              <label>url</label>
              <select class="form-control" v-model.number="linkType">
                <option :value="0">select...</option>

                <option :value="1">Free</option>
                <option :value="2">Tel</option>
                <option :value="3">App</option>
              </select>
            </div>
            <div class="col-lg-12" v-show="linkType">
              <globalLink
                :type="linkType"
                :key="linkType"
                :value="models.url"
                :app-slot="appSlot"
                @input="models.url = $event"
                @selectAppSlot="appSlot = $event"
              />
            </div>

            <div class="col-lg-6 mb-4">
              <label class="float-right"> Expire date </label>
              <date-picker
                v-model="models.expireDate"
                color="#41b887"
                class="date-time-picker date-time-picker-products"
                format="YYYY-MM-DD"
                display-format="dddd jDD jMMMM jYYYY"
                clearable
              />
            </div>

            <div class="form-group col-lg-6">
              <label>image</label>
              <!-- custom form upload -->
              <div v-if="showCreateModal || showEditModal">
                <file-upload
                  :key="showCreateModal || showEditModal"
                  :imageUrl="models.image ? models.image.thumbnailUrl : ''"
                  :fileId="models.image ? models.image.id : null"
                  :window="window"
                />
              </div>
              <!-- custom form upload -->
            </div>
          </div>
        </template>
        <template #footer>
          <button type="submit" class="btn btn-info">
            Save {{ activeClassText }}
          </button>
        </template>
      </modals>
    </form>
    <!-- Create modal -->
    <!-- filters Modal -->
    <modals
      :modalShow="showModalFilter"
      @closeModalEmited="showModalFilter = false"
    >
      <template #header> Filters </template>
      <template #content>
        <form>
          <div class="row">
            <div class="form-group col-lg-6">
              <label>Sort </label>
              <select class="form-control" v-model="sortingField">
                <option
                  :value="item.value"
                  v-for="(item, index) in fields"
                  :key="index"
                  v-show="item.value !== null"
                >
                  {{ item.text }}
                </option>
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>Order by</label>
              <select class="form-control" v-model.number="sortDircetion">
                <option value="1">ascending</option>
                <option value="2">descending</option>
              </select>
            </div>
          </div>
        </form>
      </template>
      <template #footer>
        <button
          type="button"
          class="btn btn-info"
          @click="
            ($store.state.list.paginate.page = 1),
              fetchData(),
              (showModalFilter = false)
          "
        >
          Filter
        </button>
      </template>
    </modals>
    <!-- filters Modal -->
    <!-- slideModal  -->
    <div class="slide-modal">
      <modals
        :modalShow="showSlideModal"
        @closeModalEmited="showSlideModal = false"
      >
        <template #header> اسلاید ها </template>
        <template #content>
          <slideModal :key="showSlideModal" :storyId="storyId" class="" />
        </template>
        <template #footer> </template>
      </modals>
    </div>

    <!-- slideModal  -->
    <div>
      <loading :loading="loading" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import slideModal from "./childs/sotrySlideModal.vue";

import CRUDMix from "/src/mixins/lists/CRUD.js";
import paginateMix from "/src/mixins/lists/paginate.js";
import staticDataTableMix from "/src/mixins/lists/staticDataTable.js";
import defaultErrorMessageMix from "/src/mixins/lists/defaultErrorMessage.js";
import storeStateMix from "/src/mixins/lists/storeState.js";
import formatDateMix from "/src/mixins/lists/formatTime.js";
import checkLinkMix from "/src/mixins/lists/checkLink.js";

export default {
  name: "story-list",
  mixins: [
    CRUDMix,
    paginateMix,
    staticDataTableMix,
    defaultErrorMessageMix,
    storeStateMix,
    formatDateMix,
    checkLinkMix,
  ],
  data() {
    return {
      reqName: "stories", //URL name request To server EX:www.test.com/api/v2/reqName
      fields: [
        { value: "", text: "default" },
        { value: null, text: "row" },
        { value: null, text: "image" },
        { value: "Name", text: "name" },
        { value: "expireDate", text: "Expire date" },
        { value: null, text: "url" },
        { value: null, text: "" },
      ],
      showSlideModal: false,
      storyId: "",
      models: {
        id: null,
        name: null,
        expireDate: null,
        url: null,
        image: {},
        imageId: null,
      },
      filterList: {
        sortingField: "",
      },
    };
  },
  watch: {
    imageDetail() {
      this.models.imageId = this.imageDetail.id;
    },
  },
  computed: {
    ...mapState({
      /* just Getter */
      imageDetail: (state) => state.upload.imageDetail,
    }),
    currectYear() {
      let year = new Date()
        .toLocaleDateString("fa-IR")
        .replace(/([۰-۹])/g, (token) =>
          String.fromCharCode(token.charCodeAt(0) - 1728)
        )
        .substring(0, 4);
      return year;
    },
  },

  methods: {
    async fetchData() {
      await this.$store.dispatch("list/getListsAction", {
        reqName: this.reqName,
        page: this.paginate.page,
        pageSize: this.paginate.pageSize,
        search: `Name=${this.search}¤5ɖ`,
        sorting: `${this.sortingField}¤${this.sortDircetion}`,
        filter: ``,
      });

      this.checkListClick();
    },
    checkListClick() {
      this.filterList.sortingField = this.sortingField;
    },
    createDataMethode() {
      this.showCreateModal = !this.showCreateModal;

      this.models.id = null;
      this.models.name = null;
      this.models.expireDate = "";
      this.models.url = null;
      this.models.image = {};
      this.models.imageId = null;
      this.linkType = 0;
      this.appSlot = null;
    },

    editDataMethode(item) {
      this.showEditModal = !this.showEditModal;
      this.models = { ...item };
      this.models.imageId = item.image.id;
      this.checkLink(item, "url");
    },
  },
  created() {
    this.fetchData();
  },
  components: { "date-picker": VuePersianDatetimePicker, slideModal },
};
</script>
<style lang="scss">
.slide-modal-child {
  height: fit-content;
  .modal-dialog {
    max-width: 550px !important;
  }
}
</style>
