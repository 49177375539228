export default {
  methods: {
    createData() {
     
      this.$store
        .dispatch("list/createListAction", {
          reqName: this.reqName,
          data: this.models,
        })
        .then(() => {
          this.showCreateModal = !this.$store.state.success;
          this.fetchData();
        });
    },
    editData() {
      this.$store
        .dispatch("list/updateListAction", {
          data: this.models,
          reqName: this.reqName,
        })
        .then(() => {
          this.showEditModal = !this.$store.state.success;
          this.fetchData();
        });
    },
    deleteDataMethode(id) {
      this.$swal
        .fire({
          title: "remove",
          text: "Are you sure to remove this file?!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "remove!",
          cancelButtonText: "cancel",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            this.$store
              .dispatch("list/deleteListAction", {
                reqName: this.reqName,
                id: id,
              })
              .then(() => {
                this.fetchData();
              });
          }
        });
    },


    fetchDataEmit(payload) {
      this[payload] = "";
      this.fetchData();
    },
  }
}