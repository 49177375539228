<template>
  <div>
    <div class="container-fluid">
      <div class="row no-gutter form-page">
        <div class="col">
          <div class="login d-flex align-items-center py-5">
            <div class="container">
              <div class="row">
                <div class="col-md-9 col-lg-6 mx-auto">
                  <h3 class="login-heading mb-4 text-white text-center">
                    بازیابی رمز عبور !
                  </h3>
                  <form @submit.prevent="submitEmail()" v-if="index === 0">
                    <div class="form-label-group mt-4 ">
                      <label class="input-label">Email </label>

                      <input
                        type="email"
                        v-model="$v.email.$model"
                        class="form-control p-2"
                        :class="{ 'form-control-error': $v.email.$anyError }"
                        @blur="delayTouch($v.email)"
                        @input="delayTouch($v.email)"
                        required
                      />
                      <div v-if="$v.email.$anyDirty">
                        <div class="error mt-1 mr-2" v-if="!$v.email.required">
                         Email is required !
                        </div>
                        <div class="error mt-1 mr-2" v-if="!$v.email.email">
                          email format is incorrect!
                        </div>
                      </div>
                    </div>
                    <div class="form-label-group mt-4 ">
                      <label class="input-label">موبایل </label>

                      <input
                        type="number"
                        v-model="$v.phone.$model"
                        class="form-control p-2"
                        :class="{ 'form-control-error': $v.phone.$anyError }"
                        @blur="delayTouch($v.phone)"
                        @input="delayTouch($v.phone)"
                        required
                      />
                      <div v-if="$v.phone.$anyDirty">
                        <div class="error mt-1 mr-2" v-if="!$v.phone.required">
                          فیلد موبایل الزامی است!
                        </div>
                        <div
                          class="error mt-1 mr-2"
                          v-if="!$v.phone.phoneValid"
                        >
                          شماره موبایل باید 11 رقم باشد و با 09 شروع شود!
                        </div>
                      </div>
                    </div>
                    <div class="text-center">
                      <button
                        class="btn btn-app text-uppercase font-weight-bold my-5"
                        type="submit"
                        :disabled="
                          $v.email.$invalid || $v.phone.$invalid ? true : false
                        "
                      >
                        دریافت کد تایید
                      </button>
                    </div>
                  </form>
                  <form @submit.prevent="submitCode()" v-if="index === 1">
                    <div class="form-label-group mt-4 ">
                      <div class="row justify-content-center mt-1">
                        <div class="col-md-10 col-9">
                          <label class="input-label">کد تایید </label>
                          <input
                            type="number"
                            v-model="$v.resetCode.$model"
                            class="form-control p-2"
                            :class="{
                              'form-control-error': $v.resetCode.$anyError,
                            }"
                            @blur="delayTouch($v.resetCode)"
                            @input="delayTouch($v.resetCode)"
                            required
                          />
                        </div>
                        <div class="col-md-2 col-3" style="margin-top:30px">
                          <span
                            v-if="resetCodeTimerSec || resetCodeTimerMin > 0"
                            class="btn btn-app-reset text-uppercase font-weight-bold"
                          >
                            {{
                              resetCodeTimerSec > 9
                                ? resetCodeTimerSec
                                : "0" + resetCodeTimerSec
                            }}
                            :{{ resetCodeTimerMin }}
                          </span>
                          <button
                            v-else
                            class="btn btn-app-reset text-uppercase font-weight-bold"
                            type="button"
                            @click="resetCodeInterval(120)"
                          >
                            دریافت کد
                          </button>
                        </div>
                      </div>
                      <div v-if="$v.resetCode.$anyDirty">
                        <div
                          class="error mt-1 mr-2"
                          v-if="!$v.resetCode.required"
                        >
                          فیلد کد تایید الزامی است !
                        </div>
                        <div
                          class="error mt-1 mr-2"
                          v-if="$v.resetCode.$invalid"
                        >
                          کد تایید 4 رقمی میباشد.
                        </div>
                      </div>
                    </div>

                    <div class="text-center">
                      <button
                        class="btn btn-app text-uppercase font-weight-bold my-5"
                        type="submit"
                        :disabled="$v.resetCode.$invalid ? true : false"
                      >
                        تغییر Password
                      </button>
                    </div>
                  </form>
                  <form @submit.prevent="submitPassword()" v-if="index === 2">
                    <div class="form-label-group mt-4 ">
                      <label class="input-label">New password</label>
                      <input
                        :type="showPassword ? 'text' : 'password'"
                        v-model="$v.password.$model"
                        class="form-control p-2 relative"
                        :class="{
                          'form-control-error': $v.password.$anyError,
                        }"
                        @blur="delayTouch($v.password)"
                        @input="delayTouch($v.password)"
                        required
                      />
                      <i
                        class="fa  text-white pointer password-type"
                        :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                        @click="showPassword = !showPassword"
                        aria-hidden="true"
                      ></i>
                      <div v-if="$v.password.$anyDirty">
                        <div
                          class="error mt-1 mr-2"
                          v-if="!$v.password.required"
                        >
                          فیلد رمز عبور الزامی است !
                        </div>
                        <div
                          class="error mt-1 mr-2"
                          v-if="!$v.password.minLength"
                        >
                          رمز عبور حداقل باید دارای 6 کاراکتر باشد.
                        </div>
                      </div>
                      <div class="form-label-group mt-4 ">
                        <label class="input-label ">تکرار رمز عبور</label>
                        <input
                          :type="showPassword ? 'text' : 'password'"
                          v-model="$v.repeatPassword.$model"
                          class="form-control p-2 relative"
                          :class="{
                            'form-control-error': $v.repeatPassword.$anyError,
                          }"
                          @blur="delayTouch($v.repeatPassword)"
                          @input="delayTouch($v.repeatPassword)"
                          required
                        />
                        <i
                          class="fa  text-white pointer password-type"
                          :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                          @click="showPassword = !showPassword"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div v-if="$v.repeatPassword.$anyDirty">
                        <div
                          class="error mt-1 mr-2"
                          v-if="!$v.repeatPassword.required"
                        >
                          The password repeat field is requiredThe password repeat field is required !
                        </div>
                        <div
                          class="error mt-1 mr-2"
                          v-if="!$v.repeatPassword.sameAsPassword"
                        >
                          The chosen password is not the same.
                        </div>
                      </div>
                    </div>

                    <div class="text-center">
                      <button
                        class="btn btn-app text-uppercase font-weight-bold my-5"
                        type="submit"
                        :disabled="
                          $v.password.$invalid || $v.repeatPassword.$invalid
                            ? true
                            : false
                        "
                      >
                        تغییر Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <loading :loading="loading" />
    </div>
  </div>
</template>
<script>
import {
  required,
  minLength,
  email,
  decimal,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";

import { mapState } from "vuex";
const isPhone = (value) => /^(\+1|'')?\d{10}$/.test(value);
import md5 from "js-md5";
export default {
  name: "forget-password",
  data() {
    return {
      index: 0,
      email: "",
      phone: "",
      resetCode: "",

      password: "",
      repeatPassword: "",
      showPassword: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.auth.loading,
      auth: (state) => state.auth.auth,
      resetCodeTimerMin: (state) => state.validation.resetCodeTimerMin,
      resetCodeTimerSec: (state) => state.validation.resetCodeTimerSec,
    }),
  },

  methods: {
    /* calcuMD5(pwd) {
      pwd = pwd.toUpperCase();
      pwd = md5(pwd);
      console.log(pwd);
      return pwd;
    }, */
    delayTouch($v) {
      this.$store.commit("validation/delayTouchMutate", $v);
    },
    resetCodeInterval(i) {
      this.$store.commit("validation/resetCodeIntervalMutate", i);
    },
    submitEmail() {
      this.index++;
      this.resetCodeInterval(120);
    },
    submitCode() {
      if (!this.$v.resetCode.$invalid) {
        this.index++;
      } else {
        this.$swal("Hello Vue world!!!");
      }
    },
    submitPassword() {
      if (!this.$v.invalid) {
        this.$swal("Hello Vue world!!!");
        /*  this.calcuMD5(this.password);
      this.calcuMD5(this.repeatPassword); */
      }

      console.log({
        password: md5(this.password),
        repeatPassword: md5(this.repeatPassword),
      });
    },
  },
  mounted() {
    if (this.auth) {
      this.$router.push({ name: "dashboard" });
    }
  },
  validations: {
    email: {
      email,
      required,
    },
    phone: {
      required,
      decimal,
      phoneValid: isPhone, //custom Regex from validation Global
    },
    resetCode: {
      required,
      decimal,
      minLength: minLength(4),
      maxLength: maxLength(4),
    },
    password: {
      required,
      minLength: minLength(6),
    },
    repeatPassword: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
};
</script>
<style lang="scss" scoped>
.form-page {
  height: 100vh;
}
</style>
