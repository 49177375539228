

import appSlide from "@/components/slides/appSlide.vue";





export default [
  {
    path: "/slides/list",
    name: "appSlide",
    component: appSlide,
    meta: { requiresAuth: true },
  },
  
];
