import router from "../../router";
import { errorHandelMethode } from "../errorsHandel";
const getDefaultState = () => {
  return {
    results: [],
    result: {},
    paginate: {
      current_page: 1,
      page: 1,
      pageSize: 10,
      total: 1,
    },
    tags: [],
    categories: [],
 
  }
}
export const list = {
  namespaced: true,
  // initial state
  state: getDefaultState(),
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    },
    updateData(state, data) {
    
      state.results = data.items;
      state.paginate = {
        current_page: data.pageIndex,
        page: data.pageIndex,
        total: data.totalPages,
      };

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    updateDataById(state, data) {
      state.result = data;
    },
    dataTestEdit(state, data) {
      state.results = data;
    },
    fetchTagListForProductMutate(state, data) {
      state.tags = data.items;
    },
    fetchCategoryListForProductMutate(state, data) {
      state.categories = data.items;
    },
   

  },
  actions: {

    resetState(context){
      context.commit("resetState");

    },
    async createListAction(context, params) {
     
      context.rootState.loading = true;

      await axios
        .post(`/api/${params.reqName}`, params.data)
        .then(({
          data
        }) => {


          context.rootState.loading = false;
          if (data.succeeded) {
            console.log("sucesss");
            context.commit(
              "errorHandel", {
              message: {
                text: "Successfully registered.",
                icon: "success",
                title: "it is registered.",
                textButton: "Confirm",
              },
            }, {
              root: true
            }
            );
            context.rootState.success = true;
          } else {
            // root:true Set Global Mutate in main store file
            // context.commit("errorHandel", data, {
            //   root: true
            // });

            errorHandelMethode(context, data)
            context.rootState.success = false;
          }
        })
        .catch((error) => {
          console.log(error.response)
          context.rootState.success = false;
          /* handeling 901 OR All errors in errorsHandel.js */
          errorHandelMethode(context, error.response.data)
        });
    },
    getListsAction(context, params) {
      console.log(context);
      context.rootState.loading = true;
      axios
        .get(
          `/api/${params.reqName}?Page=${params.page}&SearchCriteria=${params.filter
          }${params.search}&SortingCriteria=${params.sorting}&PageSize=${params.pageSize === undefined
            ? (params.pageSize = 10)
            : params.pageSize
          }`
        )
        .then(({
          data
        }) => {
          context.rootState.loading = false;
       
          if (data.succeeded) {
            context.commit("updateData", data.data);
          } else {
            // root:true Set Global Mutate in main store file
            context.commit("errorHandel", data, {
              root: true
            });
          }
        })
        .catch((error) => {
          context.commit(
            "errorHandel", {
            message: {
              text: "An error occurred. Contact support",
              icon: "error",
              title: "Error",
              textButton: "Ok",
            },
          }, {
            root: true
          }
          );

          if (error.response) {
            console.log(error.response.status)
            if (error.response.status === 401) {
              localStorage.removeItem("user");
              localStorage.removeItem("route");

              context.rootState.auth.auth = false;
              router.push({
                name: "login"
              });


            }
          }
        });
    },
    getListByIdAction(context, params) {
      console.log(context);
      context.rootState.loading = true;
      axios
        .get(
          `/api/${params.reqName}/${params.id}`
        )
        .then(({
          data
        }) => {
          context.rootState.loading = false;
          
          if (data.succeeded) {
            context.commit("updateDataById", data.data);
          } else {
            // root:true Set Global Mutate in main store file
            context.commit("errorHandel", data, {
              root: true
            });
          }
        })
        .catch((error) => {
          context.commit(
            "errorHandel", {
            message: {
              text: "An error occurred. Contact support",
              icon: "error",
              title: "Error",
              textButton: "Ok",
            },
          }, {
            root: true
          }
          );

          if (error.response) {
            console.log(error.response.status)
            if (error.response.status === 401) {
              localStorage.removeItem("user");
              localStorage.removeItem("route");

              context.rootState.auth.auth = false;
              router.push({
                name: "login"
              });


            }
          }
        });
    },
    async updateListAction(context, params) {
      console.log(params.data);

      context.rootState.loading = true;

      await axios
        .put(`/api/${params.reqName}/${params.data.id}`, params.data)
        .then(({
          data
        }) => {
          // context.rootState.loading = false;
          if (data.succeeded) {
            console.log("sucesss");
            context.rootState.success = true;
          } else {
            // root:true Set Global Mutate in main store file
            context.commit("errorHandel", data.error, {
              root: true
            });
            context.rootState.success = false;
          }
        })
        .catch((error) => {
          context.rootState.success = false;
          /* handeling 901 OR All errors in errorsHandel.js */
          errorHandelMethode(context, error.response.data)


        });
    },
    async deleteListAction(context, params) {
      context.rootState.loading = true;

      await axios
        .delete(`/api/${params.reqName}/${params.id}`)
        .then(({
          data
        }) => {
          context.rootState.loading = false;

          if (data.succeeded) {
            console.log("sucesss");
            context.rootState.success = true;
          } else {
            // root:true Set Global Mutate in main store file
            context.commit("errorHandel", data, {
              root: true
            });
            context.rootState.success = false;
          }
        })
        .catch((error) => {
          context.rootState.success = false;
          context.commit(
            "errorHandel", {
            message: {
              text: "An error occurred. Contact support",
              icon: "error",
              title: "Error",
              textButton: "Ok",
            },
          }, {
            root: true
          }
          );

        });
    },
    fetchTagListForProductAction(context, params) {
      axios
        .get(
          `/api/${params.reqName}?Page=${params.page}&SearchCriteria=${params.filter
          }${params.search}&SortingCriteria=${params.sorting}&PageSize=${params.pageSize === undefined
            ? (params.pageSize = 10)
            : params.pageSize
          }`
        )
        .then(({
          data
        }) => {
          context.rootState.loading = false;
          
          if (data.succeeded) {
            context.commit("fetchTagListForProductMutate", data.data);
          } else {
            // root:true Set Global Mutate in main store file
            context.commit("errorHandel", data, {
              root: true
            });
          }
        })
        .catch((error) => {
          context.commit(
            "errorHandel", {
            message: {
              text: "An error occurred. Contact support",
              icon: "error",
              title: "Error",
              textButton: "Ok",
            },
          }, {
            root: true
          }
          );
        });
    },
    fetchCategoryListForProductAction(context, params) {
      axios
        .get(
          `/api/${params.reqName}?Page=${params.page}&SearchCriteria=${params.filter
          }${params.search}&SortingCriteria=${params.sorting}&PageSize=${params.pageSize === undefined
            ? (params.pageSize = 10)
            : params.pageSize
          }`
        )
        .then(({
          data
        }) => {
          context.rootState.loading = false;
          
          if (data.succeeded) {
            context.commit("fetchCategoryListForProductMutate", data.data);
          } else {
            // root:true Set Global Mutate in main store file
            context.commit("errorHandel", data, {
              root: true
            });
          }
        })
        .catch((error) => {
          context.commit(
            "errorHandel", {
            message: {
              text: "An error occurred. Contact support",
              icon: "error",
              title: "Error",
              textButton: "Ok",
            },
          }, {
            root: true
          }
          );
        });
    },
   }
}