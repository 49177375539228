export default{
    methods: {
        setPrice(value) {
            if (typeof value === "number") {
              return value
                .toString()
                .replace(/\D/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          },
    },
}