<template>
  <div>
    <header-table-component />
    <article class="container-fluid container-box p-3 mx-1">
      <div class="row ">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="FormControlSelect1" class="">Search</label>
            <input
              type="text"
              class="form-control relative"
              placeholder="Search (fast)"
              v-model.lazy="search"
              @keyup.enter="($store.state.list.paginate.page = 1), fetchData()"
            />
          </div>
        </div>

        <div class="col mt-4">
          <button
            type="button"
            class="btn btn-primary mt-1"
            @click="fetchData()"
          >
            <i class="fa fa-refresh"></i>
          </button>

          <button
            class="btn btn-info mx-1"
            @click="showModalFilter = !showModalFilter"
          >
            <i class="fa fa-filter mx-1"></i>
            Filters
          </button>
          <div class="btn-group " :class="{ show: dropDown }">
            <button
              type="button"
              class="btn btn-info dropdown-toggle"
              @click="dropDown = !dropDown"
            >
              Export
            </button>
            <div
              class="dropdown-menu "
              :class="{ show: dropDown }"
              style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;"
            >
              <a class="dropdown-item pointer"
                ><i class="fa fa-file-excel-o mx-1 "></i>Excel</a
              >
                         </div>
          </div>
        </div>
      </div>
      <hr />

      <ListFilters
        :search="search"
        :fields="fields"
        :filterList="filterList"
        :statusCheck="parseInt(statusCheck)"
        @fetchDataEmit="fetchDataEmit"
      />

      <div class="table-custom">
        <table class="table  table-bordered">
          <thead class="thead-light">
            <tr>
              <th
                v-for="(item, index) in fields"
                :key="index"
                v-show="item.value !== ''"
              >
                {{ item.text }}
                <span
                  v-show="filterList.sortingField === item.value"
                  class="float-left"
                >
                  <i
                    class="fa"
                    :class="[
                      sortDircetion === 1
                        ? 'fa-sort-amount-asc'
                        : 'fa-sort-amount-desc',
                    ]"
                    aria-hidden="true"
                  ></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in results" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <span
                  class="text-graymx-1 pointer h4"
                  v-tooltip.top-center="'Details'"
                >
                  <i
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    @click="showDetailMethode(item)"
                  ></i>
                </span>

                <span
                  class="text-danger mx-1 pointer h4"
                  v-tooltip.top-center="'Cancel order'"
                >
                  <i
                    class="fa fa-trash mx-auto "
                    @click="deleteDataMethode(item.id)"
                  ></i>
                </span>
              </td>
              <td>{{ item.userName }}</td>
              <td>{{ item.description }}</td>

              <td>
                {{
                  new Date(item.created)
                    .toLocaleString("fa-IR")
                    .substring(0, 11)
                    .replaceAll("،", " ")
                }}
              </td>
              <td>
                {{ setPrice(item.payAmount) }}
              </td>
              <td>
                <span v-show="item.status === 0">New</span>
                <span v-show="item.status === 1" class="text-danger"
                  >Canceled</span
                >
                <span v-show="item.status === 2" class="text-warning"
                  >Pending payment</span
                >
                <span v-show="item.status === 3" class="text-success"
                  >successfully</span
                >
              </td>
            </tr>
          </tbody>
        </table>
        <h5 v-show="results.length === 0" class="mt-4 text-center text-warning">
          Not found!
        </h5>
      </div>
      <!-- paginate  v-if="paginate.total > 1"-->

      <div class="mt-4">
        <paginateComponent
          :paginateShared="paginate"
          @clickedCustom="onClickChildPaginate"
        />
      </div>

      <!-- paginate -->
    </article>
    <!-- filters Modal -->
    <modals
    :modalShow="showModalFilter"
      @closeModalEmited="showModalFilter = false"
    >
      <template #header> Filters </template>
      <template #content>
        <form>
          <div class="row">
            <div class="form-group col-lg-6">
              <label>Sort </label>
              <select class="form-control" v-model="sortingField">
                <option
                  :value="item.value"
                  v-for="(item, index) in fields"
                  :key="index"
                  v-show="item.value !== null"
                  >{{ item.text }}</option
                >
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>Order by</label>
              <select class="form-control" v-model.number="sortDircetion">
                <option value="1">ascending</option>
                <option value="2">descending</option>
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>Status</label>
              <select class="form-control" v-model.number="statusCheck">
                <option value="">all</option>
                <option :value="0">New</option>
                <option :value="1">Canceled</option>
                <option :value="2">Pending payment</option>
                <option :value="3">successfully</option>
              </select>
            </div>
          </div>
        </form>
      </template>
      <template #footer>
        <button
          type="button"
          class="btn btn-info"
          @click="
            ($store.state.list.paginate.page = 1),
              fetchData(),
              (showModalFilter = false)
          "
        >
          Filter
        </button>
      </template>
    </modals>
    <!-- filters Modal -->
    <!-- deatil modal -->
    <modals
    :modalShow="showDetailModal"
      @closeModalEmited="showDetailModal = false"
    >
      <template #header> Purchase details</template>
      <template #content>
        <table class="table  table-bordered">
          <thead class="thead-light">
            <tr>
              <th>Purchase model</th>
              <th>Pay from wallet</th>
              <th>Wallet amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span v-show="detail.type === 1">Product</span>
                <span v-show="detail.type === 2">Single file</span>
                <span v-show="detail.type === 3">hybrid</span>
              </td>
              <td>
                <i v-if="detail.useWallet" class="fa fa-check text-success"></i>
                <i v-else class="fa fa-times text-danger"></i>
              </td>
              <td>
                {{ setPrice(detail.currentWalletAmount) }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="table-custom">
          <h4 class="text-center my-4">Order details</h4>
          <table class="table  table-bordered">
            <thead class="thead-light">
              <tr>
                <th>
                  #
                </th>
                <th>description</th>
                <th>Purchase model</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in detail.orderItems" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.description }}</td>
                <td>
                  <span v-show="item.type === 1">Product</span>
                  <span v-show="item.type === 2">Single file</span>
                  <span v-show="item.type === 3">hybrid</span>
                </td>
                <td>{{ setPrice(item.amount) }}</td>
              </tr>
            </tbody>
          </table>
          <h5
            v-show="results.length === 0"
            class="mt-4 text-center text-warning"
          >
            Not found!
          </h5>
        </div>
      </template>
      <template #footer> </template>
    </modals>
    <!-- deatil modal -->
    <div>
      <loading :loading="loading" />
    </div>
  </div>
</template>
<script>
import CRUDMix from "/src/mixins/lists/CRUD.js";
import paginateMix from "/src/mixins/lists/paginate.js";
import staticDataTableMix from "/src/mixins/lists/staticDataTable.js";
import defaultErrorMessageMix from "/src/mixins/lists/defaultErrorMessage.js";
import storeStateMix from "/src/mixins/lists/storeState.js";
import setPriceMix from "/src/mixins/lists/setPrice.js";
export default {
  name: "orders-list",
  mixins: [
    CRUDMix,
    paginateMix,
    staticDataTableMix,
    defaultErrorMessageMix,
    storeStateMix,
    setPriceMix,
  ],
  data() {
    return {
      showDetailModal: false,
      fields: [
        { text: "Default", value: "" },
        { text: "#", value: null },
        { text: "", value: null },
        { text: "User name", value: null },
        { text: "Name", value: "Description" },
        { text: "Created Date", value: "Created" },
        { text: "Total Price", value: "PayAmount" },
        { text: "Status", value: "Status" },
      ],
      reqName: "orders", //URL name request To server EX:www.test.com/api/v2/reqName
      slotSelected: "",
      statusCheck: "",
      detail: {},
      filterList: {
        sortingField: "",
        statusCheck: "",
      },
    };
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("list/getListsAction", {
        reqName: this.reqName,
        page: this.paginate.page,
        pageSize: this.paginate.pageSize,
        search: `Description=${this.search}¤5ɖ`,
        sorting: `${this.sortingField}¤${this.sortDircetion}`,
        filter: `Status=${this.statusCheck}¤1µ`,
      });

      this.checkListClick();
    },
    checkListClick() {
      this.filterList.sortingField = this.sortingField;
      this.filterList.statusCheck = this.statusCheck;
    },
    deleteDataMethode(id) {
      this.$swal
        .fire({
          title: "remove",
          text: "Are you sure about canceling the order?!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "remove",
          cancelButtonText: "cancel",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            this.$store
              .dispatch("list/deleteListAction", {
                reqName: "Orders/Cancel",
                id: id,
              })
              .then(() => {
                this.fetchData();
              });
          }
        });
    },
    showDetailMethode({ currentWalletAmount, useWallet, type, orderItems }) {
      this.showDetailModal = !this.showDetailModal;
      this.detail = { currentWalletAmount, useWallet, type, orderItems };
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style lang="scss"></style>
