export default {
  data() {
    return {
      linkType: 0,
      appSlot: "",
    };
  },
  methods: {
    checkLink(item, url) {
      
      if (this.models[url].includes("tel:")) {
        this.linkType = 2;
        this.models[url] = item[url].split("tel:")[1];
      } else if (this.models[url].includes("app:")) {
        this.linkType = 3;

        if (this.models[url].includes("product:")) {
          this.appSlot = "product";
          this.models[url] = item[url].split("app:product:")[1];
        }
        // if (this.models[url].includes("instructor:")) {
        //   this.appSlot = "instructor";
        //   this.models[url] = item[url].split("app:instructor:")[1];
        // }
      } else {
        this.linkType = 1;
      }
    },
    submit(url) {
      
      if (this.linkType === 2) {
        this.models[url] = `tel:${this.models[url]}`;
      }
      if (this.linkType === 3) {
        this.models[url] = `app:${this.appSlot}:${this.models[url]}`;
      }
    },
  },
};
