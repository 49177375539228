import {calculateWindowSize} from '../../utils/helpers';
import {
    Option,
    NAVBAR_DARK_VARIANTS,
    NAVBAR_LIGHT_VARIANTS,
    SIDEBAR_DARK_SKINS,
    SIDEBAR_LIGHT_SKINS
} from "../../utils/themes";
export const ui = {
    namespaced: true,
    state: {
        darkMode: false,
        navbarVariant: 'navbar-light',
        sidebarSkin: 'sidebar-dark-primary',
        menuSidebarCollapsed: false,
        controlSidebarCollapsed: true,
        screenSize: calculateWindowSize(window.innerWidth)
    },
    getters: {
        darkModeSelected: (state) => state.darkMode,
        menuSidebarCollapsed: (state) => state.menuSidebarCollapsed,
        controlSidebarCollapsed: (state) =>
            state.controlSidebarCollapsed,
        screenSize: (state) => state.screenSize,
        navbarVariant: (state) => state.navbarVariant,
        sidebarSkin: (state) => state.sidebarSkin
    },
    mutations: {
        toggleDarkMode: (state) => {
            
            state.darkMode = !state.darkMode;
            if (state.darkMode) {
                state.navbarVariant = NAVBAR_DARK_VARIANTS[0].value;
                state.sidebarSkin = SIDEBAR_DARK_SKINS[0].value;
            } else {
                state.navbarVariant = NAVBAR_LIGHT_VARIANTS[0].value;
                state.sidebarSkin = SIDEBAR_LIGHT_SKINS[0].value;
            }
        },
        toggleMenuSidebar: (state) => {

            state.menuSidebarCollapsed = !state.menuSidebarCollapsed;
        },
        toggleControlSidebar: (state) => {
            state.controlSidebarCollapsed = !state.controlSidebarCollapsed;
        },
        setWindowSize: (state, payload) => {
            

            state.screenSize = payload;
        },
        setNavbarVariant: (state, payload) => {
            if (state.darkMode) {
                state.navbarVariant = payload || NAVBAR_DARK_VARIANTS[0].value;
            } else {
                state.navbarVariant = payload || NAVBAR_LIGHT_VARIANTS[0].value;
            }
        },
        setSidebarSkin: (state, payload) => {
            if (state.darkMode) {
                state.sidebarSkin = payload || SIDEBAR_DARK_SKINS[0].value;
            } else {
                state.sidebarSkin = payload || SIDEBAR_LIGHT_SKINS[0].value;
            }
        }

    },
    actions: {
        toggleDarkMode: (context) => {
            context.commit('toggleDarkMode');
        },
        toggleMenuSidebar: (context) => {
            context.commit('toggleMenuSidebar');
        },
        toggleControlSidebar: (context) => {
            context.commit('toggleControlSidebar');
        },
        setWindowSize: (context, payload) => {
            context.commit('setWindowSize', payload);
        },
        setNavbarVariant: (context, payload) => {
            context.commit('setNavbarVariant', payload);
        },
        setSidebarSkin: (context, payload) => {
            context.commit('setSidebarSkin', payload);
        }
    },

}