<template>
  <div>
    <i
      @click="showGetHtmlModal = !showGetHtmlModal"
      class="fa fa-html5 mx-auto text-primary pointer"
      style="position: absolute; right: 30px; top: 26px; font-size: 24px"
    ></i>
    <vue-editor
      v-model="fullDescription"
      :editorToolbar="customToolbar"
      class=""
    ></vue-editor>
    <button
      type="button"
      class="btn btn-primary mt-2"
      @click="sendDescription()"
    >
      Update
    </button>
    <div class="description-html-modal">
      <modals
        :modalShow="showGetHtmlModal"
        @closeModalEmited="showGetHtmlModal = false"
      >
        <template #header> Add media </template>
        <template #content>
          <textarea
            class="form-control"
            placeholder="description"
            v-model="fullDescription"
            rows="10"
          ></textarea>
        </template>
        <template #footer>
          <button @click="showGetHtmlModal = false" class="btn btn-primary">
            actions
          </button>
        </template>
      </modals>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
// import fullDescription from "/src/store/products/description.js"
export default {
  props: {
    productId: { required: true, type: String },
    fullDescription: { required: false, type: String },
  },
  data() {
    return {
      content: "",
      reqName: "products", //URL name request To server EX:www.test.com/api/v2/reqName
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ align: "" }, { align: "center" }, { align: "left" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"],
        ["clean"], // remove formatting button
      ],
      showGetHtmlModal: false,
    };
  },
  methods: {
    // async fetchData() {
    //   await this.$store.dispatch("htmlDescription/getHtmlByIdAction", {
    //     reqName: this.reqName,
    //     id: this.productId,
    //   });
    // },

   async sendDescription() {
    await this.$store.dispatch("htmlDescription/updateHtmlDescription", {
        fullDescription: this.fullDescription,
        id: this.productId,
        reqName: this.reqName,
      });

      this.$emit("clickedCustomCloseModal");
    },
    getHtml() {
      this.$swal.fire({
        html: `<textarea
                class="form-control"
                placeholder="description"
                v-model="${this.fullDescription}"
                rows="10"
              ></textarea>`,
      });
    },
  },
 
  components: {
    VueEditor,
  },
};
</script>
<style lang="scss">
.ql-editor {
  //text-align: right !important;
  li:not(.ql-direction-rtl)::before {
    margin-right: 0 !important;
    margin-left: 0.3em;
    text-align: right;
  }
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: unset !important;
}

.description-html-modal {
  #customModal {
    .modal-dialog {
      width: 650px;
    }
  }
}
@media (max-width: 576px) {
  .description-html-modal {
    #customModal .modal-dialog {
      width: unset;
    }
  }
}
</style>
