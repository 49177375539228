<template>
  <div>
    <div class="row bg-light px-2 py-1 mx-1" ref="filterChips" v-show="filterChipsShow">
      <span class="chip-item table-color px-2 py-1 rounded m-2" v-if="search">
        <span>Search term : </span><span class="text-primary">{{ search }}</span>
        <i class="fa fa-times mx-1 text-danger pointer" @click="searchFilterMethode('search')"></i></span>
      <span v-if="filterList.sortingField" class="chip-item table-color px-2 py-1 rounded m-2">
        <span> Sort by: </span>
        <span class="text-primary" v-for="(item, index) in fields" :key="index"><span
            v-show="item.value === filterList.sortingField">{{
              item.text
            }}</span>
        </span>
        <i class="fa fa-times mx-1 text-danger pointer" @click="searchFilterMethode('sortingField')"></i>
      </span>
      <span v-if="filterList.isActive !== '' && filterList.isActive !== undefined"
        class="chip-item table-color px-2 py-1 rounded m-2">
        <span> IsActive:</span>
        <span class="text-primary"><span v-if="isActiveCheck">Active</span>
          <span v-else>Inactive</span>
        </span>
        <i class="fa fa-times mx-1 text-danger pointer" @click="searchFilterMethode('isActiveCheck')"></i>
      </span>

      <!-- Status Check just For order list table -->
      <span v-if="
        filterList.statusCheck !== '' && filterList.statusCheck !== undefined
      " class="chip-item table-color px-2 py-1 rounded m-2">
        <span> Status :</span>
        <span class="text-primary">
          <span v-show="statusCheck === 0">New</span>
          <span v-show="statusCheck === 1">Canceled</span>
          <span v-show="statusCheck === 2">Pending payment</span>
          <span v-show="statusCheck === 3">successfully</span>
        </span>
        <i class="fa fa-times mx-1 text-danger pointer" @click="searchFilterMethode('statusCheck')"></i>
      </span>
      <!-- Status Check just For order list table -->
      <!-- slotSelected just For content list table -->
      <span class="chip-item table-color px-2 py-1 rounded m-2" v-if="slotSelected">
        <span> How to display users: </span>
        <span class="text-primary" v-for="(item, index) in slotItem" :key="index"><span
            v-show="item.value === slotSelected">{{ item.text }}</span>
        </span>

        <i class="fa fa-times mx-1 text-danger pointer" @click="searchFilterMethode('slotSelected')"></i></span>
      <!-- slotSelected just For content list table -->

      <span v-if="
        showFilterChips.showInstructor
      " class="chip-item table-color px-2 py-1 rounded m-2">
        <span> نام مدرس:</span>
        <span class="text-primary">{{ filterList.instructorsFirstName }}
          {{ filterList.instructorsLastName }}
        </span>
        <i class="fa fa-times mx-1 text-danger pointer" @click="
          searchFilterMethode(
            (filterList.instructorsFirstName = ''),
            (filterList.instructorsLastName = ''),
            (filterList.instructorsSelected = '')
          )
          "></i>
      </span>
      <span v-if="
        showFilterChips.showMinPrice
      " class="chip-item table-color px-2 py-1 rounded m-2">
        <span> Price from:</span>
        <span class="text-primary">{{ setPrice(filterList.minPrice) }} </span>
        <i class="fa fa-times mx-1 text-danger pointer" @click="searchFilterMethode((filterList.minPrice = ''))"></i>
      </span>
      <span v-if="
        showFilterChips.showMaxPrice
      " class="chip-item table-color px-2 py-1 rounded m-2">
        <span> Price to:</span>
        <span class="text-primary">{{ setPrice(filterList.maxPrice) }} </span>
        <i class="fa fa-times mx-1 text-danger pointer" @click="searchFilterMethode((filterList.maxPrice = ''))"></i>
      </span>
    </div>
    <hr v-show="filterChipsShow" />
  </div>
</template>
<script>
import setPriceMix from "/src/mixins/lists/setPrice.js";
export default {
  name: "lists-filters",
  mixins: [setPriceMix],
  props: {
    search: {
      type: String,
      default: "",
    },
    fields: {
      type: Array,
      default: [],
    },
    filterList: {
      type: Object,
      default: {},
    },
    isActiveCheck: {
      type: Boolean,
      default: false,
    },
    showFilterChips: {
      type: Object,
      default: function () {
        return {
          showInstructor: false,
          showMinPrice: false,
          showMaxPrice: false,
        }

      },
    },
    //Status Check just For order list table
    statusCheck: {
      type: Number,
      default: 0,
    },

    //Slot  selected just For content list table
    slotSelected: {
      type: String,
      default: "",
    },
    slotItem: {
      type: Array,
    },
  },
  data() {
    return {
      isMounted: false,
    };
  },
  computed: {
    filterChipsShow() {
      if (this.isMounted) {
        return this.$refs.filterChips.textContent;
      } else return "";
    },
  },

  methods: {
    searchFilterMethode(payload) {
      this.$emit("fetchDataEmit", payload);
    },
  },
  updated() {
    /* updated when dom full loaded can access $REFS */
    if (this.$refs.filterChips.textContent != "") {
      this.isMounted = true;
    } else this.isMounted = false;
  },
};
</script>
