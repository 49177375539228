<template>
  <div>
    <header-table-component />
    <article class="container-fluid container-box p-3 mx-1">
      <div class="row ">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="FormControlSelect1" class="">Search</label>
            <input type="text" class="form-control relative" placeholder="Search (fast)" v-model.lazy="search"
              @keyup.enter="($store.state.list.paginate.page = 1), fetchData()" />
          </div>
        </div>

        <div class="col mt-4">
          <button type="button" class="btn btn-primary" @click="fetchData()">
            <i class="fa fa-refresh"></i>
          </button>
          <button class="btn btn-success mx-1" @click="createDataMethode()">
            <i class="fa fa-plus mx-1"></i>
            <span class="">
              New Slide
            </span>
          </button>
        </div>
      </div>
      <hr />

      <div class="table-custom">
        <table class="table  table-bordered">
          <thead class="thead-light">
            <tr>
              <th v-for="(item, index) in fields" :key="index" v-show="item.value !== ''">
                {{ item.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in results" :key="index">
              <th class="text-center" scope="row">{{ index + 1 }}</th>
              <td scope="row" class="max-table-25 ">
                <span v-tooltip.top-center="'remove'" class="text-danger mx-1 pointer h4">
                  <i class="fa fa-trash mx-auto " @click="deleteDataMethode(item.id)"></i>
                </span>

                <span v-tooltip.top-center="'Edit'" class="text-gray h4">
                  <i class="fa fa-edit mx-auto " @click="editDataMethode(item)"></i>
                </span>
              </td>

              <td>{{ item.name }}</td>

              <td>
                {{ formatDate(item.created) }}
              </td>
              <td :class="item.isActive ? 'text-success' : 'text-danger'">
                <span v-if="item.isActive">Active</span>
                <span v-else> Inactive</span>
              </td>

              <td class="p-0" style="width: 75px;">
                <span v-if="item.type == 2">
                  <a :href="item.mediaUrl" target="_blank">
                    <div class="bg-warning bg-gradient" style="height: 71px;">
                      <i class="fa fa-quote-right mx-auto h-100 d-flex align-items-center justify-content-center"></i>
                    </div>
                  </a>
                </span>
                <span v-if="item.type == 1 && item.image">
                  <a :href="window + item.image.url" target="_blank">
                    <img :src="window + item.image.thumbnailUrl" alt="content_IMG" class="img-fluid" />
                  </a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <h5 v-show="results.length === 0" class="mt-4 text-center text-warning">
          Not found!
        </h5>
      </div>
      <!-- paginate  v-if="paginate.total > 1"-->

      <div class="mt-4">
        <paginateComponent :paginateShared="paginate" @clickedCustom="onClickChildPaginate" />
      </div>

      <!-- paginate -->
    </article>
    <!-- Create modal -->

    <modals :modalShow="showCreateModal || showEditModal"
      @closeModalEmited="(showCreateModal = false), (showEditModal = false)">
      <template #header>{{ activeClassText }}</template>
      <template #content>
         <form id="slide-form" @submit.prevent="showCreateModal ? createData() : editData()">
          <div class="row">
            <div class="form-group col-lg-6">
              <label>name</label>
              <input type="text" class="form-control" placeholder="name" v-model="models.name" />
            </div>
            <div class="form-group col-lg-6">
              <label>display title</label>
              <input type="text" class="form-control" placeholder="name" v-model="models.displayTitle" />
            </div>
            <div class="form-group col-lg-6">
              <label>duration(ms)</label>
              <input type="number" class="form-control" placeholder="duration" v-model.number="models.duration" />
            </div>
            <div class="form-group col-lg-6">
              <label> transition</label>
              <input type="text" class="form-control" placeholder="transition" v-model="models.transition" />
            </div>
            <div class="form-group col-lg-6">
              <label>Type</label>
              <select class="form-control" v-model="models.type">
                <option :value="1">Image</option>
                <option :value="2">Video</option>
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>Priority</label>
              <select class="form-control" v-model="models.priority">
                <option :value="index" v-for="(item, index) in 10" :key="index">
                  {{ index }}
                </option>
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label>Display</label>
              <select class="form-control" v-model="models.isActive">
                <option value="">select...</option>

                <option :value="true">Active</option>
                <option :value="false">Inactive</option>
              </select>
            </div>
            <div class="form-group col-lg-6" v-if="models.type == 2">
              <label>click Url</label>
              <input type="text" class="form-control" placeholder="click Url" v-model="models.clickUrl" />
              <!-- custom form upload -->
            </div>
            <div class="form-group col-lg-6" v-if="models.type == 1">
              <label>image</label>
              <!-- custom form upload -->
              <!-- <file-upload
                :key="showCreateModal || showEditModal"
                :imageUrl="models.mediaUrl"
                
              /> -->

              <file-upload input-id="content-img" :imageUrl="models.image ? models.image.thumbnailUrl : ''"
                :fileId="models.image ? models.image.id : null" :window="window" />
              <!-- custom form upload -->
            </div>
            <div class="form-group col-lg-6" v-if="models.type == 2">
              <label>media Url</label>
              <input type="text" class="form-control" placeholder="media Url" v-model="models.mediaUrl" />
              <!-- custom form upload -->
            </div>
          </div>
        </form>

      </template>
      <template #footer>
        <button type="submit" form="slide-form" class="btn btn-info">
          Save {{ activeClassText }}
        </button>
      </template>
    </modals>
    <!-- Create modal -->

    <div>
      <loading :loading="loading" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import CRUDMix from "/src/mixins/lists/CRUD.js";
import paginateMix from "/src/mixins/lists/paginate.js";
import staticDataTableMix from "/src/mixins/lists/staticDataTable.js";
import defaultErrorMessageMix from "/src/mixins/lists/defaultErrorMessage.js";
import storeStateMix from "/src/mixins/lists/storeState.js";
import formatDateMix from "/src/mixins/lists/formatTime.js";

export default {
  name: "app-slide",
  mixins: [
    CRUDMix,
    paginateMix,
    staticDataTableMix,
    defaultErrorMessageMix,
    storeStateMix,
    formatDateMix,
  ],
  data() {
    return {
      reqName: "slides", //URL name request To server EX:www.test.com/api/v2/reqName
      fields: [
        { value: "", text: "Default" },
        { value: null, text: "Row" },
        { value: null, text: "" },
        { value: "Name", text: "Name" },
        { value: "expireDate", text: "Release date" },
        { value: null, text: "Display" },
        { value: null, text: "Image" },
      ],
      models: {
        id: "",
        name: "",
        displayTitle: "",
        type: 1,
        mediaUrl: "",
        clickUrl: "",
        duration: 0,
        transition: "",
        image: {},
        imageId: null,
        priority: 0,
        isActive: true,
      },
    };
  },
  watch: {
    imageDetail() {
      this.models.image = this.imageDetail;
      this.models.imageId = this.imageDetail.id;
    },
    results() {
      let findMax = Math.max.apply(
        Math,
        this.results.map(function (o) {
          return o.priority;
        })
      );
      this.models.priority = findMax + 10;
    },
  },
  computed: {
    ...mapState({
      /* just Getter */
      imageDetail: (state) => state.upload.imageDetail,
    }),
  },

  methods: {
    async fetchData() {
      await this.$store.dispatch("list/getListsAction", {
        reqName: this.reqName,
        page: this.paginate.page,
        pageSize: this.paginate.pageSize,
        search: `Name=${this.search}¤5ɖ`,
        sorting: `${this.sortingField}¤${this.sortDircetion}`,
        filter: ``,
      });
    },
    resetModels() {
      return {

        id: "",
        name: "",
        displayTitle: "",
        type: 0,
        mediaUrl: "",
        clickUrl: "",
        image: {},
        imageId: null,
        duration: 0,
        transition: "",
        priority: 0,
        isActive: true,
      };
    },
    createDataMethode() {
      this.showCreateModal = !this.showCreateModal;
      this.models = this.resetModels();
    },
    editDataMethode(item) {
      this.showEditModal = !this.showEditModal;
      this.models = { ...item };
      if (item.image) this.models.imageId = item.image.id;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
