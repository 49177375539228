<template>
  <div>
    <div class="custom-loading" v-show="loading">
      <div class="loadingio-spinner-cube-bkc0ssarw6m">
        <div class="ldio-bbonfp885xn">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="opacities" v-show="loading"></div>
  </div>
</template>
<script>
export default {
  name: "loading",

  props: ["loading"],
};
</script>
<style scoped>
.custom-loading {
  position: fixed;
  top: 50%;
  right: 50%;
  z-index: 1113;
}
.opacities {
  background: #eee;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  opacity: 0.6;
  z-index: 1112;
  left: 0%;
}
@keyframes ldio-bbonfp885xn {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
.ldio-bbonfp885xn div {
  position: absolute;
  width: 34px;
  height: 34px;
  top: 12px;
  left: 12px;
  background: #5bb5e1;
  animation: ldio-bbonfp885xn 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.31s;
  opacity: 0.9;
}
.ldio-bbonfp885xn div:nth-child(2) {
  top: 12px;
  left: 56px;
  background: #7460f4;
  animation-delay: -0.21s;
}
.ldio-bbonfp885xn div:nth-child(3) {
  top: 56px;
  left: 12px;
  background: #f8b91e;
  animation-delay: 0s;
}
.ldio-bbonfp885xn div:nth-child(4) {
  top: 56px;
  left: 56px;
  background: #f86a8f;
  animation-delay: -0.1s;
}
.loadingio-spinner-cube-bkc0ssarw6m {
  width: 88px;
  height: 88px;
  display: inline-block;
  overflow: hidden;
}
.ldio-bbonfp885xn {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.88);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-bbonfp885xn div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
</style>
