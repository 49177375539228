const base = "albums";

import albumList from "@/components/albums/albumList.vue";
import AlbumItems from "@/components/albums/albumItems.vue";
// import productInstructors from "../../components/products/productInstructors.vue";


export default [
  {
    path: `/${base}/items`,
    name: "albumItems",
    component: AlbumItems,
    meta: { requiresAuth: true },
  },
  {
    path: `/${base}/list`,
    name: "albumList",
    component: albumList,
    meta: { requiresAuth: true },
  },



];
