const base = "product";
import productTags from "../../components/products/productTags.vue";
import productList from "../../components/products/productList.vue";
// import productInstructors from "../../components/products/productInstructors.vue";
import productCategories from "../../components/products/productCategories.vue";

export default [
  {
    path: `/${base}/tags`,
    name: "productTags",
    component: productTags,
    meta: { requiresAuth: true },
  },
  {
    path: `/${base}/list`,
    name: "productList",
    component: productList,
    meta: { requiresAuth: true },
  },
  // {
  //   path: `/${base}/instructors`,
  //   name: "productInstructors",
  //   component: productInstructors,
  //   meta: { requiresAuth: true },
  // },
  {
    path: `/${base}/categories`,
    name: "productCategories",
    component: productCategories,
    meta: { requiresAuth: true },
  },
 
 
];
