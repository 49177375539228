import Vue from "vue";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
/* plugins */
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
// If you don't need the styles, do not connect
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);
/* plugins */

//add headerTable component globally
import headerTable from "./components/shared/headerTable.vue";
Vue.component("headerTableComponent", headerTable);
//add paginate component globally
import paginate from "./components/shared/paginate.vue";
Vue.component("paginateComponent", paginate);
//add loading component globally
import loading from "./components/shared/loading.vue";
Vue.component("loading", loading);
//add modals component globally
import modals from "./components/shared/modals.vue";
Vue.component("modals", modals);
//add filters component globally
import listFilters from "./components/shared/listFilters.vue";
Vue.component("ListFilters", listFilters);
//add filters component globally
import filterChips from "./components/shared/filterChips.vue";
Vue.component("FilterChips", filterChips);
//add filters component globally
import search from "./components/shared/search.vue";
Vue.component("Search", search);
//add fileUpload component globally
import fileUpload from "./components/shared/fileUpload.vue";
Vue.component("fileUpload", fileUpload);
//add fileUpload component globally
import videoUpload from "./components/shared/videoUpload.vue";
Vue.component("videoUpload", videoUpload);
//add fileUpload component globally
import globalLink from "./components/shared/globalLink.vue";
Vue.component("globalLink", globalLink);
//add fileUpload component globally
import VTooltip from 'v-tooltip'

import controlSidebar from "./components/control-sidebar/controlSidebar.vue";
Vue.component("controlSidebar", controlSidebar);

Vue.use(VTooltip)

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)
