<template>
  <div>
    <div class="container-fluid">
      <div class="row no-gutter">
        <div class="col-md-8 " style="margin: 0 auto;">
          <div class="login d-flex align-items-center py-5">
            <div class="container">
              <div class="row">
                <div class="col-md-9 col-lg-6 mx-auto">
                  <h3 class="login-heading mb-4 text-center">Panemor</h3>
                  <form @submit.prevent="submit()">
                    <div class="form-label-login mt-4">
                      <label for="inputEmail" class="text-secondary"
                        >User name</label
                      >

                      <input
                        type="email"
                        v-model="$v.email.$model"
                        @blur="delayTouch($v.email)"
                        @input="delayTouch($v.email)"
                        class="form-control p-2"
                        :class="{ 'form-control-error': $v.email.$anyError }"
                        placeholder="User name"
                        required
                      />
                      <div v-if="$v.email.$anyDirty">
                        <div class="error mt-1 mr-2" v-if="!$v.email.required">
                           User name is required!
                        </div>
                        <div class="error mt-1 mr-2" v-if="!$v.email.email">
                          email format is incorrect.
                        </div>
                      </div>
                    </div>
                    <div class="form-label mt-4">
                      <label for="inputEmail" class="text-secondary"
                        >Password</label
                      >

                      <input
                        type="password"
                        v-model="$v.password.$model"
                        @blur="delayTouch($v.password)"
                        @input="delayTouch($v.password)"
                        class="form-control p-2"
                        :class="{ 'form-control-error': $v.password.$anyError }"
                        placeholder="Password"
                        required
                      />
                      <div v-if="$v.password.$anyDirty">
                        <div
                          class="error mt-1 mr-2"
                          v-if="!$v.password.required"
                        >
                           Password is required!
                        </div>
                        <div class="error" v-if="!$v.password.minLength">
                          Minimum password is
                          {{ $v.password.$params.minLength.min }} characters!.
                        </div>
                      </div>
                    </div>

                    <!--  <div class="custom-control custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck1"
                      />
                      <label
                        class="custom-control-label pointer text-secondary"
                        for="customCheck1"
                        >بخاطر سپردن</label
                      >
                    </div> -->
                    <button
                      class="btn btn-lg btn-primary btn-block btn-login text-uppercase  mb-2 mt-5"
                      type="submit"
                    >
                      Login
                    </button>
                    <div class="text-center">
                      <a class="small" href="#">Forgot Password</a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
      </div>
    </div>
    <div>
      <loading :loading="loading" />
    </div>
  </div>
</template>
<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import md5 from "js-md5";

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  watch: {
    messages() {
      this.$swal.fire({
        icon: this.messages.message.icon || "error",
        title: this.messages.message.title || "Error",
        text:
          this.messages.message.text ||
          "An error occurred. Contact support",
        allowOutsideClick: true,
        confirmButtonText: this.messages.message.textButton || "Confirm",
      });
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      auth: (state) => state.auth.auth,
      messages: (state) => state.messages,
    }),
  },

  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
    },
  },

  methods: {
    delayTouch($v) {
      this.$store.commit("validation/delayTouchMutate", $v);
    },
    submit() {
      if (!this.$v.$invalid) {
        this.$store.dispatch("auth/login", {
          username: this.email,
          password: md5(this.password),
        });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: "format is not correct.",
          allowOutsideClick: true,
          confirmButtonText: "Ok!",
        });
      }
    },
  },
  mounted() {
    console.log(localStorage.getItem("route"));
    let lastRoute = localStorage.getItem("route");
    if (!lastRoute) lastRoute = "dashboard";
    if (this.auth) {
      this.$router.push({
        path: lastRoute,
      });
    }
  },
};
</script>
<style lang="scss">
.login {
  min-height: 100vh;
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}
</style>
