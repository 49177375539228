import router from "../../router";

export const auth = {
  namespaced: true,
  state: {
    auth: false,
  },
  getter: {

  },
  mutations: {
    loginUser(state, userData) {
      console.log("loginned");
      state.auth = true;
      localStorage.setItem("user", JSON.stringify(userData));
      if (userData.token) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${userData.token}`;
      } else {
        console.error("token error");
      }
    },
    logOutUser(state) {
      localStorage.removeItem("user");
      localStorage.removeItem("route");
      state.auth = false;
      router.push({ name: "login" });
    },
  },
  actions: {
    login(context, params) {
      
      context.rootState.loading = true;

      axios
        .post(`/api/Authenticate/signin`, params)
        .then((res) => {
          
          context.rootState.loading = false;

          if (res.data.succeeded) {
            context.commit("loginUser", res.data.data);
          } else {
            // root:true Set Global Mutate in main store file
            context.commit(
              "errorHandel",
              {
                message: {
                  text: "The email or password is incorrect.",
                },
              },
              { root: true }
            );
          }
        })
        .catch((error) => {
          context.rootState.loading = false;
          context.commit(
            "errorHandel",
            {
              message: {
                text: "An error occurred. Contact support",
                icon: "error",
                title: "Error",
                textButton: "Ok",
              },
            },
            { root: true }
          );

          if (error.response) {
            console.log(error.response);
          }
        });
    },
    register(context, params) {
      context.rootState.loading = true;
      axios
        .post("/api/Authenticate/register", params)
        .then((res) => {
          

          if (res.status === 200) {
            // root:true Set Global Mutate in main store file

            context.commit("postResponseHandel", res.data, { root: true });
          }
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);

            console.log(error.response);
            // root:true Set Global Mutate in main store file
            context.commit("postErrorHandel", error.response.data, {
              root: true,
            });
            /*  console.log(error.response.status);
          console.log(error.response.headers); */
          }
        });
    },
    getverifysmscode(context, params) {
      axios
        .post(`/api/Authenticate/getverifysmscode`, {
          email: "test",
          phone: "123",
        })
        .then((res) => console.log("loggined"))
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            context.commit("errorHandel", error.response.data, { root: true });

            /*  console.log(error.response.status);
            console.log(error.response.headers); */
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },

    logOut(context) {
      context.commit("logOutUser");
    },
  },
};
