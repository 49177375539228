export default {
  methods: {
    formatDate(value) {
      if (value) {
        return new Date(value)
          .toLocaleString("en-US", {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          })
          .split(" ")[0]
          .replace(",", " ");
      }
    },
    formatTime(value) {
      if (value) {
        const d = new Date(value)
          .toLocaleString("en-US")
          .split(" ");
        return `${d[1]} ${d[2]}`;
        ;
      }
    },
  },
};
