export const topics = {
  namespaced: true,
  state: { topics: [] },
  getter: {},
  mutations: {
    updateDataTopicsMutate(state, data) {
      state.topics = data.items;
    },
  },
  actions: {
    getTopicsAction(context, params) {
      console.log(params);

      context.rootState.loading = true;

      axios
        .get(
          `/api/${params.reqName}?Page=1&SearchCriteria=${params.filter}&SortingCriteria=${params.sorting}&PageSize=-1`
        )
        .then(({ data }) => {
          context.rootState.loading = false;
          
          if (data.succeeded) {
            context.commit("updateDataTopicsMutate", data.data);
          } else {
            // root:true Set Global Mutate in main store file
            context.commit("errorHandel", data, { root: true });
          }
        })
        .catch((error) => {
          context.commit(
            "errorHandel",
            {
              message: {
                text: "An error occurred. Contact support",
                icon: "error",
                title: "Error",
                textButton: "Ok",
              },
            },
            { root: true }
          );

          if (error.response) {
            console.log(error.response);
          }
        });
    },
  },
};
