<template>
    <div>
        <header-table-component />
        <article class="container-fluid container-box mx-1">
            <div class="row">
                <!-- <div class="col-lg-3 col-sm-4">
                    <div class="form-group">
                        <label for="FormControlSelect1" class="">Search</label>
                        <input type="text" for="FormControlSelect1" class="form-control relative"
                            placeholder="Search (fast)" v-model.lazy="search"
                            @keyup.enter="($store.state.list.paginate.page = 1), fetchData()" />
                    </div>
                </div> -->


                <div class="col mb-4 col-sm-8">
                    <button type="button" class="btn btn-primary mt-2" @click="fetchData()">
                        <i class="fa fa-refresh"></i>
                    </button>
                    <button class="btn btn-success mx-1  mt-2" @click="createDataMethode()">
                        <i class="fa fa-plus mx-1"></i>
                        <span class=""> New </span>
                    </button>

                    <div class="btn-group  mt-2" :class="{ show: dropDown }">
                        <button type="button" class="btn btn-info dropdown-toggle" @click="dropDown = !dropDown">
                            Export
                        </button>

                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-lg-3">
                    <label for="FormControlSelect1" class="">Search</label>
                    <input type="text" ref="search" class="form-control relative" placeholder="Search (fast)"
                        v-model.lazy="search" @keyup.enter="($store.state.list.paginate.page = 1), fetchData()" />
                </div>
                <div class="form-group col-lg-2">
                    <label>IsActive</label>
                    <select class="form-control" v-model="isActiveCheck">
                        <option value="">all</option>
                        <option :value="true">Active</option>
                        <option :value="false">Inactive</option>
                    </select>
                </div>

                <div class="form-group col-lg-2">
                    <label>Sort </label>
                    <select class="form-control" v-model="sortingField">
                        <option :value="item.value" v-for="(item, index) in fields" :key="index"
                            v-show="item.value !== null">{{ item.text }}</option>
                    </select>
                </div>
                <div class="form-group col-lg-2">
                    <label>Order by</label>
                    <select class="form-control" v-model.number="sortDircetion">
                        <option value="1">ascending</option>
                        <option value="2">descending</option>
                    </select>
                </div>
                <div class="col mt-4">
                    <button class="btn btn-primary mx-1  mt-2" @click="fetchData()">
                        <i class="fa fa-filter mx-1"></i>
                        Apply
                    </button>

                </div>
            </div>

            <hr />

            <ListFilters :search="search" :fields="fields" :filterList="filterList" :isActiveCheck="isActiveCheck"
                @fetchDataEmit="fetchDataEmit" />

            <div class="table-custom">
                <table class="table table-bordered">
                    <thead class="thead-light">
                        <tr>
                            <th v-for="(item, index) in fields" :key="index" v-show="item.value !== ''">
                                {{ item.text }}
                                <span v-show="filterList.sortingField === item.value" class="float-left">
                                    <i class="fa" :class="[
                                        sortDircetion === 1
                                            ? 'fa-sort-amount-asc'
                                            : 'fa-sort-amount-desc',
                                    ]" aria-hidden="true"></i>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in results" :key="item.id">
                            <td scope="row" class="min-table-100">
                                <span v-tooltip.top-center="'remove'" class="text-danger mx-1 pointer h4">
                                    <i class="fa fa-trash mx-auto" @click="deleteDataMethode(item.id)"></i>
                                </span>

                                <span v-tooltip.top-center="'Edit'" class="text-gray h4">
                                    <i class="fa fa-edit mx-auto" @click="editDataMethode(item)"></i>
                                </span>
                                <span v-tooltip.top-center="'Items'" class="text-gray h4">
                                    <i class="fa fa-sitemap mx-auto" @click="NavigateToItems(item)"></i>
                                </span>
                                <span v-tooltip.top-center="'(ID)'" class="text-gray h4 mx-2" @click="
                                    copyId(item.id),
                                    showAlert(),
                                    (showAlertText = 'Successfully copied')
                                    ">
                                    <i class="fa fa-id-card mx-auto"></i>
                                </span>
                            </td>

                            <td class="min-table-200">{{ item.name }}</td>

                            <td :class="item.isActive ? 'text-success' : 'text-danger'">
                                <span v-if="item.isActive">Active</span>
                                <span v-else> Inactive</span>
                            </td>

                            <td>{{ item.priority }}</td>

                            <td>
                                <span :id="'update-date' + item.id">
                                    {{ formatDate(item.lastModified) }}</span>
                                <b-tooltip :target="'update-date' + item.id" placement="bottom">
                                    {{ formatTime(item.lastModified) }}
                                </b-tooltip>
                            </td>
                            <td>
                                <span :id="'create-date' + item.id">
                                    {{ formatDate(item.created) }}</span>
                                <b-tooltip :target="'create-date' + item.id" placement="bottom">
                                    {{ formatTime(item.created) }}
                                </b-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h5 v-show="results.length === 0" class="mt-4 text-center text-warning">
                    Not found!
                </h5>
            </div>
            <!-- paginate  v-if="paginate.total >. 1"-->

            <div class="mt-4">
                <paginateComponent :paginateShared="paginate" @clickedCustom="onClickChildPaginate" />
            </div>

            <!-- paginate -->
        </article>
        <!-- Create modal -->
        <modals :modalSize="models.type === 0 ? 'xl' : ''" :modalShow="showCreateModal || showEditModal"
            @closeModalEmited="(showCreateModal = false), (showEditModal = false)">
            <template #header> {{ activeClassText }}</template>

            <template #content>
                <form id="content-form" @submit.prevent="showCreateModal ? createData() : editData()">
                    <div class="row">
                        <div class="form-group col-lg-12">
                            <label>name</label>
                            <input type="text" class="form-control" placeholder="name" v-model="models.name" required />
                        </div>

                        <div class="form-group col-lg-6">
                            <label>Priority</label>
                            <select class="form-control" v-model="models.priority">
                                <option :value="index" v-for="(item, index) in 10" :key="index">
                                    {{ index }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Display</label>
                            <select class="form-control" v-model="models.isActive">
                                <option value="">select...</option>

                                <option :value="true">Active</option>
                                <option :value="false">Inactive</option>
                            </select>
                        </div>


                        <div class="form-group col-lg-12">
                            <label>description</label>
                            <div>
                                <i @click="showGetHtmlModal = !showGetHtmlModal"
                                    class="fa fa-html5 mx-auto text-primary pointer"
                                    style="position: absolute; right: 30px; top: 45px; font-size: 24px"></i>
                                <vue-editor v-model="models.description" :editorToolbar="customToolbar"
                                    class=""></vue-editor>
                                <div class="description-html-modal">
                                    <modals :modalShow="showGetHtmlModal" @closeModalEmited="showGetHtmlModal = false">
                                        <template #header> Add media </template>

                                        <template #content>
                                            <textarea class="form-control" placeholder="description"
                                                v-model="models.description" rows="10"></textarea>
                                        </template>

                                        <template #footer>
                                            <button @click="showGetHtmlModal = false" class="btn btn-primary">
                                                confirm
                                            </button>
                                        </template>
                                    </modals>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </template>

            <template #footer>
                <button type="submit" form="content-form" class="btn btn-info">
                    Save {{ activeClassText }}
                </button>
            </template>
        </modals>
        <!-- Create modal -->
        <!-- filters Modal -->
        <modals name="filter-m" :modalShow="showModalFilter" @closeModalEmited="showModalFilter = false">
            <template #header> Filters </template>
            <template #content>
                <form>
                    <div class="row">
                        <div class="form-group col-lg-6">
                            <label>Sort </label>
                            <select class="form-control" v-model="sortingField">
                                <option :value="item.value" v-for="(item, index) in fields" :key="index"
                                    v-show="item.value !== null">
                                    {{ item.text }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Order by</label>
                            <select class="form-control" v-model.number="sortDircetion">
                                <option value="1">ascending</option>
                                <option value="2">descending</option>
                            </select>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>IsActive</label>
                            <select class="form-control" v-model="isActiveCheck">
                                <option value="">all</option>
                                <option :value="true">Active</option>
                                <option :value="false">Inactive</option>
                            </select>
                        </div>

                    </div>
                </form>
            </template>
            <template #footer>
                <button type="button" class="btn btn-info" @click="
                    ($store.state.list.paginate.page = 1),
                    fetchData(),
                    (showModalFilter = false)
                    ">
                    Filter
                </button>
            </template>
        </modals>
        <!-- filters Modal -->
        <div>
            <loading :loading="loading" />
        </div>
    </div>

</template>

<script>
// import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import CRUDMix from "/src/mixins/lists/CRUD.js";
import paginateMix from "/src/mixins/lists/paginate.js";
import staticDataTableMix from "/src/mixins/lists/staticDataTable.js";
import defaultErrorMessageMix from "/src/mixins/lists/defaultErrorMessage.js";
import storeStateMix from "/src/mixins/lists/storeState.js";
import setPriceMix from "/src/mixins/lists/setPrice.js";
import formatDateMix from "/src/mixins/lists/formatTime.js";
import copyMix from "/src/mixins/lists/copy.js";
import alertMix from "/src/mixins/alert/alert.js";
import { VueEditor } from "vue2-editor";
export default {
    name: "album-list",
    components: { VueEditor },
    mixins: [
        CRUDMix,
        paginateMix,
        staticDataTableMix,
        defaultErrorMessageMix,
        storeStateMix,
        setPriceMix,
        formatDateMix,
        copyMix,
        alertMix,
    ],


    data() {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const mindate = new Date(today);
        mindate.setMonth(mindate.getMonth() - 1);
        // mindate.setDate(15)
        return {
            reqName: "albums", //URL name request To server EX:www.test.com/api/v2/reqName
            showGetHtmlModal: false,
            showDescriptionModal: false,
            showModalFilter: false,
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [{ align: "" }, { align: "center" }, { align: "left" }],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ["link"],
                ["clean"], // remove formatting button
            ],
            fields: [
                { value: "", text: "Default" },
                { value: null, text: "Operations" },
                { value: "Name", text: "Name" },
                // { value: "Description", text: "description" },
                { value: "IsActive", text: "IsActive " },
                { value: "Priority", text: "Priority" },
                { value: "LastModified", text: "Updated" },
                { value: "Created", text: "Created" },
            ],

            models: {
                id: null,
                name: null,
                description: null,
                priority: 1,
                isActive: true,
                Created: new Date().toISOString().split("T")[0],
                LastModified: new Date().toISOString().split("T")[0],
            },
            isActiveCheck: "",
            filterList: {
                sortingField: "",
                isActive: false,

            },
            albumId: "",
        };
    },
    watch: {},
    computed: {},
    methods: {
        async fetchData() {
            await this.$store.dispatch("list/getListsAction", {
                reqName: this.reqName,
                page: this.paginate.page,
                pageSize: this.paginate.pageSize,
                search: `Name=${this.search}¤5ɖDescription=${this.search}¤5ɖ`,
                sorting: `${this.sortingField}¤${this.sortDircetion}`,
                filter: `IsActive=${this.isActiveCheck}¤1µ`,
            });

            this.checkListClick();
        },
        checkListClick() {
            this.filterList.sortingField = this.sortingField;
            this.filterList.isActive = this.isActiveCheck;

        },
        async createDataMethode() {
            this.showCreateModal = !this.showCreateModal;
            await this.resetDataModel();
        },
        resetDataModel() {
            this.models.id = null;
            this.models.name = null;
            this.models.description = null;
            this.models.priority = 0;
            this.models.isActive = false;

            // this.models.publishDate = new Date().toISOString().split("T")[0];
            // this.models.lastUpdate = new Date().toISOString().split("T")[0];
        },
        editDataMethode(item) {
            this.showEditModal = !this.showEditModal;
            this.updateDataModel(item);
        },
        NavigateToItems(item) {
            this.$router.push({ name: 'albumItems', query: { albumId: item.id } })
        },
        updateDataModel(item) {

            for (const property in this.models) {
                this.models[property] = item[property];
            }

        },
        onClickChildModal() {
            this.showDescriptionModal = false;
            this.fetchData();
        },


    },

    created() {
        this.fetchData();
    },

}
</script>

<style lang="scss" scoped></style>