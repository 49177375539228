<template>
  <div>
    <div class="row justify-content-between mt-4">
      <div class="form-group col-lg-2">
        <label>count</label>
        <select
          class="form-control"
          @change="pageSizeEvent(parseInt($event.target.value))"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
      </div>
      <div v-show="paginate.total > 1">
        <paginateModule
          :page-count="paginateShared.total"
          :click-handler="paginateEvent"
          :prev-text="'prev'"
          :next-text="'next'"
          :container-class="'pagination'"
          :force-page="parseInt($store.state.list.paginate.page)"
          :page-range="3"
          :margin-pages="2"
          :hidePrevNext="true"
          ref="vue-paginate"
        >
        </paginateModule>
      </div>
    </div>
  </div>
</template>
<script>
import paginateModule from "vuejs-paginate";
import { mapState } from "vuex";

export default {
  name: "paginate",

  data() {
    return {
      pageSize: 10,
    };
  },
  props: ["paginateShared"],

  computed: {
    ...mapState({
      /* just Getter */
      paginate: (state) => state.list.paginate,
    }),
  },
  methods: {
    paginateEvent(page) {
      this.$store.state.list.paginate.page = page;
      this.$store.state.list.paginate.pageSize = this.pageSize;
      this.$emit("clickedCustom");

      /*  this.paginateShared.page = page;
      this.$refs["vue-paginate"].selected = page; */
    },
    pageSizeEvent(value) {
      this.pageSize = value;
      this.$store.state.list.paginate.pageSize = value;
      this.$store.state.list.paginate.page = 1;
      this.$emit("clickedCustom");
    },
  },

  mounted() {
 
  },
  components: {
    paginateModule,
  },
};
</script>
<style scoped lang="scss">
.paginate li {
  display: flex;
  flex-direction: row;
  padding: 2px 8px;
  border-radius: 5px;
  background-color: #eee;
  margin: 5px;
  &:hover {
    background-color: #e4e4e4;
  }
}
.paginate li a:focus {
  border: white;
}
</style>
