const base = "setting";
import defaultSetting from "../../components/setting/defaultSetting.vue";


export default [
  {
    path: `/${base}/default-setting`,
    name: "defaultSetting",
    component: defaultSetting,
    meta: { requiresAuth: true },
  },

];
