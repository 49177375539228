const base = "orders";
import ordersList from "@/components/orders/ordersList";


export default [
  {
    path: `/${base}/list`,
    name: "ordersList",
    component: ordersList,
    meta: { requiresAuth: true },
  }
];
