<template>
  <div>
    <div v-if="type === 0" class="">
      <label>See story link</label>
      <input
        type="text"
        class="form-control"
        placeholder="url"
        v-model="inputVal"
      />
    </div>
    <div v-if="type === 1" class="">
      <label>Phone</label>
      <input
        type="tel"
        class="form-control"
        placeholder="phone"
        v-model="inputVal"
      />
    </div>
    <div v-if="type === 2" class="">
      <label>Slot</label>
      <select
        class="form-control"
        v-model="appSlotChild"
        @change="$emit('selectAppSlot', appSlotChild)"
      >
        <option value="">select...</option>
        <option value="product">product</option>
        
      </select>
      <div v-if="appSlotChild">
        <label>Id</label>
        <input
          type="text"
          class="form-control"
          placeholder="Id"
          v-model="inputVal"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: [Number],
      default: 0,
      required: true,
    },
    value: {
      type: [Number],
      default: 0,
      required: false,
    },
    appSlot: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      appSlotChild:""
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.appSlotChild = this.appSlot
  },
};
</script>
