<template>
    <div>
        <header-table-component>
            <template v-slot:title>
                <h1 class="m-0" style="font-weight: bold;color:darkblue;padding-right: 6px;">
                    {{ currentAlbum.name }}
                </h1>
            </template>
        </header-table-component>
        <article class="container-fluid container-box p-3 mx-1">
            <div class="row">
                <div class="col-lg-3 col-sm-4">
                    <div class="form-group">
                        <label for="FormControlSelect1" class="">Search</label>
                        <input type="text" for="FormControlSelect1" class="form-control relative"
                            placeholder="Search (fast)" v-model.lazy="search"
                            @keyup.enter="($store.state.list.paginate.page = 1), fetchData()" />
                    </div>
                </div>

                <div class="col mt-4 col-sm-8">
                    <button type="button" class="btn btn-primary mt-2" @click="fetchData()">
                        <i class="fa fa-refresh"></i>
                    </button>
                    <button class="btn btn-success mx-1  mt-2" :disabled="!currentAlbum.id"
                        @click="createDataMethode()">
                        <i class="fa fa-plus mx-1"></i>
                        <span class=""> New </span>
                    </button>
                    <!-- <button class="btn btn-info mx-1  mt-2" @click="showModalFilter = !showModalFilter">
                        <i class="fa fa-filter mx-1"></i>
                        Filters
                    </button> -->
                    <div class="btn-group  mt-2" :class="{ show: dropDown }">
                        <button type="button" class="btn btn-info dropdown-toggle" @click="dropDown = !dropDown">
                            Export
                        </button>

                    </div>
                </div>
            </div>
            <hr />

            <ListFilters :search="search" :fields="fields" :filterList="filterList" :isActiveCheck="isActiveCheck"
                @fetchDataEmit="fetchDataEmit" />

            <div class="table-custom">
                <table class="table table-bordered">
                    <thead class="thead-light">
                        <tr>
                            <th v-for="(item, index) in fields" :key="index" v-show="item.value !== ''">
                                {{ item.text }}
                                <span v-show="filterList.sortingField === item.value" class="float-left">
                                    <i class="fa" :class="[
                                        sortDircetion === 1
                                            ? 'fa-sort-amount-asc'
                                            : 'fa-sort-amount-desc',
                                    ]" aria-hidden="true"></i>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in results" :key="item.id">
                            <td scope="row" class="min-table-100">
                                <span v-tooltip.top-center="'remove'" class="text-danger mx-1 pointer h4">
                                    <i class="fa fa-trash mx-auto" @click="deleteDataMethode(item.id)"></i>
                                </span>

                                <span v-tooltip.top-center="'Edit'" class="text-gray h4">
                                    <i class="fa fa-edit mx-auto" @click="editDataMethode(item)"></i>
                                </span>

                                <span v-tooltip.top-center="'(ID)'" class="text-gray h4 mx-2" @click="
                                    copyId(item.id),
                                    showAlert(),
                                    (showAlertText = 'Successfully copied')
                                    ">
                                    <i class="fa fa-id-card mx-auto"></i>
                                </span>
                            </td>

                            <td class="min-table-200">{{ item.name }}</td>

                            <td :class="item.isActive ? 'text-success' : 'text-danger'">
                                <span v-if="item.isActive">Active</span>
                                <span v-else> Inactive</span>
                            </td>

                            <td>{{ item.priority }}</td>
                            <td class="text-center p-0" style="width: 75px;">
                                <span v-if="item.image && item.type == albumItemType.image">
                                    <a :href="window + item.image.url" target="_blank">
                                        <img :src="window + item.image.thumbnailUrl" alt="content_IMG"
                                            class="img-fluid" />
                                    </a>
                                </span>
                                <span v-else>
                                    <div class="bg-warning bg-gradient" style="height: 71px;">
                                        <a :href="window + item.clickUrl" target="_blank">
                                            <i
                                                class="fa fa-quote-right mx-auto h-100 d-flex align-items-center justify-content-center">

                                            </i>
                                        </a>


                                    </div>

                                </span>
                            </td>
                            <!-- <td>
                                <span :id="'update-date' + item.id">
                                    {{ formatDate(item.lastModified) }}</span>
                                <b-tooltip :target="'update-date' + item.id" placement="bottom">
                                    {{ formatTime(item.lastModified) }}
                                </b-tooltip>
                            </td>
                            <td>
                                <span :id="'create-date' + item.id">
                                    {{ formatDate(item.created) }}</span>
                                <b-tooltip :target="'create-date' + item.id" placement="bottom">
                                    {{ formatTime(item.created) }}
                                </b-tooltip>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
                <h5 v-show="results.length === 0" class="mt-4 text-center text-warning">
                    Not found!
                </h5>
            </div>
            <!-- paginate  v-if="paginate.total >. 1"-->

            <div class="mt-4">
                <paginateComponent :paginateShared="paginate" @clickedCustom="onClickChildPaginate" />
            </div>

            <!-- paginate -->
        </article>
        <!-- Create modal -->
        <modals :modalSize="models.type === 0 ? 'xl' : ''" :modalShow="showCreateModal || showEditModal"
            @closeModalEmited="(showCreateModal = false), (showEditModal = false)">
            <template #header> {{ activeClassText }}</template>

            <template #content>
                <form id="content-form" @submit.prevent="showCreateModal ? createData() : editData()">
                    <div class="row">
                        <div class="form-group col-lg-12">
                            <label>name</label>
                            <input type="text" class="form-control" placeholder="name" v-model="models.name" required />
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Type</label>
                            <select class="form-control" v-model="models.type">
                                <option :value="typ" v-for="(typ, index) in albumItemType" :key="typ">
                                    {{ index }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group col-lg-6">
                            <label>Priority</label>
                            <select class="form-control" v-model="models.priority">
                                <option :value="index" v-for="(item, index) in 10" :key="index">
                                    {{ index }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Display</label>
                            <select class="form-control" v-model="models.isActive">
                                <option value="">select...</option>

                                <option :value="true">Active</option>
                                <option :value="false">Inactive</option>
                            </select>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Is primary</label>
                            <select class="form-control" v-model="models.isPrimary">
                                <option value="">select...</option>

                                <option :value="true">Active</option>
                                <option :value="false">Inactive</option>
                            </select>
                        </div>
                        <div class="form-group col-lg-12">
                            <label>image</label>
                            <!-- custom form upload -->
                            <div>
                                <template v-if="models.type === albumItemType.video">
                                    <label>media Url</label>
                                    <input type="text" class="form-control" placeholder="url" v-model="models.mediaUrl"
                                        required />
                                </template>
                                <template v-else>
                                    <file-upload input-id="item-img"
                                        :imageUrl="models.image ? models.image.thumbnailUrl : ''"
                                        :fileId="models.imageId ? models.imageId : null" :window="window" />

                                </template>
                            </div>

                        </div>

                        <div class="form-group col-lg-12">
                            <label>Click Url</label>
                            <input type="text" class="form-control" placeholder="url" v-model="models.clickUrl"
                                 />
                        </div>

                    </div>
                </form>
            </template>

            <template #footer>
                <button type="submit" form="content-form" class="btn btn-info">
                    Save {{ activeClassText }}
                </button>
            </template>
        </modals>
        <!-- Create modal -->

        <div>
            <loading :loading="loading" />
        </div>
    </div>

</template>

<script>
// import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import CRUDMix from "/src/mixins/lists/CRUD.js";
import paginateMix from "/src/mixins/lists/paginate.js";
import staticDataTableMix from "/src/mixins/lists/staticDataTable.js";
import defaultErrorMessageMix from "/src/mixins/lists/defaultErrorMessage.js";
import storeStateMix from "/src/mixins/lists/storeState.js";
import setPriceMix from "/src/mixins/lists/setPrice.js";
import formatDateMix from "/src/mixins/lists/formatTime.js";
import copyMix from "/src/mixins/lists/copy.js";
import alertMix from "/src/mixins/alert/alert.js";
import { VueEditor } from "vue2-editor";
import { mapState } from "vuex";
export default {
    name: "album-list",
    components: { VueEditor },
    mixins: [
        CRUDMix,
        paginateMix,
        staticDataTableMix,
        defaultErrorMessageMix,
        storeStateMix,
        setPriceMix,
        formatDateMix,
        copyMix,
        alertMix,
    ],

    data() {
        const albumItemType = { 
            image: 1,
            //  video: 2
             };
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const mindate = new Date(today);
        mindate.setMonth(mindate.getMonth() - 1);
        // mindate.setDate(15)
        return {
            reqName: "albumItems", //URL name request To server EX:www.test.com/api/v2/reqName
            albumId: 0,
            showGetHtmlModal: false,
            showDescriptionModal: false,
            albumItemType: albumItemType.image,
            fields: [
                // { value: "", text: "Default" },
                { value: null, text: "Operations" },
                { value: "Name", text: "Name" },
                // { value: "Description", text: "description" },
                { value: "IsActive", text: "IsActive " },
                { value: "Priority", text: "Priority" },
                { value: null, text: "" },

                // { value: "LastModified", text: "Updated" },
                // { value: "Created", text: "Created" },
            ],
            albumItemType,
            currentAlbum: {},
            models: {
                id: 0,
                name: null,
                type: 1,
                image: {},
                imageId: null,
                mediaUrl: null,
                clickUrl: null,
                priority: 1,
                isActive: true,
                albumId: 0,
                isPrimary:false
            },
            isActiveCheck: "",
            filterList: {
                sortingField: "",
                isActive: "",
                description: "",
                name: ""
            },
            albumId: "",
        };
    },
    watch: {
        imageDetail() {
            this.models.image = this.imageDetail;
            this.models.imageId = this.imageDetail.id;
        },
    },
    computed: {},
    computed: {
        ...mapState({
            /* just Getter */

            loading: (state) => state.loading,
            imageDetail: (state) =>  state.upload.imageDetail ,
        }),
    },
    methods: {
        async fetchData() {
            await this.$store.dispatch("list/getListsAction", {
                reqName: this.reqName,
                page: this.paginate.page,
                pageSize: this.paginate.pageSize,
                search: `Name=${this.search}¤5ɖDescription=${this.search}¤5ɖ`,
                sorting: `${this.sortingField}¤${this.sortDircetion}`,
                filter: `IsActive=${this.isActiveCheck}¤1µAlbumId=${this.albumId}¤1µ`,
            });

            this.checkListClick();
        },
        checkListClick() {
            this.filterList.sortingField = this.sortingField;
            this.filterList.isActive = this.isActiveCheck;
            this.filterList.description = this.description;
            this.filterList.name = this.name;


        },
        async createDataMethode() {
            this.showCreateModal = !this.showCreateModal;
            await this.resetDataModel();
        },
        resetDataModel() {
            this.models.id = null;
            this.models.name = null;
            this.models.description = null;
            this.models.priority = 0;
            this.models.isActive = false;
            this.models.image = null;
            this.models.imageId = null;

            // this.models.publishDate = new Date().toISOString().split("T")[0];
            // this.models.lastUpdate = new Date().toISOString().split("T")[0];
        },
        editDataMethode(item) {
            this.showEditModal = !this.showEditModal;
            this.models = { ...item };
            // if (item.image) this.models.imageId = item.image.id;
            this.models.image = item.image;
            this.models.imageId = item.imageId;

        },

        onClickChildModal() {
            this.showDescriptionModal = false;
            this.fetchData();
        },


    },

    created() {

        if (this.$route.query.albumId) {
            this.albumId = this.$route.query.albumId;
            axios
                .get(
                    `/api/albums/${this.albumId}`
                )
                .then(({
                    data
                }) => {
                    if (data.succeeded) {
                        this.currentAlbum = data.data;
                        this.models.albumId = data.data.id;
                    }
                })
                .catch((error) => {
                    console.error(error)
                    if (error.response) {
                        console.log(error.response.status)

                    }
                });
        }
        // console.log(this.$route.query.albumId)
        this.fetchData();
    },

}
</script>

<style lang="scss" scoped></style>