<template>
  <ul class="nav nav-pills nav-sidebar flex-column">
    <li class="nav-item">
      <router-link :to="{ name: 'dashboard' }" tag="a" class="nav-link position-relative">
        <i class="nav-icon fa fa-dashboard"></i>
        <p>Dashboard</p>
      </router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'productList' }" tag="a" class="nav-link position-relative">
        <i class="fa fa-database nav-icon"></i>
        <p>Products</p>
      </router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'contentManagment' }" tag="a" class="nav-link position-relative">
        <i class="fa fa-tasks nav-icon"></i>
        <p>Contents</p>
      </router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'productTags' }" tag="a" class="nav-link position-relative">
        <i class="fa fa-hashtag nav-icon"></i>
        <p>Tags</p>
      </router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'productCategories' }" tag="a" class="nav-link position-relative">
        <i class="fa fa-book nav-icon"></i>
        <p>Categories</p>
      </router-link>
    </li>
    <!-- <li class="nav-item">
      <router-link :to="{ name: 'discountList' }" tag="a" class="nav-link position-relative">
        <i class="fa fa-percent nav-icon"></i>
        <p>Discounts</p>
      </router-link>
    </li> -->

    <li class="nav-item">
      <ul class="nav nav-pills nav-sidebar flex-column">
        <li class="nav-item has-treeview" style="cursor:pointer" :class="[showAlbumLink ? 'menu-show' : '']"
          @click="showAlbumLink = !showAlbumLink">
          <a class="nav-link position-relative" :class="[showAlbumLink ? 'nav-active' : '']">
            <i class="nav-icon fa fa-folder"></i>
            <p>
              Albums
              <i class="fa fa-angle-right" :style="{
                transform: [showAlbumLink ? 'rotate(-90deg)' : 'rotaFte(0deg)'],
              }"></i>
            </p>
          </a>
        </li>
        <ul class="nav nav-treeview" :style="{ display: [showAlbumLink ? 'block' : 'none'] }">
          <li class="nav-item">
            <router-link :to="{ name: 'albumList' }" tag="a" class="nav-link position-relative">
              <i class="fa fa-list nav-icon"></i>
              <p>List</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'albumItems' }" tag="a" class="nav-link position-relative">
              <i class="fa fa-file-o nav-icon"></i>
              <p>Itmes</p>
            </router-link>
          </li>
        </ul>
      </ul>
    </li>



    <li class="nav-item">
      <router-link :to="{ name: 'ratingList' }" tag="a" class="nav-link position-relative">
        <i class="fa fa-comments nav-icon"></i>
        <p>Points and comments</p>
      </router-link>
    </li>
    <li class="nav-item">

      <router-link :to="{ name: 'users' }" tag="a" class="nav-link position-relative">
        <i class="fa fa-users nav-icon"></i>
        <p>Users</p>
      </router-link>
    </li>
    <li class="nav-item">
      <ul class="nav nav-pills nav-sidebar flex-column">
        <li class="nav-item has-treeview" style="cursor:pointer" :class="[showLink ? 'menu-show' : '']"
          @click="showChild()">
          <a class="nav-link position-relative" :class="[showLink ? 'nav-active' : '']">
            <i class="nav-icon fa fa-dollar"></i>
            <p>
              Payments
              <i class="fa fa-angle-right" :style="{
                transform: [showLink ? 'rotate(-90deg)' : 'rotaFte(0deg)'],
              }"></i>
            </p>
          </a>
        </li>
        <ul class="nav nav-treeview" :style="{ display: [showLink ? 'block' : 'none'] }">
          <li class="nav-item">
            <router-link :to="{ name: 'transactions' }" tag="a" class="nav-link position-relative">
              <i class="fa fa-circle-o nav-icon"></i>
              <p>Transactions</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'bankPayments' }" tag="a" class="nav-link position-relative">
              <i class="fa fa-circle-o nav-icon"></i>
              <p>Bank Transactions</p>
            </router-link>
          </li>
        </ul>
      </ul>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'ordersList' }" tag="a" class="nav-link position-relative">
        <i class="fa fa-shopping-cart nav-icon"></i>
        <p>Orders</p>
      </router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'appSlide' }" tag="a" class="nav-link position-relative">
        <i class="fa fa-picture-o nav-icon"></i>
        <p>Slide</p>
      </router-link>
    </li>
    <!-- <li class="nav-item">
      <router-link :to="{ name: 'appSplash' }" tag="a" class="nav-link position-relative">
        <i class="fa fa-picture-o nav-icon"></i>
        <p>Splash</p>
      </router-link>
    </li> -->
    <li class="nav-item">

      <router-link :to="{ name: 'defaultSetting' }" tag="a" class="nav-link position-relative">
        <i class="fa fa-gear nav-icon"></i>
        <p>Settings</p>
      </router-link>
    </li>


  </ul>

</template>
<script>
export default {
  data() {
    return {
      showLink: false,
      showAlbumLink: false,
    };
  },
  methods: {
    showChild() {
      this.showLink = !this.showLink;
    },
    
  },
};
</script>
