import Vue from "vue";
import VueRouter from "vue-router";
import dashboard from "../components/dashboard/dashboard.vue";
import login from "../views/login.vue";
import forgetPassword from "../views/forgetPassword.vue";
import register from "../views/register.vue";
import error from "../views/404.vue";
/* modules */
import users from "./users";
import content from "./content";
import stories from "./stories";
import productsRoutes from "./products";
import paysRoute from "./pays";
import settingRoute from "./setting";
import ordersRoute from "./orders";
import discount from "./discount";
// import splash from "./splash"
import slides from "./slides"
import rating from "./rating"
import albums from "./albums";
/* modules */
import store from "../store";



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "dashboard" },
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: { requiresAuth: false },
  },
  {
    path: "/forget-password",
    name: "forgetPassword",
    component: forgetPassword,
    meta: { requiresAuth: false },
  },
  {
    path: "/register",
    name: "register",
    component: register,
    meta: { requiresAuth: false },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard,
    meta: { requiresAuth: true },
  },
  /* Module each menu Route */
  ...users,
  ...content,
  ...stories,
  ...productsRoutes,
  ...paysRoute,
  ...settingRoute,
  ...ordersRoute,
  ...discount,
  // ...splash,
  ...rating,
  ...slides,
  ...albums,
  {
    path: "/*",
    name: "404",
    component: error,
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  // base: "admin",
  mode:'history',
  routes,
});


/* router guard */
router.afterEach((to, from) => {
  // store.state.list.paginate.page = 1; //globali set page=1 each route change
  store.dispatch("list/resetState");
  if (to.name != "login") {
    localStorage.setItem("route", to.fullPath);
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.name !== "login" && !store.state.auth.auth) {
      router.push({ name: "login" });
    }
  }
});
/* router guard */

export default router;
